import { MEDICALCODES, MEDICALCODESTYPES } from "../../url_helper"
import { request } from "../../api_helper"

/*
Medical Codes
*/

// add new Service

// url
export const addMedicalCode = (data) =>
  request({ url: MEDICALCODES, method: "POST", data })

export const getAllMedicalCodeTypes = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: MEDICALCODESTYPES,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by,
      sort_type,
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })
// get all Medical Codes with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllMedicalCodes = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: MEDICALCODES,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// show MedicalCode
//url/{id}

export const showMedicalCode = (medicalCodeId) =>
  request({ url: `${MEDICALCODES}/${medicalCodeId}`, method: "GET" })

// update MedicalCode
//url/{id}

export const updateMedicalCode = (data) =>
  request({ url: `${MEDICALCODES}/${data.id}`, method: "PUT", data })

// delete MedicalCode
//url/{id}
export const deleteMedicalCode = (medicalCodeId) =>
  request({ url: `${MEDICALCODES}/${medicalCodeId}`, method: "DELETE" })

// activate MedicalCode
//url/{id}/activate
export const activateMedicalCode = (medicalCodeId) =>
  request({
    url: `${MEDICALCODES}/${medicalCodeId}/activate`,
    method: "POST",
  })

// deactivate MedicalCode
//url/{id}/deactivate
export const deactivateMedicalCode = (medicalCodeId) =>
  request({
    url: `${MEDICALCODES}/${medicalCodeId}/deactivate`,
    method: "POST",
  })

// Batch Upload Medical Codes
export const uploadMedicalCodes = (data) =>
  request({ url: `${MEDICALCODES}/import`, method: "POST", data })

// medical codes prices used in claim
export const showMedicalCodePrices = (medicalCodeId, params) =>
  request({
    url: `${MEDICALCODES}/${medicalCodeId}/estimate-price`,
    method: "GET",
    params,
  })
