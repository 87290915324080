import { request } from "../../api_helper"
import { PRODUCTS, TABLEBENEFITS, UNCOVEREDBENEFITS } from "../../url_helper"
/*

Table of Benefits
*/

// show Product
//url/{productId}/{uncovered benefits url}

export const showProductUncoveredBenefits = (productId) =>
  request({
    url: `${PRODUCTS}/${productId}/${UNCOVEREDBENEFITS}`,
    method: "GET",
  })

// show Product
//url/{productId}/{table of benefits URL}

export const showProductTableBenefits = (productId) =>
  request({
    url: `${PRODUCTS}/${productId}/${TABLEBENEFITS}`,
    method: "GET",
  })

// update Product
//url/{productId}/{table of benefits URL}

export const updateProductTableBenefit = (productId, data) =>
  request({
    url: `${PRODUCTS}/${productId}/${TABLEBENEFITS}`,
    method: "PATCH",
    data,
  })

export const batchUploadTOB = (productId, data) =>
  request({
    url: `${PRODUCTS}/${productId}/import-table-benefits`,
    method: "POST",
    data,
  })
