import axios from "axios"
import { notification } from "antd"

// import accessToken from "./jwt-token-access/accessToken"

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  config => {
    // check if there is an access token in localstorage
    const accessToken = localStorage.getItem("accessToken")
    const lang = localStorage.getItem("i18nextLng")
    if (lang) {
      config.headers["Accept-Language"] = lang
    } else {
      config.headers["Accept-Language"] = "en"
    }
    if (accessToken !== null) {
      config.headers["Authorization"] = `Bearer ${
        JSON.parse(accessToken).access_token
      }`
    } else {
      config.headers["Authorization"] = null
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const accessToken = localStorage.getItem("accessToken")
    const authUser = localStorage.getItem("authUser")
    if (
      error?.response?.status === 401 &&
      error?.response.statusText === "Unauthorized" &&
      (accessToken || authUser)
    ) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("accessToken")
      window.location.reload()
    } else if (error?.response?.status === 500) {
      notification.error({
        message: "Error",
        description: "an server error",
      })
    }

    return Promise.reject(error)
  }
)
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

// let requestResponses = []

// export const request = ({ url, method, data, params, headers }) => {
//   // const filtered = requestResponses.filter(
//   //   item => Date.now() - item.created_at <= 20000
//   // )
//   // requestResponses = filtered
//   // const currentURL = url
//   // const currentParams = params

//   // const isFound = requestResponses.find(
//   //   item =>
//   //     method === "GET" &&
//   //     (currentParams?.isdropdown ||
//   //       currentURL.startsWith("/settings/predefined-values/")) &&
//   //     item.url === currentURL &&
//   //     isEqual(item.params, currentParams) &&
//   //     Date.now() - item.created_at <= 30000
//   // )
//   // if (isFound) return isFound.response

//   // let newRequestResponse = {
//   //   created_at: Date.now(),
//   //   url: url,
//   //   params: params,
//   // }
//   return new Promise(async (resolve, reject) => {
//     const axios_obj = {
//       url,
//       method,
//       ...(data && {
//         data,
//       }),
//       ...(params && {
//         params: {
//           ...params,
//         },
//       }),
//       headers: {
//         ...(headers && {
//           ...headers,
//         }),
//       },
//     }
//     try {
//       const res = await axiosApi(axios_obj)
//       resolve(res.data)
//       // newRequestResponse = {
//       //   ...newRequestResponse,
//       //   response: res.data,
//       // }
//       // requestResponses.push(newRequestResponse)
//     } catch (e) {
//       reject(e)
//     }
//   })
// }

let cancelToken
let requestQueue = []
export const request = ({ url, method, data, params, headers, repeat }) => {
  //const currentApiCode =  JSON.parse(body).apiCode;
  const currentApiCode = url

  // Check if there are any duplicate requests in the request queue
  const duplicateRequests = requestQueue.filter(
    request => request.apiCode === currentApiCode && !repeat
  )

  // Cancel the duplicate requests
  duplicateRequests.forEach(request => {
    request.cancelToken.cancel("Operation canceled due to duplication.")
  })

  // Remove the canceled requests from the queue
  requestQueue = requestQueue.filter(
    request => request.apiCode !== currentApiCode
  )
  const cancelTokenSource = axios.CancelToken.source()
  cancelToken = cancelTokenSource
  // Add the current request to the request queue
  requestQueue.push({
    apiCode: currentApiCode,
    cancelToken: cancelTokenSource,
  })

  return new Promise(async (resolve, reject) => {
    const axios_obj = {
      url,
      method,
      ...(data && {
        data,
      }),
      ...(params && {
        params: {
          ...params,
        },
      }),
      headers: {
        ...(headers && {
          ...headers,
        }),
      },
      cancelToken: cancelTokenSource.token,
    }
    try {
      const res = await axiosApi(axios_obj)
      resolve(res.data)
    } catch (e) {
      // console.log("code", e.code)
      if (e.code !== "ERR_CANCELED") {
        reject(e)
      }
    }
  })
}
