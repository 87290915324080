import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

import React, { Fragment } from "react";
import { ConfigProvider, DatePicker } from "antd";
import { APIDateFormat } from "constants/TPA";
const { RangePicker } = DatePicker;

const DateRangePicker = ({
  onChange,
  value,
  setDateRange,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  customOnChange,
  wrapperClassNames,
  noBorder,
  noPresets,
  id,
  disableFutureDates,
  minDate,
}) => {
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel;
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder;
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`;

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`;

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange({
        start_date: dateStrings[0],
        end_date: dateStrings[1],
      });
    } else {
      setDateRange({
        start_date: "",
        end_date: "",
      });
    }
  };
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last Week",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 2 Weeks",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last Month",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 3 Months",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  return (
    <Fragment>
      <label
        htmlFor={id}
        className={`form-label text-capitalize ${
          inputField?.is_required ? "required-label" : ""
        } ${labelClass ? labelClass : ""}`}
      >
        {label}
      </label>
      <div
        className={`form-control p-0 ${
          noBorder ? "" : "border border-gray-400"
        } rounded-3 ${wrapperClassNames ? wrapperClassNames : ""}`}
        // style={{ width: "fit-content" }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#556ee6",
            },
          }}
        >
          <RangePicker
            bordered={false}
            format={APIDateFormat}
            // value={[value.start_date, value.end_date]}
            defaultValue={
              value.start_date &&
              value.end_date && [
                dayjs(value.start_date, APIDateFormat),
                dayjs(value.end_date, APIDateFormat),
              ]
            }
            size="large"
            presets={!noPresets ? rangePresets : []}
            onChange={customOnChange ? customOnChange : onRangeChange}
            disabledDate={(current) => {
              if (disableFutureDates && minDate) {
                return (
                  dayjs(current).format(APIDateFormat) >
                    dayjs().format(APIDateFormat) ||
                  dayjs(current).format(APIDateFormat) < minDate
                );
              }

              if (disableFutureDates) {
                return (
                  dayjs(current).format(APIDateFormat) >
                  dayjs().format(APIDateFormat)
                );
              }
              if (minDate) {
                return dayjs(current).format(APIDateFormat) < minDate;
              } else {
                return undefined;
              }
            }}
          />
        </ConfigProvider>
      </div>
    </Fragment>
  );
};

export default DateRangePicker;
