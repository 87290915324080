import React, { Fragment, useState } from "react"
import { Input, InputGroup, Label, UncontrolledTooltip } from "reactstrap"
import FormInputError from "./FormInputError"
import { useLocation } from "react-router-dom"
import { NumericFormat } from "react-number-format"
import { isFloat } from "utils/smallUtils"
import GoogleMapsModal from "../GoogleMaps/GoogleMapsModal"

const LocationInput = ({
  id,
  name,
  type,
  value,
  min,
  max,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  error,
  wrapperClassNames,
  children,
  onChange,
  validation,
  includeNumricFormat,
  readOnly,
  horizontalLayout,
  noLabel,
  labelPrefix,
  labelSuffix,
  labelAddOns,
  preloadedLocation,
  ...rest
}) => {
  const [isMapModal, setIsMapModal] = useState(false)

  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const { invalid } = rest

  const location = useLocation()
  const { pathname } = location
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2]

  return (
    <Fragment>
      <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
        {!noLabel && (
          <div className="d-flex align-items-start gap-2">
            {/* tooltip for label from placeholder if */}
            <Fragment>
              <Label
                htmlFor={id}
                className={`text-capitalize ${
                  inputField?.is_required ? "required-label" : ""
                } ${labelClass ? labelClass : ""}`}
                // data-toggle="tooltip"
                // title={placeholder ? placeholder : ""}
                id={`tooltip-${id}`}
              >
                {labelPrefix && labelPrefix}
                {label}
                {labelSuffix && labelSuffix}
              </Label>
              {placeholder && (
                <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
                  {placeholder}
                </UncontrolledTooltip>
              )}
            </Fragment>

            {labelAddOns && labelAddOns}
          </div>
        )}
        <div
          className={horizontalLayout && inputClass}
          // style={{ height: type !== "textarea" && "40px" }}
        >
          <InputGroup>
            <Input
              id={id}
              name={name}
              type="text"
              value={value && value}
              placeholder={placeholder}
              className={`${inputClass ? inputClass : ""} ${
                pageType === "view" ? "border-0" : ""
              } ${readOnly ? "bg-gray-600 bg-opacity-10" : ""}`}
              {...rest}
              invalid={pageType === "view" || readOnly ? false : invalid}
              onChange={
                onChange && pageType !== "view" && !readOnly
                  ? e => onChange(`${name}`, e.target.value)
                  : () => {}
              }
              // in view page make the input read only or if is readonly prop
              readOnly={pageType === "view" || readOnly}
            />

            {pageType !== "view" ? (
              <div
                className="input-group-text"
                onClick={() => setIsMapModal(true)}
              >
                <i className="mdi mdi-google-maps font-size-14"></i>
              </div>
            ) : null}
            {invalid && pageType !== "view" && !readOnly ? (
              <FormInputError error={error} />
            ) : null}
          </InputGroup>
        </div>
      </div>

      {isMapModal && (
        <GoogleMapsModal
          show={isMapModal}
          onCloseClick={() => setIsMapModal(false)}
          preloadedLocation={preloadedLocation}
          onSelectPlace={place => {
            // onChange(`${name}`, place?.name)
            onChange({
              name: place?.name,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            })
          }}
        />
      )}
    </Fragment>
  )
}

export default LocationInput
