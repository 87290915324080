import React, { Fragment, useEffect, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"

// UI Components
import {
  FormModal,
  FormControll,
  ModalSpinner,
} from "components/TP/Common/Forms"

import { Col, Row } from "reactstrap"

// utility function for error
import {
  apiErrorrHandler,
  convertNullToString,
  NotificationMessage,
} from "utils/"
import { showHealthCareProvider } from "helpers/Stakeholders/healthCare"

import { showHealthCareContract } from "helpers/Networks/healthCareContract"

const ViewClaimProviderModal = ({
  isModal,
  setIsModal,
  providerId,
  contractId,
  modalTitle,
}) => {
  // states
  const [provider, setProvider] = useState(null)
  const [contract, setContract] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (providerId) {
      getProvider(providerId)
    }
  }, [providerId])

  useEffect(() => {
    if (contractId) {
      getContract(contractId)
    }
  }, [contractId])

  const getProvider = async providerId => {
    setIsLoading(true)
    try {
      const response = await showHealthCareProvider(providerId)
      setProvider(convertNullToString(response.item))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }
  const getContract = async contractId => {
    setIsLoading(true)
    try {
      const res = await showHealthCareContract(contractId)
      setContract(convertNullToString(res.item))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      show={isModal}
      modalSize="xl"
      onCloseClick={() => {
        setIsModal(false)
      }}
      modalTitle={modalTitle}
      noFooter={true}
      scrollable={true}
    >
      {isLoading ? (
        <ModalSpinner />
      ) : (
        <Fragment>
          {/* Physician Name Input */}
          <Row>
            <Col md={3}>
              <FormControll
                id="103"
                name="email"
                type="email"
                value={provider ? provider.email : ""}
                label="Email Address"
                wrapperClassNames="mb-3"
                readOnly={true}
              />
            </Col>

            {/* phone Input */}
            <Col md={3}>
              <FormControll
                id="104"
                name="phone"
                type="text"
                value={provider ? provider.phone : ""}
                label="Phone Number"
                wrapperClassNames="mb-3"
                readOnly={true}
              />
            </Col>
            {/* Address Input */}
            <Col md={3}>
              <FormControll
                id="106"
                name="address"
                type="text"
                value={provider ? provider.address : ""}
                label="Address"
                wrapperClassNames="mb-3"
                readOnly={true}
              />
            </Col>

            {/* Payment Method Input */}
            <Col md={3}>
              <FormControll
                id="114"
                name="payment_method_id"
                label="Payment Method"
                value={
                  provider && provider.payment_method
                    ? provider.payment_method.name
                    : ""
                }
                wrapperClassNames="mb-3"
                readOnly={true}
              />
            </Col>
            {/* Jumbo Claim Limits Input  */}
            <Col md={3}>
              <FormControll
                id="125"
                name="jumbo_claim_limit"
                label="Jumbo Claim Limit"
                type="number"
                includeNumricFormat={true}
                value={contract ? contract.jumbo_claim_limit : ""}
                wrapperClassNames="mb-3"
                readOnly={true}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </FormModal>
  )
}

export default withTranslation()(ViewClaimProviderModal)
