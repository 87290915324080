import React, { Fragment } from "react";
import { buttonsTitles } from "common/data";
import { StickyHeaderTable } from "components/TP/Common/Tables";

import { exportToExcel } from "../../../utils";

const MedReportTable = ({ reports, isLoading }) => {
  const CSVHeaders = [
    { label: "Description", key: "description" },
    { label: "Need Clinical Exam", key: "need_clinical_exam" },
    { label: "Appointment Date", key: "appointment_date" },
    { label: "Appointment Time", key: "appointment_time" },
    { label: "Is Congenital Case", key: "is_congenital_case" },
    { label: "Congenital Description", key: "congenital_description" },
    { label: "Is Surgical Case", key: "is_surgical_case" },
    { label: "Has Medical Necessity", key: "has_medical_necessity" },
    { label: "Suggested LOS", key: "suggested_LOS" },
    { label: "Suggested Codes", key: "suggested_codes" },
    { label: "Final Diagnosis", key: "final_diagnosis" },
    {
      label: "Past Medical Surgical History",
      key: "past_medical_surgical_history",
    },
    { label: "Symptoms Onset Date", key: "symptoms_onset_date" },
    { label: "Present History Treatments", key: "present_history_treatments" },
    { label: "Acquired Disorder", key: "acquired_disorder" },
    { label: "Pre-op Diagnostic Tests", key: "pre_op_diagnostic_tests" },
    { label: "Disease Onset Approx", key: "disease_onset_approx" },
    { label: "Is Preexisting", key: "is_preexisting" },
    { label: "Preexisting Description", key: "preexisting_description" },
    { label: "Specialty", key: "specialty" },
    { label: "Physician", key: "physician" },
    { label: "User", key: "user" },
    { label: "Priority", key: "priority" },
    { label: "Status", key: "status" },
    { label: "Routed to User", key: "routed_to_user" },
    { label: "Claim", key: "claim" },
    { label: "Claim Reference", key: "claim_reference" },
    {
      label: "Claim Healthcare Provider Name",
      key: "claim_healthcare_provider_name",
    },
    { label: "Claim Admission Date", key: "claim_admission_date" },
    { label: "Claim Payer Total Amount", key: "claim_payer_total_amount" },
    { label: "Claim Payer Name", key: "claim_payer_name" },
    { label: "Claim Benefit Name", key: "claim_benefit_name" },
    { label: "Created By", key: "created_by" },
    { label: "Last Updated By", key: "last_updated_by" },
  ];

  const CSVData = reports.map((report) => ({
    ...report,
  }));
  return (
    <Fragment>
      <div className="mb-4 d-flex gap-2 align-items-end justify-content-end">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            exportToExcel({
              headers: CSVHeaders,
              data: CSVData,
              filename: "fnol_report",
            });
          }}
        >
          {buttonsTitles.exportExcel}
        </button>
      </div>

      <StickyHeaderTable
        columns={CSVHeaders.map((item) => item.label)}
        isLoading={isLoading}
        noData={reports?.length === 0}
      >
        {reports.length > 0 &&
          reports?.map((report) => (
            <tr key={report.id}>
              <td>{report?.description}</td>
              <td>{report?.need_clinical_exam === 1 ? "Yes" : "No"}</td>
              <td>{report?.appointment_date}</td>
              <td>{report?.appointment_time}</td>
              <td>{report?.is_congenital_case === 1 ? "Yes" : "No"}</td>
              <td>{report?.congenital_description}</td>
              <td>{report?.is_surgical_case === 1 ? "Yes" : "No"}</td>
              <td>{report?.has_medical_necessity === 1 ? "Yes" : "No"}</td>
              <td>{report?.suggested_LOS}</td>
              <td>{report?.suggested_codes}</td>
              <td>{report?.final_diagnosis}</td>
              <td>{report?.past_medical_surgical_history || "N/A"}</td>
              <td>{report?.symptoms_onset_date || "N/A"}</td>
              <td>{report?.present_history_treatments || "N/A"}</td>
              <td>{report?.acquired_disorder || "N/A"}</td>
              <td>{report?.pre_op_diagnostic_tests || "N/A"}</td>
              <td>{report?.disease_onset_approx || "N/A"}</td>
              <td>{report?.is_preexisting === 1 ? "Yes" : "No"}</td>
              <td>{report?.preexisting_description || "N/A"}</td>
              <td>{report?.specialty}</td>
              <td>{report?.physician}</td>
              <td>{report?.user || "N/A"}</td>
              <td>{report?.priority || "N/A"}</td>
              <td>{report?.status}</td>
              <td>{report?.routed_to_user || "N/A"}</td>
              <td>{report?.claim || "N/A"}</td>
              <td>{report?.claim_reference || "N/A"}</td>
              <td>{report?.claim_healthcare_provider_name || "N/A"}</td>
              <td>{report?.claim_admission_date || "N/A"}</td>
              <td>{report?.claim_payer_total_amount || "N/A"}</td>
              <td>{report?.claim_payer_name || "N/A"}</td>
              <td>{report?.claim_benefit_name || "N/A"}</td>
              <td>{report?.created_by || "N/A"}</td>
              <td>{report?.last_updated_by || "N/A"}</td>
            </tr>
          ))}
      </StickyHeaderTable>
    </Fragment>
  );
};

export default MedReportTable;
