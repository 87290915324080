import React, { useState } from "react"
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Col,
  Row,
} from "reactstrap"

import { TableContainer } from "components/Common"
import { useGetTasks } from "./useGetTasks"
import { useSelector } from "react-redux"
import { buttonsTitles } from "common/data"
import { SingleRowAction } from "components/TP/Common/Tables"
import EditSourceTask from "../EditSourceTask"
import { useFormik } from "formik"
import { DatePickerInput, SearchSelect } from "components/TP/Common/Forms"
import { PREDEFINEDS } from "helpers/url_helper"
import { isInputInvalid } from "utils"

function ViewSourceTasks({ setShow, show, sourceType, sourceId }) {
  const toggle = () => setShow(!show)
  const {
    tasks,
    loadingTaks,
    showOptions,
    resetInput,
    pagination,
    columns,
    getTasks,
    setResetInput,
    setShowOptions,
    actionFields,
    moduleId,
    generateColumns,
  } = useGetTasks(sourceType, sourceId)

  // console.log(tasks);
  // current module name
  const { moduleName } = useSelector((state) => ({
    moduleName: state.Module?.name,
  }))

  const [selectedTask, setSelectedTask] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isview, setIsView] = useState(false)

  // const [editModal, setEditModal] = useState(false);

  const userId = JSON.parse(localStorage.getItem("authUser"))?.id

  const actions = {
    Header: "Actions",
    accessor: "actions",
    disableFilters: true,
    Cell: (cellProps) => {
      // Actions Icons in each row with its props
      const row = cellProps.row.original

      return (
        <>
          <div className="d-flex align-items-center gap-1">
            <SingleRowAction
              onClick={() => viewTask(row)}
              iconClass="mdi mdi-eye"
              actionName={"View"}
              record={row}
            />

            {row?.users?.some(
              (user) => user.is_follower === 0 && user.id === userId
            ) && (
              <SingleRowAction
                onClick={() => editTask(row)}
                color="text-gray-700"
                iconClass="mdi mdi-pencil"
                actionName="Edit"
                record={row}
              />
            )}
          </div>
        </>
      )
    },
  }

  const newColumns = sourceType === "Claim" ? [...columns, actions] : columns

  // if userId === createdUser Id

  const editTask = (row) => {
    setSelectedTask(row.id)
    setShowModal(true)
    if (isview) setIsView(false)
  }
  const viewTask = (row) => {
    setSelectedTask(row.id)
    setIsView(true)
    setShowModal(true)
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status_id: "",
      start_date: "",
    },
  })
  // filter with status or start date
  const handleFilter = () => {
    if (validation.values) {
      getTasks({
        page: 1,
        status_id: validation.values.status_id,
        search_keys: "start_date",
        search_values: validation.values.start_date,
      })
    }
  }
  return (
    <Modal isOpen={show} toggle={toggle} fullscreen>
      <ModalHeader toggle={toggle}>{`${moduleName}’s Tasks`}</ModalHeader>

      <ModalBody>
        <Row className="align-items-center">
          <Col md={2}>
            <SearchSelect
              id="1572"
              name="status_id"
              inputField={{ label_name: "Status" }}
              value={validation.values.status_id || null}
              onChange={validation.setFieldValue}
              onBlur={validation.setFieldTouched}
              mappingLabel="name"
              mappingValue="id"
              url={`${PREDEFINEDS}?parent_slug=taskStatus-options`}
              invalid={isInputInvalid(validation, "status_id")}
              error={validation.errors.status_id}
              wrapperClassNames="mb-3"
              disableViewPage={true}
            />
          </Col>
          <Col md={2}>
            <DatePickerInput
              id="1574"
              name="start_date"
              inputField={{ label_name: "Start Date" }}
              value={validation.values.start_date}
              onChange={validation.setFieldValue}
              onBlur={validation.setFieldTouched}
              wrapperClassNames="mb-3"
              pageType="edit"
              // disablePastDates={true}
            />
          </Col>
          <Col md={1} className="d-flex align-items-end">
            <Button
              onClick={handleFilter}
              disabled={
                !validation.values.start_date && !validation.values.status_id
              }
              className="btn btn-secondary w-md text-center mt-2"
            >
              {buttonsTitles.filter}
            </Button>
          </Col>
        </Row>
        <TableContainer
          loading={loadingTaks}
          columns={newColumns}
          data={tasks}
          pagination={pagination}
          getData={getTasks}
          resetInput={resetInput}
          setResetInput={setResetInput}
          // for show / hide columns
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          fullColumns={generateColumns(actionFields)}
          sheetName={"Staff Tasks"}
          moduleId={moduleId}
          removeActionsButtons={true}
          removeActionsColumns={true}
          removePagination={true}
        />

        {showModal && (
          <EditSourceTask
            id={selectedTask}
            setShow={setShowModal}
            show={showModal}
            sourceType={sourceType}
            sourceId={sourceId}
            isView={isview}
            setIsView={setIsView}
            refetchList={() => {
              getTasks({ page: 1, per_page: 10 })
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {buttonsTitles.close}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewSourceTasks
