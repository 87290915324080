import React from "react"
import { Alert } from "reactstrap"

const CustomAlert = ({ children, color }) => {
  return (
    <Alert color={color}>
      <i className="mdi mdi-alert-decagram font-size-16 me-2"></i>
      {children}
    </Alert>
  )
}

export default CustomAlert
