import React, { Fragment, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"

// UI Components
import {
  FormModal,
  SearchTree,
  FormControll,
  ModalSpinner,
} from "components/TP/Common/Forms"
import { Alert } from "reactstrap"

// redux hooks
import { useSelector } from "react-redux"
// helper functions

//utils functions
import { isInputInvalid, fieldFinder } from "utils"
import PopUpFormTitle from "components/TP/Common/General/PopUpFormTitle"
import { showPredefined } from "helpers/Settings/predefineds/predefineds_herlper"

const DeclinedClaimApprovModal = ({
  isModal,
  setIsModal,
  validation,
  inputs,
  error,
  t,
}) => {
  // region Permission Level 3 states
  const { loading } = useSelector(state => ({
    loading: state.Module.loading,
  }))

  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
      }}
      onSaveClick={validation.handleSubmit}
      modalTitle={t("Approved Claim")}
      isSaving={validation.isSubmitting}
    >
      {loading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={e => e.preventDefault()}>
          {/* API Error Message For Region Module */}

          {error ? <Alert color="danger">{error}</Alert> : null}
          {inputs && inputs.length > 0 && (
            <Fragment>
              {/* Special Coverage Dropdown */}
              <SearchTree
                id="529"
                name="business_consideration_type_id"
                inputField={fieldFinder(inputs, 529)}
                value={validation.values.business_consideration_type_id || null}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                fieldNames={{
                  children: "children",
                  label: "name",
                  value: "id",
                }}
                getTreeData={showPredefined}
                recordId={fieldFinder(inputs, 529)?.predefined_value?.id}
                invalid={isInputInvalid(
                  validation,
                  "business_consideration_type_id"
                )}
                error={validation.errors.business_consideration_type_id}
                wrapperClassNames="mb-3"
                // readOnly={pageType === "processing" ? true : false}
              />

              {/* business Consideration Amount */}

              <FormControll
                id="528"
                name="business_consideration_amount"
                type="number"
                inputField={fieldFinder(inputs, 528)}
                {...validation.getFieldProps("business_consideration_amount")}
                invalid={isInputInvalid(
                  validation,
                  "business_consideration_amount"
                )}
                error={validation.errors.business_consideration_amount}
                wrapperClassNames="mb-3"
                // readOnly={pageType === "processing" ? true : false}
              />
            </Fragment>
          )}
        </form>
      )}
    </FormModal>
  )
}

export default withTranslation()(DeclinedClaimApprovModal)
