import React, { Fragment } from "react"
import { buttonsTitles } from "common/data"
import { StickyHeaderTable } from "components/TP/Common/Tables"
import { CSVLink } from "react-csv"
import { exportToExcel } from "../../../utils"

const FnolReportTable = ({ reports, isLoading }) => {
  const CSVHeaders = [
    { label: "Reference", key: "reference" },
    { label: "Declaration Date", key: "declaration_date" },
    { label: "Accident Date", key: "accident_date" },
    { label: "Accident Time", key: "accident_time" },
    { label: "Accident Type", key: "accident_type_name" },
    { label: "Accident Region", key: "accident_region_name" },
    { label: "Accident location", key: "accident_location" },
    { label: "Insurer Name", key: "payer_name" },
    { label: "Insured Name", key: "insured_name" },
    { label: "Red Flag Name", key: "red_flag_name" },
    { label: "No Dispatch reason", key: "no_dispatch_reason_name" },
    { label: "Airbag Opened", key: "airbag_opened" },
    { label: "Problem Type", key: "problem_name" },
    { label: "Number Of Vehicles", key: "number_of_vehicles" },
    { label: "Number Of Property", key: "number_of_property" },
    { label: "Number Of Bodily Injuries", key: "number_of_bodily_injuries" },
    { label: "Number Of Deaths", key: "number_of_deaths" },
    { label: "Property Type", key: "property_type_name" },
    { label: "Estimated amount $", key: "estimation_amount" },
    { label: "Vehicle Outside Road", key: "vehicle_outside_road" },
    { label: "Driver First Name", key: "driver_first_name" },
    { label: "Driver Father Name", key: "driver_father_name" },
    { label: "Driver Last Name", key: "driver_last_name" },
    { label: "Driver Mobile", key: "driver_mobile" },
    { label: "Expert Name", key: "expert_name" },
    { label: "Expert Fees", key: "mt_expert_fee_amount" },
    { label: "Plate Number", key: "mt_vehicle_plate_number" },
    { label: "Chasis Number", key: "mt_vehicle_chasis_number" },
    { label: "Plate Code", key: "mt_vehicle_plate_code" },
    { label: "Brand", key: "mt_vehicle_brand_name" },
    { label: "Model", key: "mt_vehicle_module_name" },
    { label: "Make Year", key: "mt_vehicle_make_year" },
    { label: "Policy Reference", key: "mt_policy_reference" },
    { label: "Policy Type", key: "mt_policy_type_name" },
    { label: "Policy Category", key: "mt_policy_category_name" },
    { label: "Policy Status", key: "mt_policy_status_name" },
    { label: "Special Notes", key: "special_notes" },
    { label: "Reported By", key: "reported_by_name" },
    { label: "Created By", key: "created_by_name" },
    { label: "Status", key: "status_name" },
  ]

  const CSVData = reports.map((report) => ({
    ...report,
  }))
  return (
    <Fragment>
      <div className="mb-4 d-flex gap-2 align-items-end justify-content-end">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            exportToExcel({
              headers: CSVHeaders,
              data: CSVData,
              filename: "fnol_report",
            })
          }}
        >
          {buttonsTitles.exportExcel}
        </button>
      </div>

      <StickyHeaderTable
        columns={CSVHeaders.map((item) => item.label)}
        isLoading={isLoading}
        noData={reports?.length === 0}
      >
        {reports.length > 0 &&
          reports?.map((fnol) => (
            <tr key={fnol.id}>
              <td>{fnol?.reference}</td>
              <td>{fnol?.declaration_date}</td>
              <td>{fnol?.accident_date}</td>
              <td>{fnol?.accident_time}</td>
              <td>{fnol?.accident_type_name}</td>
              <td>{fnol?.accident_region_name}</td>
              <td>{fnol?.accident_location}</td>
              <td>{fnol?.payer_name}</td>
              <td>{fnol?.insured_name}</td>
              <td>{fnol?.red_flag_name}</td>
              <td>{fnol?.no_dispatch_reason_name}</td>
              <td>
                {fnol?.airbag_opened === 0
                  ? "No"
                  : fnol?.airbag_opened === 1
                  ? "Yes"
                  : ""}
              </td>
              <td>{fnol?.problem_name}</td>
              <td>{fnol?.number_of_vehicles}</td>
              <td>{fnol?.number_of_property}</td>
              <td>{fnol?.number_of_bodily_injuries}</td>
              <td>{fnol?.number_of_deaths}</td>
              <td>{fnol?.property_type_name}</td>
              <td>{fnol?.estimation_amount}</td>
              <td>
                {fnol?.vehicle_outside_road === 0
                  ? "No"
                  : fnol?.vehicle_outside_road === 1
                  ? "Yes"
                  : ""}
              </td>
              <td>{fnol?.driver_first_name}</td>
              <td>{fnol?.driver_father_name}</td>
              <td>{fnol?.driver_last_name}</td>
              <td>{fnol?.driver_mobile}</td>
              <td>{fnol?.expert_name}</td>
              <td>{fnol?.mt_expert_fee_amount}</td>
              <td>{fnol?.mt_vehicle_plate_number}</td>
              <td>{fnol?.mt_vehicle_chasis_number}</td>
              <td>{fnol?.mt_vehicle_plate_code}</td>
              <td>{fnol?.mt_vehicle_brand_name}</td>
              <td>{fnol?.mt_vehicle_module_name}</td>
              <td>{fnol?.mt_vehicle_make_year}</td>
              <td>{fnol?.mt_policy_reference}</td>
              <td>{fnol?.mt_policy_type_name}</td>
              <td>{fnol?.mt_policy_category_name}</td>
              <td>{fnol?.mt_policy_status_name}</td>
              <td>{fnol?.special_notes}</td>
              <td>{fnol?.reported_by_name}</td>
              <td>{fnol?.created_by_name}</td>
              <td>{fnol?.status_name}</td>
            </tr>
          ))}
      </StickyHeaderTable>
    </Fragment>
  )
}

export default FnolReportTable
