import { FormControll, FormModal } from "components/TP/Common/Forms";
import { SingleRowAction } from "components/TP/Common/Tables";
import { showFile, updateFile } from "helpers/General/files_helper";
import React, { Fragment, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import {
  apiErrorrHandler,
  convertNullToString,
  NotificationMessage,
} from "utils";
import MultiSkeleton from "../MultiSkeleton";

const EditFileDescriptionModal = ({ fileId, refetchList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const [description, setDescription] = useState("");

  const handleUpdateFile = async () => {
    setIsSaving(true);
    try {
      const res = await updateFile(fileId, {
        description,
      });
      NotificationMessage("Success", "Update");
      setIsOpen(false);
      setDescription("");
      refetchList(res?.item);
      setError("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const getFile = async (id) => {
      setIsLoading(true);
      try {
        const response = await showFile(id);
        const resFile = convertNullToString(response.item);
        setFile(resFile);
        setDescription(resFile.description);
      } catch (error) {
        const errorMessage = apiErrorrHandler(error);
        NotificationMessage("Error", errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (fileId && isOpen) {
      getFile(fileId);
    }
  }, [fileId, isOpen]);

  return (
    <Fragment>
      <SingleRowAction
        onClick={() => setIsOpen(true)}
        iconClass="mdi mdi-pencil"
        actionName={"Edit Description"}
        iconSize="font-size-14"
        record={{ id: fileId }}
      />

      {isOpen && (
        <FormModal
          show={isOpen}
          onCloseClick={() => {
            setIsOpen(false);
            setError("");
          }}
          onSaveClick={() => {
            handleUpdateFile();
          }}
          modalTitle={"Edit File Description"}
          isSaving={isSaving}
          noFooter={isLoading}
        >
          {isLoading ? (
            <MultiSkeleton length={1} />
          ) : (
            <form onSubmit={(e) => e.preventDefault()}>
              {/* API Error Message For Region Module */}
              {error ? <Alert color="danger">{error}</Alert> : null}

              <FormControll
                id="1"
                name="name"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                }}
                type="text"
                inputField={{ label_name: "Description" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </form>
          )}
        </FormModal>
      )}
    </Fragment>
  );
};

export default EditFileDescriptionModal;
