import React from "react"

const ToggleSwitch = ({ label, id, name, ...rest }) => {
  const { value } = rest
  return (
    <div className="form-check form-switch mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        name={name}
        {...rest}
        defaultChecked={value}
      />
      <label className="form-check-label text-capitalize" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default ToggleSwitch
