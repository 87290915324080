/*
This is Hook is used to check if the 
user want to leave the tab without saving her/his changes in this tab
*/

import { isEqual, omit, remove } from "lodash"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { toggleChange } from "store/actions"
import { isObjectValuesEmpty } from "utils/smallUtils"

const useTabLeave = validation => {
  const dispatch = useDispatch()

  const newInitialValues = Object.fromEntries(
    // convert prices to array, map each key/value pair into another pair
    // and then fromEntries gives back the object
    Object.entries(validation.initialValues).map(([key, value]) => {
      return Array.isArray(value) && value?.length > 0
        ? [
            key,
            remove(
              value.map(item =>
                typeof item == "object" && item != null
                  ? omit(item, ["id"])
                  : item
              ),
              function (item) {
                return typeof item == "object" && item != null
                  ? !isObjectValuesEmpty(item)
                  : true
              }
            ),
          ]
        : [key, value]
    })
  )
  const newValues = Object.fromEntries(
    // convert prices to array, map each key/value pair into another pair
    // and then fromEntries gives back the object
    Object.entries(validation.values).map(([key, value]) => {
      return Array.isArray(value) && value?.length > 0
        ? [
            key,
            remove(
              value.map(item =>
                typeof item == "object" && item != null
                  ? omit(item, ["id"])
                  : item
              ),
              function (item) {
                return typeof item == "object" && item != null
                  ? !isObjectValuesEmpty(item)
                  : true
              }
            ),
          ]
        : [key, value]
    })
  )

  // useEffect(() => {
  //   if (isEqual(validation.initialValues, validation.values)) {
  //     dispatch(toggleChange({ value: false }))
  //   } else {
  //     dispatch(toggleChange({ value: true }))
  //   }
  // }, [JSON.stringify(validation.values)])
  useEffect(() => {
    if (isEqual(newInitialValues, newValues)) {
      dispatch(toggleChange({ value: false }))
    } else {
      dispatch(toggleChange({ value: true }))
    }
  }, [JSON.stringify(validation.values)])
}

export default useTabLeave
