import Excel from "assets/images/fileTypes/excel.png";
import PDF from "assets/images/fileTypes/pdf.png";
import Word from "assets/images/fileTypes/word.png";
import MP3 from "assets/images/fileTypes/mp3.png";

// generate preview base on extension
const generateFilePreview = (file) => {
  // if file has name put it else put the path comming from DB
  const fileName = file.name ? file.name : file.path;
  // extract extension from fileName
  const extension = fileName?.split(".")?.pop();
  let imagePreview;
  if (extension === "pdf") {
    imagePreview = PDF;
  } else if (extension === "docx" || extension === "doc") {
    imagePreview = Word;
  } else if (extension === "csv" || extension === "xlsx") {
    imagePreview = Excel;
  } else if (extension === "mp3") {
    imagePreview = MP3;
  } else {
    imagePreview = file.id ? file?.path : URL.createObjectURL(file);
  }

  return imagePreview;
};

export { generateFilePreview };
