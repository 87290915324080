import React, { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Form, Row } from "reactstrap";
import CustomAlert from "../../../components/TP/Common/General/CustomAlert";
import {
  AsyncSearchSelect,
  DatePickerInput,
  DateRangePicker,
  SearchSelect,
} from "../../../components/TP/Common/Forms";
import { PREDEFINEDS } from "../../../helpers/url_helper";
import {
  apiErrorrHandler,
  fieldFinder,
  generateYearsOptions,
  isInputInvalid,
  NotificationMessage,
  objectToValue,
} from "../../../utils";
import { staticFieldLabels } from "../../../common/data";
import { FormButtons } from "../../../components/TP/Common/Forms/FormButtons";
import moment from "moment/moment";
import { LoadingModal } from "../../../components/TP/Common/Modals";
import {
  generalRequest,
  postRequest,
} from "../../../helpers/General/general_request";
import FnolReportTable from "../FnolReport/FnolReportTable";
import MedReportTable from "./MedReportTable";

const MedReportForm = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAddTreaty = async (values, setSubmitting, resetForm) => {
    setIsLoading(true);
    try {
      const request = await generalRequest({
        url: `claim/report/medical-opinion-cases`,
        status_ids: values.status_ids,
        specialty_ids: values.specialty_ids,
      });

      setItems(request.items);
      NotificationMessage("success", "Report Generated Successfully");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status_ids: "",
      specialty_ids: "",
    },
    validationSchema: Yup.object({
      status_ids: Yup.array(),
      specialty_ids: Yup.array(),
    }),

    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleAddTreaty(values, setSubmitting, resetForm);
    },
  });

  console.log(items);

  const {
    values,
    setFieldValue,
    isSubmitting,
    setFieldTouched,
    errors,
    handleSubmit,
  } = validation;
  // console.log(errors);
  return (
    <Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          return false;
        }}
      >
        {error ? <CustomAlert color="danger">{error}</CustomAlert> : null}

        <>
          <Row>
            <Col md={4}>
              <SearchSelect
                name="status_ids"
                inputField={{ predefined_value: true }}
                label="Status"
                value={values.status_ids}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                url={`${PREDEFINEDS}/1796`}
                mappingValue="id"
                mappingLabel="name"
                invalid={isInputInvalid(validation, "status_ids")}
                error={errors.status_ids}
                wrapperClassNames="mb-3"
                multiple={true}
                fixedMenu={true}
              />
            </Col>
            <Col md={4}>
              <SearchSelect
                name="specialty_ids"
                inputField={{ predefined_value: true }}
                label="Specialty"
                value={values.specialty_ids}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                url={`${PREDEFINEDS}/8`}
                mappingValue="id"
                mappingLabel="name"
                invalid={isInputInvalid(validation, "specialty_ids")}
                error={errors.specialty_ids}
                wrapperClassNames="mb-3"
                multiple={true}
                fixedMenu={true}
              />
            </Col>
          </Row>
          {/* Form Buttons */}

          <FormButtons
            disabled={isSubmitting}
            isSaving={isSubmitting}
            cancelLink="/"
          />
        </>
      </Form>
      {items.length > 0 && (
        <MedReportTable reports={items} isLoading={isLoading} />
      )}
      <LoadingModal isOpen={isLoading} />
    </Fragment>
  );
};

export default MedReportForm;
