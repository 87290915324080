import React, { Fragment, useEffect, useState } from "react";
import { FormControll, FormModal } from "components/TP/Common/Forms";
import { apiErrorrHandler, flattenObject, NotificationMessage } from "utils";
import { postRequest } from "helpers/General/general_request";
import { PREDEFINEDS, SENDSMS } from "helpers/url_helper";
import { fetcher } from "utils/API/SWRFetcher";
import useSWR from "swr";

const SendSMSModal = ({ isModal, setIsModal, claim }) => {
  const [number, setNumber] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [text, setText] = useState("");

  const url = `${PREDEFINEDS}/22663`;

  // get fields
  const {
    isLoading,
    error: fieldError,
    data: predefined,
  } = useSWR(
    () => (url && isModal ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (claim?.beneficiary?.mobile) {
      setNumber(claim?.beneficiary?.mobile);
    } else {
      setNumber("");
    }

    if (predefined && claim) {
      const message = predefined?.name
        ?.replace("{beneficiary.first_name}", claim?.beneficiary?.first_name)
        ?.replace("{claim.reference}", claim?.reference);
      setText(message);
    } else {
      setText("");
    }
  }, [JSON.stringify(claim), JSON.stringify(predefined)]);

  const sendSMS = async () => {
    const lang = localStorage.getItem("i18nextLng");

    setIsSaving(true);
    try {
      await postRequest(SENDSMS, {
        message: text,
        destination_phone: number,
        lang_code: lang ? lang : "en",
      });
      NotificationMessage("success", "SMS Sent Successfully");
      setIsModal(false);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false);
        setNumber("");
        setText("");
      }}
      onSaveClick={() => {
        sendSMS();
      }}
      saveButtonTitle="Send"
      modalTitle={"Send SMS"}
      isSaving={isSaving}
      disableSave={!number || !text ? true : false}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Fragment>
          <FormControll
            id="number"
            label={"Number"}
            placeholder="961 xxx xxxx"
            value={number}
            type="text"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <FormControll
            id="text"
            label={"Text"}
            placeholder="enter your text"
            value={text}
            type="textarea"
            onChange={(e) => {
              setText(e.target.value);
            }}
            wrapperClassNames="mt-3"
          />
        </Fragment>
      </form>
    </FormModal>
  );
};

export default SendSMSModal;
