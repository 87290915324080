import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { useDispatch } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar.png"
import { logoutUser } from "store/actions"
import SaveConfirmationModal from "components/TP/Common/Modals/SaveConfirmationModal"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { updateUserOnDuty } from "helpers/Settings/Users/users_helper"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("")
  const [userPhoto, setUserPhoto] = useState("")
  const [userDuty, setUserDuty] = useState(0)
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const authUser = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    if (authUser) {
      setusername(authUser.first_name)
      setUserPhoto(authUser?.photo?.path)
      setUserDuty(authUser?.on_duty)
    }
  }, [JSON.stringify(authUser)])

  // Force Logout User
  const changeUserDusty = async () => {
    if (!authUser) return
    setIsSaving(true)
    try {
      const res = await updateUserOnDuty(authUser?.id, {
        on_duty: userDuty ? 1 : 0,
      })
      setUserDuty(res?.item?.on_duty)
      // update the local storage
      localStorage.setItem(
        "authUser",
        JSON.stringify({ ...authUser, on_duty: res?.item?.on_duty })
      )
      NotificationMessage("success", "update")
      setIsConfirmModal(false)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item text-white"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userPhoto || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          {/* profile */}
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link>
          <div className="dropdown-divider" />

          {/* on / off duty switcher */}
          <div className="dropdown-item">
            <div className="form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="on-off duty"
                defaultChecked={userDuty ? true : false}
                onClick={() => {
                  setUserDuty(prev => !prev)
                  setIsConfirmModal(true)
                }}
              />
              <label className="font-size-10 ms-2" htmlFor="on-off duty">
                On / Off Duty
              </label>
            </div>
          </div>
          <div className="dropdown-divider" />
          {/* Logout */}
          <DropdownItem
            tag="button"
            onClick={() => dispatch(logoutUser(history))}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {/* confirmation message for Force Logout User */}

      {isConfirmModal && (
        <SaveConfirmationModal
          show={isConfirmModal}
          onCloseClick={() => {
            setIsConfirmModal(false)
            setUserDuty(prev => !prev)
          }}
          isSaving={isSaving}
          onConfirmClick={() => changeUserDusty()}
          modalTitle={`${userDuty ? "On" : "Off"} Duty User`}
          confirmationMessage={`Are you sure you want to ${
            userDuty ? "On" : "Off"
          } Duty this User?`}
        />
      )}
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
