import React, { Fragment } from "react";

const ClaimChatNotification = ({ claim, inClaimForm = false }) => {
  const loggedInUserAccessType = JSON.parse(localStorage.getItem("authUser"))
    ?.access_type?.id;

  return (
    <Fragment>
      {/* if the array of the conversations has an item with  */}
      {/* conversation access type insurer or Provider and last message of this conversation is unread and from the Insurer or Provider */}
      {/* show this bill */}
      {claim?.conversations?.length > 0 &&
        claim?.conversations?.filter(
          (item) =>
            item?.access_type?.id !== loggedInUserAccessType &&
            item?.last_message &&
            item?.last_message?.user?.access_type?.id !==
              loggedInUserAccessType &&
            !item?.last_message?.is_read
        )?.length > 0 && (
          <span
            className="badge bg-danger d-flex align-items-center justify-content-center rounded-circle align-self-baseline position-absolute"
            style={{
              width: "15px",
              height: "15px",
              right: inClaimForm ? "-3px" : "-8px",
              top: inClaimForm ? "-10px" : "-5px",
            }}
          >
            <i className="bx bx-bell bx-tada font-size-12" />
          </span>
        )}

      {/* {claim?.last_message_conversation &&
        claim?.last_message_conversation?.last_message &&
        !claim?.last_message_conversation?.last_message?.is_read &&
        claim?.last_message_conversation?.last_message?.user.access_type?.id !==
          loggedInUserAccessType && (
          <span
            className="badge bg-danger d-flex align-items-center justify-content-center rounded-circle align-self-baseline position-absolute"
            style={{
              width: "15px",
              height: "15px",
              right: inClaimForm ? "-3px" : "-8px",
              top: inClaimForm ? "-10px" : "-5px",
            }}
          >
            <i className="bx bx-bell bx-tada font-size-12" />
          </span>
        )} */}
    </Fragment>
  );
};

export default ClaimChatNotification;
