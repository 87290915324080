export const motorPaths = {
  motoProvider: "/mt-providers",
  motoDriver: "/mt-driver",
  fnol: "/mt-fnol",
  fnolValidation: "/fnol-verification",
  motoVehicle: "/mt-vehicles",
  motoExpert: "/mt-experts",
  motoSpareParts: "/mt-spare-parts",
  motoClaims: "/mt-claims",
  motoPolicies: "/mt_policies",
  motoInsured: "/mt_insured",
  towProviders: "/mt_tow_providers",
  surveyors: "/mt_surveyors",
  supplier: "/mt_suppliers",
  panels: "/mt_panels",
  survey: "/survey",
  vehicleBrackets: "/vehicle-brackets",
  fnolDelegation: "/fnol-delegation",
}
