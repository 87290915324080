/*
    this hook is to scroll up to the top of 
   the page
*/

import { useEffect, useState } from "react"

// pass in an array of errors
const useScrollToTop = () => {
  const [scrollTop, setScrollTop] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY >= 500 ? true : false)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return { scrollTop }
}

export default useScrollToTop
