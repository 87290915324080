import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap"

const ShortcutMenu = () => {
  const [socialDrp, setsocialDrp] = useState(false)

  const { modules } = useSelector(state => ({
    modules: state.Sidebar.modules,
  }))
  return (
    <Dropdown
      className="d-none d-lg-inline-block ms-1"
      isOpen={socialDrp}
      toggle={() => {
        setsocialDrp(!socialDrp)
      }}
    >
      {/* Shortcuts Menu Toggler */}

      <DropdownToggle className="btn header-item noti-icon " tag="button">
        <i className="bx bx-customize" />
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
        <div className="px-lg-2">
          <Row className="no-gutters">
            {modules.length > 0 &&
              modules.map(module => (
                <Fragment key={module.id}>
                  {module.sub_modules.length > 0 &&
                    module.sub_modules.map(
                      sub_module =>
                        sub_module.shortcut === 1 && (
                          <Col key={sub_module.id}>
                            <Link
                              className="dropdown-icon-item"
                              style={{
                                width: "140px",
                                padding: "6px 0px 6px",
                                lineHeight: "24px",
                              }}
                              to={`/${sub_module.name
                                .toLowerCase()
                                .replace(" ", "-")}`}
                            >
                              <i
                                className={`${sub_module.icon} font-size-24`}
                              ></i>
                              <span>{sub_module.title}</span>
                            </Link>
                          </Col>
                        )
                    )}
                </Fragment>
              ))}
          </Row>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ShortcutMenu
