import { FILES } from "../url_helper";
import { request } from "../api_helper";

// Get All Files
//url
export const getAllFiles = () => request({ url: FILES, method: "GET" });

// Add File
//url
export const addFile = (data) =>
  request({
    url: FILES,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });

// show File
//url/{id}
export const updateFile = (fileId, data) =>
  request({ url: `${FILES}/${fileId}`, method: "PUT", data });
// show File
//url/{id}
export const showFile = (fileId) =>
  request({ url: `${FILES}/${fileId}`, method: "GET" });

// delete File
//url/{id}
export const deleteFile = (fileId) =>
  request({ url: `${FILES}/${fileId}`, method: "DELETE" });
