import PropTypes from "prop-types"
import React from "react"
import { Modal } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
import { buttonsTitles } from "common/data"
import CustomButton from "../General/CustomButton"
const FormModal = ({
  show,
  onSaveClick,
  onCloseClick,
  isSaving,
  children,
  t,
  modalTitle,
  modalSize,
  noFooter,
  notCentered,
  includeComputeButton,
  onComputeClick,
  hideSaveButton,
  saveButtonTitle,
  saveButtonBG,
  scrollable,
  additionalFooterButtons,
  disableSave,
  disableTooltip,
  closeOnBackdropClick = false,
}) => {
  return (
    <Modal
      size={modalSize ? modalSize : "md"}
      isOpen={show}
      toggle={onCloseClick}
      centered={notCentered ? false : true}
      backdrop={closeOnBackdropClick ? true : "static"}
      scrollable={scrollable ? scrollable : false}
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          {modalTitle}
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">{children}</div>

      {/* Modal Footer */}
      {!noFooter && (
        <div className="modal-footer">
          {additionalFooterButtons && additionalFooterButtons}
          {hideSaveButton ? null : (
            <CustomButton
              type="button"
              className={`btn ${
                saveButtonBG ? `btn-${saveButtonBG}` : "btn-success"
              } w-md`}
              onClick={onSaveClick}
              disabled={isSaving || disableSave ? true : false}
              disabledTooltip={disableTooltip ? disableTooltip : ""}
              title={
                saveButtonTitle ? t(`${saveButtonTitle}`) : buttonsTitles.save
              }
              isSaving={isSaving}
            />
          )}

          {includeComputeButton ? (
            <CustomButton
              className="btn btn-secondary"
              type="button"
              onClick={onComputeClick}
              disabled={isSaving || disableSave}
              disabledTooltip={disableTooltip ? disableTooltip : ""}
              isSaving={isSaving}
              title={buttonsTitles.compute}
            />
          ) : null}
        </div>
      )}
    </Modal>
  )
}

FormModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default withTranslation()(FormModal)
