import React, { useEffect, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import AddSourceTask from './AddSourceTask/AddSourceTask';
import ViewSourceTasks from './SourceTasks/ViewSourceTasks';
import AddReminderModal from 'components/TP/Claims/AddReminderModal';
import ViewReminders from './RemindersTasks/ViewReminders';

function SourceTaskDropdown({ sourceId, sourceType }) {
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenReminder, setIsOpenReminder] = useState(false);
  const [openReminders, setOpenReminders] = useState(false);

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          color="danger"
          className="badge bg-danger fw-semibold py-2 px-2"
        >
          Tasks
          <i className="mdi mdi-chevron-down px-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setIsOpenView(true)}>
            View Tasks
          </DropdownItem>
          <DropdownItem onClick={() => setIsOpenAdd(true)}>
            Add Task
          </DropdownItem>
          {/* this option will be shown only in claim */}
          {sourceType === 'Claim' ? (
            <>
              <DropdownItem onClick={() => setIsOpenReminder(true)}>
                Add Reminder
              </DropdownItem>
              <DropdownItem onClick={() => setOpenReminders(true)}>
                View Reminder
              </DropdownItem>
            </>
          ) : null}
        </DropdownMenu>
      </UncontrolledDropdown>

      {isOpenView && (
        <ViewSourceTasks
          sourceId={sourceId}
          sourceType={sourceType}
          setShow={setIsOpenView}
          show={isOpenView}
        />
      )}

      {isOpenAdd && (
        <AddSourceTask
          sourceId={sourceId}
          sourceType={sourceType}
          setShow={setIsOpenAdd}
          show={isOpenAdd}
        />
      )}
      {isOpenReminder && (
        <AddReminderModal
          claimId={sourceId}
          setIsModal={setIsOpenReminder}
          isModal={isOpenReminder}
        />
      )}
      {openReminders && (
        <ViewReminders
        sourceId={sourceId}
          // sourceType={sourceType}
          setShow={setOpenReminders}
          show={openReminders}
        />
      )}
    </>
  );
}

export default SourceTaskDropdown;
