import React, { Fragment, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import {
  apiErrorrHandler,
  fieldFinder,
  generateFieldValidation,
  isInputInvalid,
  NotificationMessage,
} from "utils"
import {
  FormControll,
  FormEditor,
  FormModal,
  ModalSpinner,
} from "../Common/Forms"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import {
  claimAdmissionDetailsId,
  claimDetailsModuleId,
  claimFormModuleId,
  claimMedicalDetailsId,
  claimProcessingDetailsId,
} from "utils/modulesIds"
import flatten from "lodash/flatten"
import NotesTable from "./Forms/NotesTable"
import useSWR from "swr"
import * as URLs from "helpers/url_helper"
import { fetcher } from "utils/API/SWRFetcher"

const ClaimNotesModal = ({ claimId, onCloseClick, show, notInClaim }) => {
  // state
  const {
    admissionFields,
    medicalFields,
    processingFields,
    loading: Loading,
  } = useSelector(state => ({
    // Admission Details Fields
    admissionFields: state?.Module?.subModules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)?.fields,
    // Medical Details Fields
    medicalFields: state?.Module?.subModules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimMedicalDetailsId)?.fields,

    // Processing Details Fields
    processingFields: state?.Module?.subModules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimProcessingDetailsId)?.fields,
    loading: state.Module.loading,
  }))

  const [inputs, setInputs] = useState([])

  const url = `${URLs.MODULES_SUBMODULES}/${claimFormModuleId}`

  const afterFetch = data => {
    const admissionSectionfields = data?.sub_modules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)?.fields
    const medicalSectionfields = data?.sub_modules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimMedicalDetailsId)?.fields
    const processingSectionfields = data?.sub_modules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimProcessingDetailsId)?.fields

    setInputs(
      flatten([
        admissionSectionfields,
        medicalSectionfields,
        processingSectionfields,
      ])
    )
  }
  // fetch claim permission if this component got called in any module other than claim
  const {
    isLoading: loading,
    error: err,
    data,
  } = useSWR(
    () => (url && notInClaim ? [url] : null),
    ([url]) => fetcher(url, {}, afterFetch),
    {
      revalidateOnFocus: false,
    }
  )

  // fetch claim
  const {
    isLoading,
    error,
    data: claim,
  } = useSWR(
    () => (claimId ? [`${URLs.CLAIMFORM}/${claimId}`] : null),
    ([claimURL]) => fetcher(claimURL),
    {
      revalidateOnFocus: false,
    }
  )

  // fetch claim permission if the component isn’t in the claim form

  useEffect(() => {
    if (!notInClaim) {
      setInputs(flatten([admissionFields, medicalFields, processingFields]))
    }
  }, [notInClaim])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      processing_notes: claim ? claim.processing_notes : "",
      authorization_notes: claim ? claim.authorization_notes : "",
      internal_notes: claim ? claim.internal_notes : "",
    },
    validationSchema: Yup.object({
      processing_notes: generateFieldValidation(inputs, 523, "string"),
      authorization_notes: generateFieldValidation(inputs, 406, "string"),

      internal_notes: generateFieldValidation(inputs, 407, "string"),
    }),
  })

  const { values, setFieldValue, setFieldTouched, errors } = validation
  return (
    <Fragment>
      <FormModal
        modalSize="md"
        show={show}
        onCloseClick={onCloseClick}
        modalTitle="Notes"
        noFooter={true}
        scrollable={true}
      >
        {/* Modal Body */}
        {isLoading || loading || Loading ? (
          <ModalSpinner />
        ) : (
          <Fragment>
            {inputs && inputs.length > 0 && (
              <form onSubmit={e => e.preventDefault()}>
                <Fragment>
                  <Row>
                    <Col md={12}>
                      <FormControll
                        id="407"
                        name="internal_notes"
                        type="textarea"
                        inputField={fieldFinder(inputs, 407)}
                        {...validation.getFieldProps("internal_notes")}
                        invalid={isInputInvalid(validation, "internal_notes")}
                        error={errors.internal_notes}
                        wrapperClassNames="mb-3"
                        readOnly={true}
                      />
                    </Col>

                    <Col md={12}>
                      <FormEditor
                        id={406}
                        name="authorization_notes"
                        inputField={fieldFinder(inputs, 406)}
                        {...validation.getFieldProps("authorization_notes")}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          // editorData.current = data
                          setFieldValue("authorization_notes", data)
                        }}
                        onBlur={() => {
                          setFieldTouched("authorization_notes", true)
                        }}
                        invalid={isInputInvalid(
                          validation,
                          "authorization_notes"
                        )}
                        error={errors.authorization_notes}
                        wrapperClassNames="mb-3"
                        readOnly={true}
                      />
                    </Col>
                    <Col md={12}>
                      <FormControll
                        id="523"
                        name="processing_notes"
                        type="textarea"
                        inputField={fieldFinder(inputs, 523)}
                        {...validation.getFieldProps("processing_notes")}
                        invalid={isInputInvalid(validation, "processing_notes")}
                        error={errors.processing_notes}
                        wrapperClassNames="mb-3"
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                  {claim?.benefit?.id === 1 && (
                    <Fragment>
                      <h5>Medical Notes</h5>
                      {/* Medical Notes Table */}
                      <NotesTable
                        notes={claim.notes.filter(
                          item => item?.type?.id === 1690
                        )}
                        removeActionsColumn={true}
                      />
                      <h5>Doctor Notes</h5>
                      {/* Doctor Notes Table */}
                      <NotesTable
                        notes={claim.notes.filter(
                          item => item?.type?.id === 1689
                        )}
                        removeActionsColumn={true}
                      />
                    </Fragment>
                  )}
                </Fragment>
              </form>
            )}
          </Fragment>
        )}
      </FormModal>
    </Fragment>
  )
}

export default ClaimNotesModal
