import React, { lazy } from "react"
import { Redirect } from "react-router-dom"

// Profile
const UserProfile = lazy(() => import("../pages/Authentication/UserProfile"))
const Test = lazy(() => import("../pages/Test/Index"))
const Login = lazy(() => import("../pages/Authentication/Login"))
const Logout = lazy(() => import("../pages/Authentication/Logout"))
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"))
const Dashboard = lazy(() => import("pages/Dashboard/index"))
const Tables = lazy(() => import("pages/Tables"))
const ExportMultipleTables = lazy(() =>
  import("../pages/Tables/ExportMultipleTables")
)
const ConfirmMail = lazy(() => import("pages/Authentication/ConfirmMail"))
const ResetPassword = lazy(() => import("pages/Authentication/ResetPassword"))

// Common Data Modules Pages
import { settingsRoutes } from "./Settings"
import NotFound from "pages/NotFound"
import { CommonDataRoutes } from "./CommonData"
import { Stakeholders } from "./stakeholders"
import { Networks } from "./Netwroks"
import { Productions } from "./Productions"
import { ClaimRoutes } from "./Claim"
import { FinanceRoutes } from "./Finance"
import { DashboardsRoutes } from "./Dashboards"
import { GeneralRoutes } from "./General"
import { ReportsRoutes } from "./Reports"
import { motorRoutes } from "./Motors"
// Add Company

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // settings routes
  ...settingsRoutes,
  /* Common Data Routes */
  ...CommonDataRoutes,
  //Stakeholders routes
  ...Stakeholders,
  // networks routes
  ...Networks,
  // production routes
  ...Productions,
  // claim routes
  ...ClaimRoutes,
  // finance routes
  ...FinanceRoutes,
  // dashboards routes
  ...DashboardsRoutes,
  // general routes,
  ...GeneralRoutes,
  // reports routes,
  ...ReportsRoutes,
  //motor routes
  ...motorRoutes,
  // //profile
  { path: "/test", component: Test },
  { path: "/tables", component: Tables },
  { path: "/export-tables", component: ExportMultipleTables },
  { path: "/profile", component: UserProfile },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  // 404 Page
  { path: "*", component: NotFound },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/confirm-mail", component: ConfirmMail },
  { path: "/new-password", component: ResetPassword },
]
export { authProtectedRoutes, publicRoutes }
