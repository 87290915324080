import { buttonsTitles } from "common/data";
import { useModuleActions, usePageType } from "hooks";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { extractSpecificAction } from "utils";
import CustomButton from "../General/CustomButton";

export const TabFormButtons = ({
  module,
  disabled,
  cancelLink,
  cancelOnClick,
  hideSaveButton,
  children,
  isSaving,
}) => {
  const location = useLocation();
  const { pathname } = location;
  // to know if the page link has a view word or not
  const { isViewPage } = usePageType();

  return (
    <div className="d-flex gap-2 mt-3">
      {/* hide the save button based on update permission and in view pages */}
      {extractSpecificAction(module, 2) && !isViewPage && !hideSaveButton ? (
        <CustomButton
          type="submit"
          className="btn btn-primary w-md"
          disabled={disabled}
          title={buttonsTitles.saveChanges}
          isSaving={isSaving}
        />
      ) : null}
      {isViewPage && extractSpecificAction(module, 2) ? (
        <Link
          to={pathname.replace("view", "edit")}
          target="_blank"
          className="btn btn-success"
        >
          {buttonsTitles.edit}
        </Link>
      ) : null}

      {children && children}
      <Link
        to={cancelLink ? cancelLink : "/#"}
        onClick={cancelOnClick && cancelOnClick}
        className="btn btn-secondary"
      >
        {buttonsTitles.back}
      </Link>
    </div>
  );
};

export const FormButtons = ({
  disabled,
  cancelLink,
  includeSubmit,
  cancelOnClick,
  reset,
  children,
  removeCancelButton,
  isSaving,
  saveBtnTitle,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const { isUpdatePermission } = useModuleActions();
  // to know if the page link has a view word or not
  const { isViewPage } = usePageType();

  return (
    <div className="d-flex gap-2 mt-3">
      {/* hide the save button in view pages */}
      {isViewPage ? null : (
        <CustomButton
          type="submit"
          className="btn btn-primary w-md"
          disabled={disabled}
          title={saveBtnTitle ? saveBtnTitle : buttonsTitles.saveChanges}
          isSaving={isSaving}
        />
      )}

      {isViewPage && isUpdatePermission ? (
        <Link
          to={pathname.replace("view", "edit")}
          className="btn btn-success w-md"
        >
          {buttonsTitles.edit}
        </Link>
      ) : null}
      {includeSubmit ? (
        <button
          type="button"
          className="btn btn-success w-md"
          // disabled={disabled}
        >
          Submit
        </button>
      ) : null}

      {children && children}
      {!removeCancelButton ? (
        <Link
          to={cancelLink ? cancelLink : "#"}
          onClick={cancelOnClick && cancelOnClick}
          className="btn btn-secondary"
        >
          {reset ? "Reset" : buttonsTitles.back}
        </Link>
      ) : null}
    </div>
  );
};
