import React, { Fragment } from "react"
import { FormInputError } from "."
import { useLocation } from "react-router-dom"
import { Input, Label } from "reactstrap"
import { usePageType } from "hooks"

const RadioFormInput = ({
  label: labelProp,
  id,
  name,
  wrapperClassNames,
  inputField,
  optionsLabels,
  onChange,
  onBlur,
  ...rest
}) => {
  // to know if the page link has a view word or not
  const { isViewPage } = usePageType()
  const { value, invalid, error } = rest
  const { id: fieldId, ...others } = rest

  const label = labelProp ? labelProp : inputField?.label_name

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      <label
        className={`text-capitalize ${
          inputField?.is_required ? "required-label" : ""
        }`}
        htmlFor={id}
      >
        {label}
      </label>
      <div className="mb-3">
        {optionsLabels.length > 0
          ? optionsLabels.map((item, index) => (
              <Fragment key={`${item?.label}-${index}`}>
                <div className="form-check form-check-inline font-size-16">
                  <Input
                    type="radio"
                    id={`${id}-${index}`}
                    defaultChecked={value === item?.value ? true : false}
                    name={name}
                    onChange={() => onChange(`${name}`, item?.value)}
                    onBlur={() => onBlur(`${name}`, true)}
                    className="form-check-input"
                  />
                  <Label
                    className="form-check-label text-capitalize"
                    htmlFor={`${id}-${index}`}
                  >
                    {item?.label}
                  </Label>
                </div>
                &nbsp;
              </Fragment>
            ))
          : null}
      </div>
      {/* Error Message */}
      {invalid && !isViewPage ? (
        <FormInputError error={error} selectError={true} />
      ) : null}
    </div>
  )
}

export default RadioFormInput
