import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col } from "reactstrap";

import { DefaultColumnFilter } from "./filters";

//i18n
import { withTranslation } from "react-i18next";

import { TablePagination, TableActionsButtons, TableSkeleton } from "./index";
import { NoRecords, RowActions } from "components/TP/Common/Tables";
import {
  apiErrorrHandler,
  NotificationMessage,
  resetSearchColumnInput,
} from "utils";
import { Link } from "react-router-dom";
import { useModuleActions, usePageType } from "hooks";
import { eventManagerModuleID, productionEventsId } from "utils/modulesIds";
import { useSelector } from "react-redux";

// const TableContainer = React.forwardRef(
const TableContainer = ({
  columns,
  data,
  resetInput,
  basicTable,
  subModuleId,
  setResetInput,
  // isGlobalFilter,
  // isJobListGlobalFilter,
  className,
  customPageSizeOptions,
  addPageLink,
  isAddInNewTab,
  handleAdd,
  inCludeExportButton,
  addDropDown,
  batchPageLink,
  isImportInSameTab,
  showOptions,
  setShowOptions,
  fullColumns,
  pagination,
  hidePagination,
  getData,
  loading,
  sheetName,
  sheetHeader,
  getSheetData,
  customCSVHeader,
  customCSVData,
  customPDFHeader,
  customPDFData,
  activateFunc,
  deactivateFunc,
  deleteFunc,
  handleClickUpdate,
  handleViewProcessing,
  handleClickStatus,
  handleClickClone,
  handleClickView,
  handleClickStandardCode,
  handleClickDependency,
  handleClickPrint,
  queryAddOn,
  moduleId,
  removeActionsColumns,
  isBackPermission,
  backPageLink,
  isUnusedMarkPermission,
  handleAddMarkUnused,
  addOnButtons,
  removeActionsButtons,
  hideRecordsDropdown = true,
  includeCheckBoxes,
  selectAllCheckBox,
  idsArray,
  setIdsArray,
  asyncExport,
  generateCustomData,
  claimAdvancedFilters,
  onClaimOwnerChange,
  addOnButtonsComponent,
  removePagination,
  hideTable,
  handleForceLogout,
  isFiltersComponent = false,
  includeReloadButton,
  onReloadClick,
  removeHyperIdColumn,
  removePDFExport = false,
  handleClickAssignToMe,
  isFilters = false,
  onFiltersClick,
  t,
  y,
}) => {
  // number of rows per page
  const [perPage, setPerPage] = useState(
    customPageSizeOptions ? customPageSizeOptions : 10
  );

  const { loading: moduleLoading } = useSelector((state) => ({
    loading: state?.Module?.loading,
  }));
  const [allColumns, setAllColumns] = useState([]);
  const { isUpdatePermission, isViewPermission } = useModuleActions();

  // get the page type from URL
  const { isViewPage } = usePageType();

  const [isActivating, setIsActivating] = useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // rows,
    selectedFlatRows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns: allColumns,
      data,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 1,
        pageSize: perPage,
      },
      manualFilters: true,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const [searchData, setSearchData] = useState({
    search_keys: "",
    search_values: "",
    sort_by: "",
    sort_type: "",
    search_value: "",
  });

  const searchParams = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!resetInput) return;
    if (resetInput) {
      setAllFilters([]);
      setGlobalFilter([]);
      setSearchData({
        search_keys: "",
        search_values: "",
        sort_by: "",
        sort_type: "",
        search_value: "",
      });
      resetSearchColumnInput();
      setResetInput(false);
    }
  }, [resetInput]);

  const componentRef = React.createRef();

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  // handle CheckBoxes change
  const handleCheckBoxChange = (rowId) => {
    const isFound = idsArray.find((item) => item === rowId);
    if (isFound) {
      setIdsArray(idsArray.filter((item) => item !== rowId));
    } else {
      const newIds = [...idsArray, rowId];
      setIdsArray(newIds);
    }
  };

  useEffect(() => {
    if (!columns.length) {
      return;
    }
    let removeUpdatePermission = false;

    const modifiedColumns = columns.map((item) => {
      return item.accessor === "id" && !removeHyperIdColumn
        ? {
            ...item,
            Cell: (cellProps) => {
              const row = cellProps.row.original;
              // remove update icon when module is event manage module and
              // is_excuted property is true or 1
              removeUpdatePermission =
                (moduleId === eventManagerModuleID ||
                  moduleId === productionEventsId) &&
                cellProps.row.original.is_executed
                  ? true
                  : false;
              return (isUpdatePermission && !removeUpdatePermission) ||
                isViewPermission ? (
                <span
                  className="text-success"
                  onClick={() => {
                    if (isViewPage) {
                      handleClickView(row);
                    } else {
                      handleClickUpdate(row);
                    }
                  }}
                >
                  {row[item.accessor]}
                </span>
              ) : (
                <Fragment>{row[item.accessor]}</Fragment>
              );
            },
          }
        : item;
    });

    const columnsWithCheckBoxes = includeCheckBoxes
      ? [
          {
            Header: selectAllCheckBox ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={
                  idsArray.length !== 0 &&
                  data.filter((item) => !item?.disableCheckbox).length !== 0 &&
                  idsArray.length ===
                    data.filter((item) => !item?.disableCheckbox).length
                }
                onChange={(e) => {
                  let arrayOfIds;
                  if (
                    idsArray.length !==
                    data.filter((item) => !item?.disableCheckbox).length
                  ) {
                    arrayOfIds = data
                      .filter((item) => !item?.disableCheckbox)
                      .map((item) => item.id);
                  } else {
                    arrayOfIds = [];
                  }
                  setIdsArray(arrayOfIds);
                }}
              />
            ) : (
              "#"
            ),
            id: "selectedIds",
            Cell: (cellprops) => {
              const rowId = cellprops.row.original.id;
              const row = cellprops.row.original;
              return (
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={idsArray.find((item) => item === rowId)}
                  onChange={(e) => handleCheckBoxChange(rowId)}
                  disabled={row?.disableCheckbox}
                />
              );
            },
          },
          ...modifiedColumns,
        ]
      : modifiedColumns;

    setAllColumns(
      // hide the actions in view pages
      isViewPage || removeActionsColumns
        ? [...columnsWithCheckBoxes]
        : [
            ...columnsWithCheckBoxes,
            {
              Header: "Actions",
              accessor: "actions",
              disableFilters: true,
              disableSortBy: true,
              show: 1,
              Cell: (cellProps) => {
                // Actions Icons in each row with its props
                const row = cellProps.row.original;
                return (
                  <RowActions
                    cellProps={cellProps}
                    onDelete={() => handleDelete(row.id)}
                    onActivate={() => handleActivateDeactivate(row)}
                    isActivating={isActivating}
                    removeUpdatePermission={removeUpdatePermission}
                    onClickUpdate={
                      handleClickUpdate
                        ? () => {
                            handleClickUpdate(row);
                          }
                        : undefined
                    }
                    onClickProcessing={() => {
                      handleViewProcessing(row);
                    }}
                    onClickView={() => {
                      handleClickView(row);
                    }}
                    onClickStatus={() => {
                      handleClickStatus(row);
                    }}
                    onClickCopy={() => {
                      handleClickClone(row);
                    }}
                    onClickStandardCode={() => {
                      handleClickStandardCode(row);
                    }}
                    onClickDependency={() => {
                      handleClickDependency(row);
                    }}
                    onTaskAssignToMeClick={
                      handleClickAssignToMe
                        ? () => {
                            handleClickAssignToMe(row);
                          }
                        : undefined
                    }
                    subModuleId={subModuleId}
                    onClickLog={() => handleClickLog(row)}
                    onClickPrint={() => handleClickPrint(row)}
                  />
                );
              },
            },
          ]
    );
  }, [columns, includeCheckBoxes, idsArray]);

  // Number of records per page
  const onChangeInSelect = (event) => {
    getData({
      page: currentPage,
      per_page: event.target.value,
      ...searchData,
      ...queryAddOn,
      ...searchParams.current,
    });
    setPerPage(Number(event.target.value));
  };

  useEffect(() => {
    if (sortBy.length > 0) {
      sort();
    }
  }, [sortBy]);

  // Sorting function

  const sort = () => {
    const sort_by = headerGroups[0].headers.filter(
      (header) => sortBy[0]?.id === header.id
    )[0]?.customSearchParameter
      ? headerGroups[0].headers.filter(
          (header) => sortBy[0]?.id === header.id
        )[0]?.customSearchParameter
      : headerGroups[0].headers.filter(
          (header) => sortBy[0]?.id === header.id
        )[0]?.name;
    const sort_type = sortBy.length ? (sortBy[0].desc ? "desc" : "asc") : "";
    getData({
      page: currentPage,
      per_page: perPage,
      ...searchData,
      ...queryAddOn,
      sort_by,
      sort_type,
    });
    setSearchData({
      ...searchData,
      sort_by,
      sort_type,
    });
  };

  // Search function
  const search = () => {
    // setAllFilters([])

    let searchObj = {};
    let searchParameters = {};
    const headers = headerGroups[0].headers;

    for (let index = 0; index < headers.length; index++) {
      if (headers[index].filterValue && headers[index].canFilter) {
        if (headers[index].customSearchParameter) {
          searchParameters[headers[index].customSearchParameter] =
            headers[index].filterValue;
        } else {
          searchObj[headers[index].name] = headers[index].filterValue;
        }
      }
    }
    let search_keys = Object.keys(searchObj).join(",");
    let search_values = Object.values(searchObj).join(",");
    setSearchData({ ...searchData, search_keys, search_values });
    searchParams.current = searchParameters;
    getData({
      page: 1,
      per_page: perPage,
      ...searchData,
      ...queryAddOn,
      search_keys,
      search_values,
      ...searchParameters,
      ...searchParams.current,
    });
    setCurrentPage(1);
    // setAllFilters([])
  };

  // Log Function
  const handleClickLog = (row) => {
    const { id } = row;
    window.open(`/logs/${moduleId}/${id}`);
  };

  // Activate Deactivate
  const handleActivateDeactivate = async (row) => {
    const { id, is_active } = row;
    setIsActivating(true);
    try {
      if (is_active === 0) {
        await activateFunc(id);
        NotificationMessage("Success", "activate");
      } else {
        await deactivateFunc(id);
        NotificationMessage("Success", "deactivate");
      }

      // reset the search Data and Column Filter Input
      // setResetInput(true)
      setSearchData({
        search_keys: "",
        search_values: "",
        sort_by: "",
        sort_type: "",
        search_value: "",
      });

      getData({
        page: currentPage,
        per_page: perPage,
        ...searchData,
        ...queryAddOn,
      });
      // setCurrentPage(1)
      setAllFilters([]);
      setGlobalFilter([]);
      resetSearchColumnInput();
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsActivating(false);
    }
  };

  // Delete Function
  const handleDelete = async (id) => {
    try {
      await deleteFunc(id);
      NotificationMessage("Success", "delete");
      setSearchData({
        search_keys: "",
        search_values: "",
        sort_by: "",
        sort_type: "",
        search_value: "",
      });
      getData({
        page: currentPage,
        per_page: perPage,
        ...searchData,
        ...queryAddOn,
      });
      // setCurrentPage(1)
      setAllFilters([]);
      setGlobalFilter([]);
      resetSearchColumnInput();
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  return (
    <Fragment>
      {!removeActionsButtons && (
        <Row className="my-1 justify-content-md-between flex-wrap align-items-center justify-content-center">
          {/* custom filters */}
          {isFiltersComponent ? null : (
            <Col
              md={1}
              className={`${
                hideRecordsDropdown ? "opacity-0 mb-1" : "opacity-1 mb-1"
              }`}
            >
              <select
                className={`form-select font-size-11 ${
                  hideRecordsDropdown ? "d-none" : "d-block"
                }`}
                style={{ width: "fit-content" }}
                value={perPage}
                onChange={onChangeInSelect}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {t("Show")} {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}

          {/* Hide the action buttons in view pages */}
          {!isViewPage && (
            <Col xs="12" md="11">
              {/* action buttons at the top of the table (Add new , Export ,...etc) */}
              <TableActionsButtons
                handleAdd={handleAdd}
                addPageLink={addPageLink}
                isAddInNewTab={isAddInNewTab}
                addDropDown={addDropDown}
                batchPageLink={batchPageLink}
                isImportInSameTab={isImportInSameTab}
                includeReloadButton={includeReloadButton}
                onReloadClick={onReloadClick}
                isBackPermission={isBackPermission}
                backPageLink={backPageLink}
                addOnButtons={addOnButtons}
                asyncExport={asyncExport}
                addOnButtonsComponent={addOnButtonsComponent}
                // for show / hide columns
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                fullColumns={fullColumns}
                isUnusedMarkPermission={isUnusedMarkPermission}
                handleAddMarkUnused={handleAddMarkUnused}
                data={data}
                columns={columns}
                sheetName={sheetName}
                sheetHeader={sheetHeader}
                getSheetData={getSheetData}
                generateCustomData={generateCustomData}
                queryAddOn={queryAddOn}
                customCSVHeader={customCSVHeader}
                customCSVData={customCSVData}
                customPDFHeader={customPDFHeader}
                customPDFData={customPDFData}
                inCludeExportButton={inCludeExportButton}
                onClaimOwnerChange={onClaimOwnerChange}
                claimAdvancedFilters={claimAdvancedFilters}
                noPagingation={!pagination ? true : false}
                handleForceLogout={handleForceLogout}
                removePDFExport={removePDFExport}
                isFilters={isFilters}
                onFiltersClick={onFiltersClick}
                // ref={componentRef}
                subModuleId={subModuleId}
                moduleId={moduleId}
              />
            </Col>
          )}
        </Row>
      )}

      {/* the table  */}
      {!hideTable && (
        <div
          className="table-responsive react-table"
          // ref={componentRef}
        >
          <Table
            hover={!loading}
            {...getTableProps()}
            className={`${className}`}
            style={{
              position: "relative",
              height: loading && "700px",
            }}
          >
            <thead className="table-light table-nowrap">
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="text-capitalize"
                    >
                      <div className="mb-2" {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>

                      {/* search bar in each column header */}
                      {!basicTable && (
                        <DefaultColumnFilter
                          column={column}
                          search={search}
                          dataLength={moduleLoading ? 0 : 1}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={allColumns?.length}>
                    <div
                      style={{
                        position: "absolute",
                        left: "0",
                        right: "0",
                      }}
                    >
                      <TableSkeleton />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                <tbody {...getTableBodyProps()}>
                  {data.length ? (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <Fragment key={row.getRowProps().key}>
                          <tr
                            {...row.getRowProps()}
                            className={
                              row.original.rowClassNames
                                ? row.original.rowClassNames
                                : ""
                            }
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  {...cell.getCellProps()}
                                  className="text-capitalize"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        </Fragment>
                      );
                    })
                  ) : (
                    <tr />
                  )}
                </tbody>
              </>
            )}
          </Table>
          {!data.length && !loading ? <NoRecords /> : ""}
        </div>
      )}

      {/* Table Pagination Component */}
      {(pagination || !hidePagination) && (
        <TablePagination
          pagination={pagination}
          getData={(currentPage) => {
            getData({
              ...searchData,
              ...queryAddOn,
              ...searchParams.current,
              page: currentPage,
              per_page: perPage,
            });
          }}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          resetInput={resetInput}
          removePagination={removePagination}
        />
      )}
    </Fragment>
  );
};
// )

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
};
TableContainer.displayName = "TableContainer";

export default withTranslation()(TableContainer);
