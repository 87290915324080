import { deleteFile } from "helpers/General/files_helper";
import React, { Fragment, useState } from "react";
import { Card, Col, Row, UncontrolledTooltip } from "reactstrap";
import { NotificationMessage, apiErrorrHandler } from "utils";
import { DeleteModal } from "../Modals";
import { withTranslation } from "react-i18next";
import { buttonsTitles } from "common/data";
import { useModuleActions, usePageType } from "hooks";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import CustomButton from "../General/CustomButton";
import DataIcon from "../General/DataIcon";
import { previewDateFormat } from "constants/TPA";
import moment from "moment";
import { generalRequest } from "helpers/General/general_request";
import PerfectScrollbar from "react-perfect-scrollbar";
import EditFileDescriptionModal from "./EditFileDescriptionModal";

const DropZonePreviews = ({
  selectedFiles,
  setselectedFiles,
  typeId,
  isDownloadAll = false,
  zipFileName,
  removeDeleteAction = false,
  t,
}) => {
  const { isDeleteFilePermission } = useModuleActions();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [fetching, setFetching] = useState(false);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);

  const { isViewPage, isViewProcessingPage } = usePageType();
  const filterSelectedFiles = (fileName) => {
    const filteredFiles = selectedFiles.filter(
      (file) => file.name !== fileName
    );
    setselectedFiles(filteredFiles);
  };

  // on Delete button Click
  const handleDeleteClick = (file) => {
    if (file?.id) {
      setIsDeleteModal(true);
      setFileToDelete(file?.id);
    } else {
      filterSelectedFiles(file.name);
    }
  };

  // Delete File From DB
  const handleDeleteFile = async () => {
    try {
      await deleteFile(fileToDelete);
      NotificationMessage("Success", "delete");
      const filteredFiles = selectedFiles.filter(
        (file) => file.id !== fileToDelete
      );
      setselectedFiles(filteredFiles);
      setFileToDelete(null);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsDeleteModal(false);
    }
  };

  // Function to convert Base64 string to Blob
  function base64ToBlob(base64, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // download single file based on click
  const download = async (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);

    try {
      const res = await generalRequest({
        url: `file-info?file_name=${name}`,
      });
      const fileType = res?.item?.file_type;
      const base64String = `data:${fileType};base64,${res?.item?.base64}`;
      const filename = res?.item?.file_name;

      // Convert the Base64 string to a Blob
      const blob = base64ToBlob(base64String, fileType);

      // Create a URL for the Blob and make it downloadable
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setFetching(false);
    }
  };

  // download all files
  const downloadAllFiles = async (files) => {
    setIsDownloadingAll(true);

    const zip = new JSZip();
    const folder = zip.folder();

    const fileNamesArray = files
      ?.filter((item) => item?.id)
      ?.map((item) => item?.name);
    if (fileNamesArray?.length === 0) {
      setIsDownloadingAll(false);
      return;
    }
    try {
      const res = await generalRequest({
        url: "files-info",
        file_names: fileNamesArray,
      });

      const resFiles = res?.item;

      //loop through files
      resFiles.forEach((file) => {
        const fileType = file.file_type;
        const base64String = `data:${fileType};base64,${file.base64}`;
        const fileName = file.file_name;

        // Convert the Base64 string to a Blob
        const blob = base64ToBlob(base64String, fileType);

        folder.file(fileName, blob, { binary: true });
      });
      //Zip folder and download folderZip
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `${zipFileName}_${new Date().toISOString()}.zip`);
      });
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
      console.log("error", errorMessage);
    } finally {
      setIsDownloadingAll(false);
    }
  };

  return (
    <Fragment>
      {selectedFiles.filter((item) => item?.type?.id === typeId)?.length > 0 ? (
        <div
          className={`border rounded-3 broder-muted py-2 ${
            isViewPage || isViewProcessingPage ? "my-0" : "my-2"
          }`}
        >
          <PerfectScrollbar
            style={{
              maxHeight: "200px",
              paddingInline: "10px",
            }}
          >
            <div className="w-100" style={{ overflowX: "hidden" }}>
              <div className="dropzone-previews" id="file-previews">
                {selectedFiles
                  .filter((item) => item?.type?.id === typeId)
                  .map((f, i) => {
                    return (
                      <Card
                        className={`mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete ${
                          f?.isAged && "bg-danger bg-opacity-10"
                        }`}
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center justify-content-between g-1">
                            <div className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f?.name}
                                src={f?.preview}
                              />
                            </div>

                            <Col md={8}>
                              {f?.id ? (
                                <div className="pt-1 ps-2">
                                  {/* preview Link */}
                                  <a
                                    href={f?.path}
                                    download={true}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-black font-weight-bold"
                                  >
                                    {f?.name?.split("_")?.pop()}
                                  </a>

                                  {/* file Description */}
                                  {/* and Edit Description popup */}
                                  <p className="text-muted font-size-12 font-weight-bold mt-1 d-flex gap-1">
                                    <span>{f?.description}</span>
                                    <EditFileDescriptionModal
                                      fileId={f?.id}
                                      refetchList={(updatedFile) => {
                                        setselectedFiles((prev) =>
                                          prev.map((item) =>
                                            item.id === updatedFile.id
                                              ? { ...item, ...updatedFile }
                                              : item
                                          )
                                        );
                                      }}
                                    />
                                  </p>
                                </div>
                              ) : (
                                <p className="text-muted font-weight-bold mb-0">
                                  {f?.name}
                                </p>
                              )}
                              <p className="mb-0">
                                <strong>{f?.formattedSize}</strong>
                              </p>
                            </Col>
                            <Col className="text-end d-flex justify-content-end">
                              {f?.id && (
                                <Fragment>
                                  {/* preview */}
                                  <a
                                    href={f?.path}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-soft-info waves-effect waves-light shadow-sm rounded-circle me-1"
                                    id={`view-${i}-${typeId}`}
                                  >
                                    <i className="mdi mdi-eye font-size-14"></i>
                                  </a>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`view-${i}-${typeId}`}
                                  >
                                    {buttonsTitles.preview}
                                  </UncontrolledTooltip>
                                  {/* Download */}
                                  <button
                                    disabled={fetching}
                                    onClick={() => download(f?.path, f?.name)}
                                    type="button"
                                    className="btn btn-soft-success waves-effect waves-light shadow-sm rounded-circle me-1"
                                    id={`download-${i}-${typeId}`}
                                  >
                                    <i className="mdi mdi-download font-size-14"></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`download-${i}-${typeId}`}
                                  >
                                    {buttonsTitles.download}
                                  </UncontrolledTooltip>
                                </Fragment>
                              )}

                              {/* Delete */}
                              {isViewPage ||
                              isViewProcessingPage ||
                              (f.id &&
                                removeDeleteAction &&
                                !isDeleteFilePermission) ? null : (
                                <Fragment>
                                  <button
                                    className="btn btn-soft-danger waves-effect waves-light shadow-sm rounded-circle"
                                    id={`deleteFile-${i}-${typeId}`}
                                    type="button"
                                    onClick={() => handleDeleteClick(f)}
                                  >
                                    <i className="mdi mdi-close font-size-14"></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`deleteFile-${i}-${typeId}`}
                                  >
                                    {f.id
                                      ? buttonsTitles.delete
                                      : buttonsTitles.remove}
                                  </UncontrolledTooltip>
                                </Fragment>
                              )}
                            </Col>
                          </Row>
                        </div>

                        {/* created at and created by */}
                        {f?.id ? (
                          <ul className="d-flex align-items-center px-2 gap-1">
                            <DataIcon
                              icon="mdi mdi-cloud-upload"
                              title="Uploaded By"
                              data={f?.created_by?.name}
                            />
                            <DataIcon
                              icon="mdi mdi-calendar"
                              // icon="bx bx-calendar"
                              title="Uploaded At"
                              data={`
                       ${moment(f?.created_at).format(previewDateFormat)}
                       ${moment(f?.created_at).format("hh:mm a")}
                     `}
                            />
                          </ul>
                        ) : null}
                      </Card>
                    );
                  })}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      ) : null}

      {isDownloadAll &&
        selectedFiles?.filter((item) => item?.type?.id === typeId)?.length >
          0 && (
          <div className="text-end mt-3">
            <CustomButton
              type="button"
              disabled={
                isDownloadingAll ||
                selectedFiles.filter((item) => item?.type?.id === typeId)
                  ?.length === 0
                  ? true
                  : false
              }
              isSaving={isDownloadingAll}
              onClick={() =>
                downloadAllFiles(
                  selectedFiles.filter((item) => item?.type?.id === typeId)
                )
              }
              className="btn btn-green w-lg"
              title={"Download All Attachments"}
            />
          </div>
        )}

      <DeleteModal
        // isDeleting={saving}
        show={isDeleteModal}
        onDeleteClick={() => {
          handleDeleteFile();
        }}
        onCloseClick={() => setIsDeleteModal(false)}
      />
    </Fragment>
  );
};

export default withTranslation()(DropZonePreviews);
