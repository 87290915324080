import React, { useEffect, useRef, useState } from "react"
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api"
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { calculateDistance } from "utils"

const Map = ({ CurrentLocation, onSelectPlace }) => {
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [searchLngLat, setSearchLngLat] = useState(null)
  const [currentLocation, setCurrentLocation] = useState(null)
  const autocompleteRef = useRef(null)

  useEffect(() => {
    setCurrentLocation(CurrentLocation)
  }, [JSON.stringify(CurrentLocation)])

  // useEffect(() => {
  //   console.log(
  //     "distance",
  //     calculateDistance(
  //       {
  //         lat: "37.7749",
  //         lng: "-122.4194",
  //       },

  //       {
  //         lat: "34.0522",
  //         lng: "-118.2437",
  //       }
  //     )
  //   )
  // }, [])

  // laod script for google map
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCxCo_gNttWwrvvGJbiFYQTh4BDXcnDyFs",
    libraries: ["places"],
  })
  if (!isLoaded) return <div>Loading....</div>
  // static lat and lng
  const center = { lat: 30.033333, lng: 31.233334 }
  // handle place change on search
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace()
    setSelectedPlace(place)
    if (onSelectPlace) {
      onSelectPlace(place)
    }
    setSearchLngLat({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
    setCurrentLocation(null)
  }

  // get current location
  const handleGetLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          setSelectedPlace(null)
          setSearchLngLat(null)
          setCurrentLocation({ lat: latitude, lng: longitude })
        },
        error => {
          console.log(error)
        }
      )
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }
  // on map load
  const onMapLoad = map => {
    const controlDiv = document.createElement("div")
    const controlUI = document.createElement("div")
    controlUI.innerHTML = "Get Location"
    controlUI.style.borderRadius = "3px"
    controlUI.style.cursor = "pointer"
    controlUI.style.marginBottom = "22px"
    controlUI.style.textAlign = "center"
    controlUI.style.width = "100%"
    controlUI.style.padding = "8px 0"
    controlUI.addEventListener("click", handleGetLocationClick)
    controlDiv.appendChild(controlUI)
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(controlDiv)
  }
  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    const lat = latLng.lat()
    const lng = latLng.lng()
    setCurrentLocation({
      lat: lat,
      lng: lng,
    })
  }
  return (
    <div
      // /  className="map_component"
      className="d-flex flex-column align-items-center justify-content-center"
      id="map"
    >
      {/* search component  */}
      {/* map component  */}
      <GoogleMap
        zoom={currentLocation || selectedPlace ? 18 : 8}
        center={currentLocation || searchLngLat || center}
        mapContainerClassName="map map_container"
        mapContainerStyle={{
          width: "80%",
          height: "450px",
          margin: "auto",
        }}
        onLoad={onMapLoad}
      >
        <Autocomplete
          onLoad={autocomplete => {
            autocompleteRef.current = autocomplete
          }}
          onPlaceChanged={handlePlaceChanged}
          options={{
            fields: ["address_components", "geometry", "name"],
          }}
        >
          <input
            type="text"
            placeholder="Search for a location"
            style={{
              position: "absolute",
              margin: "auto",
              left: "1%",
              top: "2%",
              right: "1%",
              padding: 18,
              border: "1px solid #556ee6",
              borderRadius: "10px",
            }}
          />
        </Autocomplete>
        {selectedPlace && (
          <Marker
            position={searchLngLat}
            onDragEnd={value => onMarkerDragEnd(value)}
            draggable={true}
          />
        )}
        {currentLocation && (
          <>
            <Marker
              position={currentLocation}
              draggable={true}
              onDragEnd={value => onMarkerDragEnd(value)}
            />
          </>
        )}
      </GoogleMap>
    </div>
  )
}
export default withRouter(withTranslation()(Map))
