import axios from "axios"

export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const location = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          }
          resolve(location)
        },
        error => {
          console.error("Error getting geolocation:", error)
          const defaultLocation = {
            lat: 30.033333,
            lng: 31.233334,
          }
          resolve(defaultLocation)
        },
        {
          maximumAge: 500000,
          enableHighAccuracy: true,
          timeout: 6000,
        }
      )
    } else {
      const defaultLocation = {
        lat: 30.033333,
        lng: 31.233334,
      }
      resolve(defaultLocation)
    }
  })
}

export const calculateDistance = (mk1, mk2) => {
  // var R = 3958.8 // Radius of the Earth in miles
  var R = 6371.071
  var rlat1 = mk1.lat * (Math.PI / 180) // Convert degrees to radians
  var rlat2 = mk2.lat * (Math.PI / 180) // Convert degrees to radians
  var difflat = rlat2 - rlat1 // Radian difference (latitudes)
  var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180) // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    )
  return +d?.toFixed(2)
}
