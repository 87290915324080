import { buttonsTitles } from "common/data"
import { SingleRowAction, StickyHeaderTable } from "components/TP/Common/Tables"
import { usePageType } from "hooks"
import { isNumber } from "lodash"
import moment from "moment"
import React, { Fragment, useState } from "react"

const NotesTable = ({
  notes,
  handleDelete,
  handleUpdate,
  removeActionsColumn,
}) => {
  const { isProcessingPage, isViewProcessingPage, isViewPage } = usePageType()

  const tableColumns = ["Created by", "Date & Time", "Description", "Actions"]

  const [numberOfNotes, setNumberOfNotes] = useState(3)

  return (
    <Fragment>
      <StickyHeaderTable
        columns={
          removeActionsColumn
            ? tableColumns.filter(item => item !== "Actions")
            : tableColumns
        }
        isLoading={false}
        noData={notes?.length === 0}
      >
        {notes?.length > 0 &&
          notes?.slice(0, numberOfNotes)?.map(note => (
            <tr key={note?.id}>
              <td>{note?.created_by?.name}</td>
              <td>
                {note?.created_at
                  ? moment(note?.created_at).format("DD-MM-YYYY hh:mm")
                  : ""}
              </td>
              <td>{note?.description}</td>
              {!isViewPage && !isViewProcessingPage && !removeActionsColumn && (
                <td>
                  {/* if the note is saved in DB the user couldn’t edit or delete it */}
                  {isNumber(note?.id) ? null : (
                    <div className="d-flex gap-1">
                      {/* update Actions */}
                      <SingleRowAction
                        onClick={() => handleUpdate(note)}
                        color="text-gray-700"
                        iconClass="mdi mdi-pencil"
                        actionName={buttonsTitles.update}
                        record={note}
                      />
                      {/* Delete Actions */}
                      <SingleRowAction
                        onClick={() => handleDelete(note.id)}
                        color="text-danger"
                        iconClass="mdi mdi mdi-close"
                        actionName={buttonsTitles.delete}
                        record={note}
                      />
                    </div>
                  )}
                </td>
              )}
            </tr>
          ))}
        {/* Show More Button */}
        {notes.length > 3 ? (
          <tr className="noHover noBorderBottom">
            <td colSpan={tableColumns.length}>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-green d-flex align-items-center gap-2"
                  onClick={() =>
                    setNumberOfNotes(prev => (prev === 3 ? notes?.length : 3))
                  }
                >
                  <span className="font-size-10">
                    Show {numberOfNotes === 3 ? "More" : "Less"}
                  </span>
                  <i
                    className={`bx ${
                      numberOfNotes === 3 ? "bx-chevron-down" : "bx-chevron-up"
                    } font-size-14`}
                  ></i>
                </button>
              </div>
            </td>
          </tr>
        ) : null}
      </StickyHeaderTable>

      {/* {notes.length > 3 ? (
        <div className="d-flex align-items-center justify-content-center">
          <button
            type="button"
            className="btn btn-green d-flex align-items-center gap-2"
            onClick={() =>
              setNumberOfNotes(prev => (prev === 3 ? notes?.length : 3))
            }
          >
            <span className="font-size-10">
              Show {numberOfNotes === 3 ? "More" : "Less"}
            </span>
            <i
              className={`bx ${
                numberOfNotes === 3 ? "bx-chevron-down" : "bx-chevron-up"
              } font-size-14`}
            ></i>
          </button>
        </div>
      ) : null} */}
    </Fragment>
  )
}

export default NotesTable
