import { buttonsTitles, modalHeaders } from "common/data"
import { getAbnormalMedicalHistory } from "helpers/Production/Policies/beneficaries_helper"
import React, { Fragment, useEffect, useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Modal,
} from "reactstrap"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { ModalSpinner } from "../Common/Forms"
import { StickyHeaderTable } from "../Common/Tables"

const AbnormalMedicalHistory = ({ beneficiaryId }) => {
  const [isModal, setIsModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [abnormals, setAbnormals] = useState([])

  const [open, setOpen] = useState([])

  // toggle the accordions
  const toggle = id => {
    if (open.find(item => item === id)) {
      setOpen(open.filter(item => item !== id))
    } else {
      setOpen([...open, id])
    }
  }

  const getAbnormalHistory = async beneficiaryId => {
    try {
      setIsLoading(true)
      const res = await getAbnormalMedicalHistory(beneficiaryId)
      const objValues = res?.item ? Object.values(res?.item) : []
      setAbnormals(objValues)
      setOpen(objValues.map(item => `${item?.medicalCode?.id}`))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (isModal && beneficiaryId) {
      getAbnormalHistory(beneficiaryId)
    }
  }, [isModal, beneficiaryId])

  return (
    <Fragment>
      {/* Trigger */}
      <div className="d-flex align-items-center justify-content-start">
        <button
          type="button"
          className="btn btn-gray-600"
          onClick={() => setIsModal(true)}
        >
          {buttonsTitles.abnormalHistory}
        </button>
      </div>

      {/* Modal */}
      <Modal
        size="xl"
        isOpen={isModal}
        toggle={() => setIsModal(false)}
        backdrop="static"
        className="modal-fullscreen"
      >
        <div className="modal-header">
          {/* Modal Header Title */}
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modalHeaders.abnormalMedicalHistory}
          </h5>

          {/* close button on the top right section */}
          <button
            aria-label="Close Modal"
            type="button"
            onClick={() => setIsModal(false)}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          {isLoading ? (
            <ModalSpinner />
          ) : (
            <Fragment>
              <Accordion open={open} toggle={toggle}>
                {abnormals.map((abnormal, index) => (
                  <AccordionItem
                    key={`${abnormal.medicalCode?.id}-${index}`}
                    style={{ color: "inherit" }}
                  >
                    <AccordionHeader targetId={`${abnormal.medicalCode?.id}`}>
                      {abnormal.medicalCode?.name || "Others"}
                    </AccordionHeader>
                    <AccordionBody accordionId={`${abnormal.medicalCode?.id}`}>
                      <StickyHeaderTable
                        columns={[
                          "Name",
                          "From - To",
                          "Value",
                          "Unit",
                          "Claim Number",
                          "Admission Date",
                          "General Assessment",
                          "Healthcare Provider Name",
                          "FOB",
                        ]}
                        isLoading={false}
                        noData={
                          abnormal?.abnormalTestResults?.length === 0
                            ? true
                            : false
                        }
                      >
                        {abnormal?.abnormalTestResults?.length > 0 &&
                          abnormal?.abnormalTestResults
                            ?.sort((a, b) => b?.id - a?.id)
                            .map(testResult => (
                              <tr key={testResult.id}>
                                {/* result name */}
                                <td>{testResult?.name}</td>
                                {/* result From - TO */}
                                <td>{`${testResult?.from} - ${testResult?.to}`}</td>
                                {/* result value */}
                                <td>{testResult?.value}</td>
                                {/* result unit */}
                                <td>{testResult?.unit}</td>
                                {/* result claim ref */}
                                <td>{testResult?.claim?.reference}</td>
                                {/* result claim admission date */}
                                <td>{testResult?.claim?.admission_date}</td>
                                {/* result claim general assessment */}
                                <td>
                                  {testResult?.claim?.general_assessment?.name}
                                </td>
                                {/* result claim provider name */}
                                <td>
                                  {
                                    testResult?.claim
                                      ?.healthcare_provider_first_name
                                  }
                                </td>
                                {/* result claim FOB */}
                                <td>{testResult?.claim?.benefit?.name}</td>
                              </tr>
                            ))}
                      </StickyHeaderTable>
                    </AccordionBody>
                  </AccordionItem>
                ))}
              </Accordion>
            </Fragment>
          )}
        </div>

        <div className="modal-footer">
          <button
            aria-label="Close Modal"
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsModal(false)}
          >
            {buttonsTitles.close}
          </button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default AbnormalMedicalHistory
