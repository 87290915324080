import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "antd"

const PopUpFormTitle = ({ formType, subModule }) => {
  const { moduleName, loading } = useSelector(state => ({
    moduleName: state.Module.name,
    loading: state.Module.loading,
  }))

  let newFormType

  if (formType && formType.toLowerCase() === "add") {
    newFormType = " - Add Form"
  } else if (
    formType &&
    (formType.toLowerCase() === "edit" || formType.toLowerCase() === "update")
  ) {
    newFormType = " - Edit Form"
  } else if (
    formType &&
    (formType.toLowerCase() === "clone" || formType.toLowerCase() === "copy")
  ) {
    newFormType = " - Clone Form"
  } else if (
    formType &&
    (formType.toLowerCase() === "blacklist" ||
      formType.toLowerCase() === "black list")
  ) {
    newFormType = " - BlackList Form"
  } else if (
    formType &&
    (formType.toLowerCase() === "whitelist" ||
      formType.toLowerCase() === "white list")
  ) {
    newFormType = " - WhiteList Form"
  } else if (formType && formType.toLowerCase() === "view") {
    newFormType = " - View Form"
  } else if (formType && formType.toLowerCase() === "detail") {
    newFormType = " - Detail Form"
  }else {
    newFormType = ""
  }
  return loading ? (
    <Skeleton
      active
      round={true}
      title={true}
      paragraph={{
        rows: 0,
      }}
    />
  ) : (
    <Fragment>
      {subModule ? (
        <Fragment>{`${subModule?.title} ${newFormType}`}</Fragment>
      ) : (
        <Fragment>{`${moduleName} ${newFormType}`}</Fragment>
      )}
    </Fragment>
  )
}

export default PopUpFormTitle
