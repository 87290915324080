import * as XLSX from "xlsx";
import { flattenObject } from "./smallUtils";
import moment from "moment";

export function exportToExcel({ headers, data, filename }) {
  // flatten every object of the array
  const flattenObjectData = data
    .map((item) => flattenObject(item))
    .map((row) => {
      let modifiedRow = Object.fromEntries(
        // convert prices to array, map each key/value pair into another pair
        // and then fromEntries gives back the object
        Object.entries(row).map(([key, value]) => {
          return moment(value, "YYYY-MM-DD", true).isValid()
            ? [key, moment(value).format("DD/MM/YYYY")]
            : [key, value];
        })
      );

      return {
        ...modifiedRow,
        is_active: modifiedRow.is_active === 1 ? "Yes" : "No",
      };
    });
  // Map data to include only the specified headers
  const filteredData = flattenObjectData.map((row) =>
    headers.reduce((acc, { label, key }) => {
      acc[label] = row[key];
      return acc;
    }, {})
  );

  // Generate worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Trigger file download
  XLSX.writeFile(workbook, `${filename}_${new Date().toISOString()}.xlsx`);
}
