import { get, post, put, request } from "../api_helper"
import * as url from "../url_helper"
import axios from "axios"

// const postEmailLogin = async data => {
//   const { data: responseData } = await axios.post(url.POST_EMAIL_LOGIN, data)
//   return responseData
// }

const postEmailLogin = (data) => post(url.POST_EMAIL_LOGIN, data)

// Logout Method
// const postLogout = data => post(url.POST_LOGOUT, data)
const postLogout = () => post(url.POST_LOGOUT, {})

// refresh token
const postRefreshToken = () => post(url.POST_REFRESH_TOKEN, {})

// post Forgot Password
const postForgotPassword = (data) => post(url.POST_FORGOT_PASSWORD, data)
const postResetPassword = (data) => post(url.POST_RESET_PASSWORD, data)

const changePassword = (data) =>
  request({
    url: url.CHANGE_PASSWORD,
    method: "POST",
    data,
  })
// const updateProfile = data => put(url.PROFILE, data)
const updateProfile = (data) =>
  request({
    url: url.PROFILE,
    method: "PUT",
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    data,
  })
const getProfile = () => get(url.PROFILE)

export {
  postEmailLogin,
  postLogout,
  postRefreshToken,
  postForgotPassword,
  updateProfile,
  getProfile,
  changePassword,
  postResetPassword,
}
