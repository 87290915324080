import { buttonsTitles } from "common/data"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "reactstrap"
import { openModal, closeModal } from "store/actions"

const ConfirmationModal = () => {
  const {
    isModal,
    message,
    title,
    confirmCallback,
    closeCallback,
    includeTwoButtons,
    confirmButtonTitle,
    closeButtonTitle,
  } = useSelector(state => ({
    isModal: state.ToggleModal.isModal,
    message: state.ToggleModal.message,
    title: state.ToggleModal.title,
    closeCallback: state.ToggleModal.closeCallback,
    confirmCallback: state.ToggleModal.confirmCallback,
    includeTwoButtons: state.ToggleModal.includeTwoButtons,
    confirmButtonTitle: state.ToggleModal.confirmButtonTitle,
    closeButtonTitle: state.ToggleModal.closeButtonTitle,
  }))

  const dispatch = useDispatch()

  return (
    <Modal
      size="md"
      isOpen={isModal}
      toggle={() => dispatch(closeModal())}
      centered={true}
      backdrop="static"
      // zIndex="10000000"
    >
      {/* Modal Header */}

      <div
        className={`modal-header ${
          title.toLowerCase() === "warning"
            ? "bg-primary bg-gradient text-white"
            : ""
        }`}
      >
        {/* Modal Header Title */}

        <h5
          className="modal-title mt-0 d-flex align-items-center"
          id="myModalLabel"
        >
          {title.toLowerCase() === "warning" ? (
            <i className="mdi mdi-alert-outline font-size-20 me-1"></i>
          ) : null}
          {title?.toUpperCase()}
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={() => {
            dispatch(closeModal())
            closeCallback
              ? closeCallback()
              : () => {
                  return
                }
          }}
          className="btn-close position-absolute end-0 top-0 m-3"
          // style={{ color: "white", backgroundColor: "yellow" }}
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <p className="text-muted font-size-16 mb-4 text-left">{message}</p>
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-center mb-0">
          {includeTwoButtons ? (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                dispatch(closeModal())
                closeCallback
                  ? closeCallback()
                  : () => {
                      return
                    }
              }}
              // disabled={isSaving}
            >
              {closeButtonTitle ? closeButtonTitle : buttonsTitles.no}
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-success"
            // onClick={() => dispatch(closeModal())}
            onClick={
              confirmCallback
                ? () => {
                    confirmCallback()
                    dispatch(closeModal())
                  }
                : () => dispatch(closeModal())
            }
          >
            {confirmButtonTitle
              ? confirmButtonTitle
              : buttonsTitles.ok?.toUpperCase()}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal

// import React from "react"
// import { useDispatch, useSelector } from "react-redux"
// import { Modal } from "reactstrap"
// import { openModal, closeModal } from "store/actions"

// const ConfirmationModal = () => {
//   const { isModal, message, title, confirmCallback, closeCallback } =
//     useSelector(state => ({
//       isModal: state.ToggleModal.isModal,
//       message: state.ToggleModal.message,
//       title: state.ToggleModal.title,
//       closeCallback: state.ToggleModal.closeCallback,
//       confirmCallback: state.ToggleModal.confirmCallback,
//     }))

//   const dispatch = useDispatch()

//   return (
//     <Modal
//       size="md"
//       isOpen={isModal}
//       toggle={() => dispatch(closeModal())}
//       centered={true}
//       // zIndex="10000000"
//     >
//       {/* Modal Header */}

//       <div
//         className={`modal-header ${
//           title.toLowerCase() === "warning"
//             ? "bg-danger bg-gradient text-white"
//             : ""
//         }`}
//       >
//         {/* Modal Header Title */}

//         <h5
//           className="modal-title mt-0 d-flex align-items-center"
//           id="myModalLabel"
//         >
//           {title.toLowerCase() === "warning" ? (
//             <i className="mdi mdi-alert-outline font-size-20 me-1"></i>
//           ) : null}
//           {title}
//         </h5>

//         {/* close button on the top right section */}
//         <button
//           type="button"
//           onClick={() => dispatch(closeModal())}
//           className="btn-close position-absolute end-0 top-0 m-3"
//         ></button>
//       </div>

//       {/* Modal Body */}
//       <div className="modal-body">
//         <p className="text-muted font-size-16 mb-4 text-left">{message}</p>
//       </div>

//       {/* Modal Footer */}
//       <div className="modal-footer">
//         <div className="hstack gap-2 justify-content-center mb-0">
//           <button
//             type="button"
//             className="btn btn-light"
//             // onClick={() => dispatch(closeModal())}
//             onClick={
//               confirmCallback
//                 ? () => {
//                     confirmCallback()
//                     dispatch(closeModal())
//                   }
//                 : () => dispatch(closeModal())
//             }
//           >
//             Undestood
//           </button>
//           {/* <button
//             type="button"
//             className="btn btn-primary"
//             onClick={onConfirmClick}
//             disabled={isSaving}
//           >
//             {t("Confirm")}
//           </button> */}
//         </div>
//       </div>
//     </Modal>
//   )
// }

// export default ConfirmationModal
