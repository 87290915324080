import React, { Fragment, useEffect, useRef, useState } from "react"
import { Input, InputGroup, Label, UncontrolledTooltip } from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import FormInputError from "./FormInputError"
import { useLocation } from "react-router-dom"
import { usePageType } from "hooks"
import { claimPaths } from "common/data/routePaths"
const DatePickerInput = ({
  id,
  name,
  value,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  onChange,
  customOnChange,
  onBlur,
  invalid,
  error,
  wrapperClassNames,
  disabledDates,
  disableFutureDates,
  disablePastDates,
  disableFutureDatesAndToday,
  disablePastDatesAndToday,
  removeDisable,
  removeIcon,
  noLabel,
  minDate,
  maxDate,
  readOnly,
  removeOnClose,
  notCloseOnSelect,
  clearIcon,
  inFilter,
  showTime,
  disableInvalidPreload,
  minTime,
  maxTime,
  pageType: propPageType,
}) => {
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  }${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  }${propInputClass ? propInputClass : ""}`

  const handleChange = (date, dateStr, instance) => {
    onChange(`${name}`, dateStr ? dateStr : "")

    // inputRef.current.flatpickr.altInput.blur()
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur(`${name}`, true)
    }
  }

  const inputRef = useRef(null)

  useEffect(() => {
    if (invalid) {
      inputRef.current.flatpickr.altInput.classList.add("border-danger")
    } else {
      inputRef.current.flatpickr.altInput.classList.remove("border-danger")
    }

    if (inputClass) {
      inputRef.current.flatpickr.altInput.classList.add(inputClass)
    }
    if (readOnly) {
      inputRef.current.flatpickr.altInput.classList.add("bg-gray-600")
      inputRef.current.flatpickr.altInput.classList.add("bg-opacity-10")
    }
    if (!readOnly) {
      inputRef.current.flatpickr.altInput.classList.remove("bg-gray-600")
      inputRef.current.flatpickr.altInput.classList.remove("bg-opacity-10")
    }
  }, [invalid, inputClass, readOnly])

  const location = useLocation()
  const { pathname } = location

  const { isViewPage, isViewProcessingPage } = usePageType()
  // to know if the page link has a view word or not
  // const pageType = pathname.split("/")[2]
  const pageType = propPageType ? propPageType : pathname.split("/")[2]

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel ? (
        <Fragment>
          <Label
            htmlFor={id}
            className={`text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${labelClass ? labelClass : ""}`}
            // data-toggle="tooltip"
            // title={placeholder ? placeholder : ""}
            id={`tooltip-${id}`}
          >
            {label}
          </Label>
          {placeholder && (
            <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
              {placeholder}
            </UncontrolledTooltip>
          )}
        </Fragment>
      ) : null}
      <div className="position-relative overflow-hidden">
        <Flatpickr
          ref={inputRef}
          id={id}
          name={name}
          value={value}
          className={`form-control ${inFilter ? "filter-input" : ""} ${
            pageType === "view" || pageType === claimPaths.viewProcessing
              ? "border-0"
              : ""
          }`}
          placeholder={placeholder ? placeholder : "YYYY-MM-DD"}
          options={{
            minDate: minDate ? minDate : null,
            maxDate: maxDate ? maxDate : null,
            altInput: true,
            allowInvalidPreload: disableInvalidPreload ? false : true,
            // altFormat: "Y-m-d",
            altFormat: showTime ? "d-m-Y H:i:ss" : "d-m-Y",
            dateFormat: showTime ? "Y-m-d H:i:ss" : "Y-m-d",
            enableTime: showTime ? true : false,
            minTime: minTime ? minTime : null,
            maxTime: maxTime ? maxTime : null,
            // allowInput: true,
            // disable typing in view page
            allowInput:
              pageType === "view" ||
              pageType === claimPaths.viewProcessing ||
              readOnly
                ? false
                : true,
            // disable on click in view page
            clickOpens:
              !isViewPage && !isViewProcessingPage && !readOnly ? true : false,
            // closeOnSelect: notCloseOnSelect ? false : true,
            disable: removeDisable
              ? []
              : disabledDates
              ? disabledDates
              : [
                  function (date) {
                    // return true to disable
                    if (disableFutureDates) {
                      return date > moment(new Date())
                    } else if (disableFutureDatesAndToday) {
                      return date > moment(new Date()).subtract(1, "day")
                    } else if (disablePastDates) {
                      return date < moment(new Date()).subtract(1, "day")
                    } else if (disablePastDatesAndToday) {
                      return date < moment(new Date())
                    } else {
                      return date > moment(new Date())
                    }
                  },
                ],
          }}
          // onClose={handleBlur}
          onBlur={handleBlur}
          onChange={customOnChange ? customOnChange : handleChange}
          onClose={
            !removeOnClose
              ? customOnChange
                ? customOnChange
                : handleChange
              : () => {
                  return
                }
          }
        />
        {clearIcon && inputRef.current?.flatpickr?.input?.value ? (
          <div
            className="input-group-append"
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              // zIndex: "100",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-secondary px-1"
              style={{
                borderRight: "0",
                borderTop: "0",
                borderBottom: "0",
                borderRadius: "0",
              }}
              onClick={() => {
                if (!inputRef?.current?.flatpickr) return
                inputRef.current.flatpickr.clear()
              }}
            >
              <i className="mdi mdi-close font-size-12" aria-hidden="true" />
            </button>
          </div>
        ) : null}
        {/* show right icon in all pages except the view page */}
        {!isViewPage && !isViewProcessingPage && !readOnly && !removeIcon ? (
          <div
            className="input-group-append"
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              height: "100%",
              // zIndex: "100",
            }}
          >
            <button
              type="button"
              id="calender-icon-button"
              className={`btn opacity-50 ${
                invalid ? "btn-outline-danger" : "btn-outline-gray-500"
              }`}
              style={{
                borderRight: "0",
                borderTop: "0",
                borderBottom: "0",
                borderRadius: "0",
                height: "100%",
              }}
              onClick={() => {
                if (!inputRef?.current?.flatpickr) return
                inputRef.current.flatpickr.toggle()
              }}
            >
              <i className="fa fa-calendar" aria-hidden="true" />
            </button>
          </div>
        ) : null}
      </div>
      {/* Error Message */}
      {invalid && !isViewPage && !isViewProcessingPage && !readOnly ? (
        <FormInputError error={error} selectError={true} />
      ) : null}
    </div>
  )
}

export default DatePickerInput
