import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle";

//i18n
import { withTranslation } from "react-i18next";
import MedReportForm from "./MedReportForm";

export const MedicalOpinionReport = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={"Medcial Opinion Report"} />
          <Col xs={12}>
            <MedReportForm />
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(MedicalOpinionReport);
