import React, { Suspense } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { useSecureURL } from "hooks"
import { Preloader } from "components/Common"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  authUser,
  ...rest
}) => {
  // custom hook to secure the URL of Add,Edit,View and so on pages
  // if user has no permission on it and type the URL by hand it redirects him to home page
  useSecureURL()
  return (
    <Route
      {...rest}
      render={props => {
        if (
          isAuthProtected &&
          !localStorage.getItem("authUser") &&
          !localStorage.getItem("accessToken") &&
          !authUser
        ) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
        return (
          <Layout>
            <Suspense fallback={<Preloader />}>
              <Component {...props} />
            </Suspense>
          </Layout>
        )
      }}
    />
  )
}
Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}
const mapStateToProps = state => {
  return {
    authUser: state.Login.authUser,
  }
}
export default connect(mapStateToProps, null)(Authmiddleware)
