import React from "react";

//i18n
import { withTranslation } from "react-i18next";

// UI Components
import { FormModal, FormControll } from "components/TP/Common/Forms";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// helper functions

//utils functions
import { encryptText, isInputInvalid } from "utils";
import { PAYER } from "helpers/url_helper";
import { SearchSelect } from "components/TP/Common/Forms";
import { claimPaths } from "common/data/routePaths";
import { buttonsTitles } from "common/data";

const ClaimHistoryActionsModal = ({ isModal, setIsModal, t }) => {
  const handleRedirectToClaimHistory = (values, setSubmitting, resetForm) => {
    window.open(
      ` ${claimPaths.claimHistory}/${encryptText(
        JSON.stringify({
          beneficiaries_pin_number: values.beneficiaries_pin_number,
          payer_id: values.payer_id,
        })
      )}`
    );
    setIsModal(false);
    setSubmitting(false);
  };

  // validation with formik and Yup
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      beneficiaries_pin_number: "",
      payer_id: "",
    },
    validationSchema: Yup.object({
      beneficiaries_pin_number: Yup.string().required(
        "Please Enter Pin Number"
      ),
      payer_id: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleRedirectToClaimHistory(values, setSubmitting, resetForm);
    },
  });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    isSubmitting,
    resetForm,
    handleSubmit,
    getFieldProps,
  } = validation;

  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false);
        resetForm();
      }}
      onSaveClick={handleSubmit}
      modalTitle={buttonsTitles.claimHistory}
      isSaving={isSubmitting}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <FormControll
          id="819"
          name="beneficiaries_pin_number"
          inputField={{ label_name: "Pin Number", is_required: 1 }}
          type="text"
          {...getFieldProps("beneficiaries_pin_number")}
          invalid={isInputInvalid(validation, "beneficiaries_pin_number")}
          error={errors.beneficiaries_pin_number}
          wrapperClassNames="mb-3"
        />

        <SearchSelect
          id="819"
          name="payer_id"
          label="Insurer Name"
          value={values.payer_id}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          url={PAYER}
          urlAddOns={{
            is_active: 1,
          }}
          mappingValue="id"
          mappingLabel="first_name"
          invalid={isInputInvalid(validation, "payer_id")}
          error={errors.payer_id}
          wrapperClassNames="mb-3"
        />
      </form>
    </FormModal>
  );
};

export default withTranslation()(ClaimHistoryActionsModal);
