import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { TableContainer } from 'components/Common';
import { useSelector } from 'react-redux';
import { buttonsTitles } from 'common/data';
import useSWR from 'swr';
import { fetcher } from 'utils/API/SWRFetcher';
import {
  REMINDERS,
  USER_MODULES,
} from './../../../../../../helpers/url_helper';
import { staffTasksModuleID } from '../../../../../../utils/modulesIds';
import generateColumns from 'utils/generateColumns';
import { getReminders } from '../../../../../../helpers/Claims/ClaimForm';

function ViewReminders({ setShow, show, sourceId }) {
  const toggle = () => setShow(!show);
  const [tasks, setTasks] = useState([]);
  const [loadingTaks, setLoadingTaks] = useState(false);
  const [showOptions, setShowOptions] = useState([]);
  const [resetInput, setResetInput] = useState(false);
  const [pagination, setPagination] = useState({});
  const [columns, setColumns] = useState([]);
  const [showAllTasks, setShowAllTasks] = useState(false);

  const userId = JSON.parse(localStorage.getItem('authUser'))?.id;
  const actionsUrl = `${USER_MODULES}/${staffTasksModuleID}`;

  const getTasks = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_keys,
    search_values,
    search_value,
  }) => {
    setLoadingTaks(true);
    try {
      const params = {
        page,
        per_page,
        sort_by,
        sort_type,
        search_keys,
        search_values,
        search_value,
      };
      // use correspondent api function for each source Type
      const response = await getReminders(sourceId, userId, params);

      setTasks(response?.items || []);
      setPagination({
        size: response.size,
        page: response.page,
        per_page: response.per_page,
        total_pages: response.total_pages,
        total: response.total_size,
      });

      setLoadingTaks(false);
    } catch (error) {
      setLoadingTaks(false);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage('Error', errorMessage);

      console.log(error);
    }
  };

  const {
    isLoading: actionLoading,
    error: errorAction,
    data: actions,
  } = useSWR(
    () => (actionsUrl ? [actionsUrl] : null),
    ([actionsUrl]) => fetcher(actionsUrl),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    setColumns(generateColumns(actions?.fields));
    // console.log(actions)
  }, [actions]);

  useEffect(() => {
    if (sourceId && userId) {
      getTasks({
        page: 1,
      });
    }
  }, [sourceId, userId]);

  return (
    <Modal isOpen={show} toggle={toggle} fullscreen>
      <ModalHeader toggle={toggle}>{`${'Reminders'}’s Tasks`}</ModalHeader>
      <ModalBody>
        <TableContainer
          loading={loadingTaks}
          columns={columns}
          data={tasks}
          pagination={pagination}
          getData={getTasks}
          resetInput={resetInput}
          setResetInput={setResetInput}
          // for show / hide columns
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          fullColumns={generateColumns(actions?.fields)}
          sheetName={'Reminders Tasks'}
          moduleId={sourceId}
          removeActionsButtons={true}
          removeActionsColumns={true}
          removePagination={true}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {buttonsTitles.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ViewReminders;
