import React, { Fragment } from "react";
import { Label, UncontrolledTooltip } from "reactstrap";
import FormInputError from "./FormInputError";
import { TagsInput } from "react-tag-input-component";
import { usePageType } from "hooks";

const TagsFormController = ({
  id,
  name,
  type,
  value,
  min,
  max,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  error,
  wrapperClassNames,
  children,
  onChange,
  validation,
  includeNumricFormat,
  readOnly,
  horizontalLayout,
  noLabel,
  labelPrefix,
  labelSuffix,
  labelAddOns,
  ...rest
}) => {
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel;
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder;
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`;

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`;

  const { invalid } = rest;

  // to know if the page link has a view word or not
  const { isViewPage } = usePageType();

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <div className="d-flex align-items-start gap-2">
          <Fragment>
            <Label
              htmlFor={id}
              className={`text-capitalize ${
                inputField?.is_required ? "required-label" : ""
              } ${labelClass ? labelClass : ""}`}
              id={`tooltip-${id}`}
            >
              {labelPrefix && labelPrefix}
              {label}
              {labelSuffix && labelSuffix}
            </Label>
            {placeholder && (
              <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
                {placeholder}
              </UncontrolledTooltip>
            )}
          </Fragment>
          <Fragment>{labelAddOns && labelAddOns}</Fragment>
        </div>
      )}
      <div className={horizontalLayout && inputClass}>
        <TagsInput
          id={id}
          name={name}
          value={value}
          placeHolder={isViewPage ? "" : placeholder}
          classNames={`form-control ${inputClass ? inputClass : ""} ${
            invalid ? "border-danger" : ""
          } ${isViewPage ? "border-0" : ""} ${
            readOnly ? "bg-gray-600 bg-opacity-10" : ""
          }`}
          onChange={(selected) => onChange(selected)}
          {...rest}
          invalid={isViewPage || readOnly ? false : invalid}
          // in view page make the input read only or if is readonly prop
          // readOnly={isViewPage || readOnly}
          separators={["Enter", " "]}
        />

        {/* Error Message */}
        {invalid && !isViewPage && !readOnly ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>
    </div>
  );
};

export default TagsFormController;
