import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState, Fragment } from "react"
import { Badge, Modal } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
import { getAllNetworkHealthCares } from "helpers/Networks/networkHealthCare"
import { TableContainer } from "components/Common"
import { buttonsTitles, messages } from "common/data"
import { useSelector } from "react-redux"
import { getNetworkExclusions } from "helpers/Networks/networks"
import { NotificationMessage, apiErrorrHandler } from "utils"

const NetworkContractsModal = ({
  show,
  onCloseClick,
  networkId,
  networkName,
  inBeneficiaryForm,
  t,
}) => {
  const [healthCareNetworks, setHealthCareNetworks] = useState([])
  const [allNewtorks, setAllNetworks] = useState([])
  const [exclusions, setExclusions] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  // show exclusions only
  const [isExclusionsOnly, setIsExclusionsOnly] = useState(false)

  //   global state
  const { claimFormData } = useSelector(state => ({
    claimFormData: state.SharedData?.claimFormData,
  }))

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Code",
        accessor: "healthcare_contract.healthcare_provider.id",
        name: "healthcareContract.healthcareProvider.id",
        Cell: cellProps => {
          return (
            <Fragment>
              {
                cellProps.row.original?.healthcare_contract?.healthcare_provider
                  ?.id
              }
            </Fragment>
          )
        },
      },
      {
        Header: "Name",
        accessor: "healthcare_contract.healthcare_provider",
        name: "healthcareContract.healthcareProvider.first_name",
        Cell: cellProps => {
          const provider =
            cellProps.row.original?.healthcare_contract?.healthcare_provider
          const isProviderExluded = exclusions.find(
            item => item.healthcare_provider?.id === provider?.id
          )
          // const isProviderExluded = provider?.isProviderExluded
          return (
            <Fragment>
              {provider?.first_name}
              {isProviderExluded ? (
                <Badge color="danger" className="ms-2 p-2">
                  {messages.providerExluded}{" "}
                  {`${isProviderExluded?.benefit?.slug}-${isProviderExluded?.class?.name}`}
                </Badge>
              ) : null}
            </Fragment>
          )
        },
      },
      {
        Header: "Provider Type",
        accessor: "healthcare_contract.healthcare_provider.type",
        name: "healthcareContract.healthcareProvider.type.name",
        Cell: cellProps => {
          return (
            <Fragment>
              {
                cellProps.row.original?.healthcare_contract?.healthcare_provider
                  ?.type?.name
              }
            </Fragment>
          )
        },
      },
      {
        Header: "Region",
        accessor: "healthcare_contract.healthcare_provider.region",
        name: "healthcareContract.healthcareProvider.region.name",
        Cell: cellProps => {
          return (
            <Fragment>
              {
                cellProps.row.original?.healthcare_contract?.healthcare_provider
                  ?.region?.name
              }
            </Fragment>
          )
        },
      },
      {
        Header: "Active",
        accessor: "is_active",
        name: "is_active",
        fieldType: "boolean",
        Cell: cellProps => {
          return (
            <Fragment>
              {cellProps.row.original?.is_active === 1 ? "Yes" : "No"}
            </Fragment>
          )
        },
      },
    ],
    [exclusions]
  )

  const getExclusions = async () => {
    try {
      const networkExclusionsRes = await getNetworkExclusions(networkId, {
        per_page: 1000,
        product_id: claimFormData?.product?.id,
        benefit_id: claimFormData?.benefit?.id,
        class_id: claimFormData?.class?.id,
      })

      setExclusions(networkExclusionsRes.items)
    } catch (error) {
      console.log("error", error)
    }
  }
  const getNewtorkHealthCares = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    ...rest
  }) => {
    try {
      setLoading(true)
      const networkHealthCareRes = await getAllNetworkHealthCares({
        network_id: networkId,
        page,
        per_page: 1000,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        ...rest,
      })
      // const networkExclusionsRes = await getNetworkExclusions(networkId, {
      //   page,
      //   per_page: 1000,
      //   sort_by,
      //   sort_type,
      //   search_value,
      //   search_keys,
      //   search_values,
      //   product_id: claimFormData?.product?.id,
      //   benefit_id: claimFormData?.benefit?.id,
      //   class_id: claimFormData?.class?.id,
      // })

      setHealthCareNetworks(networkHealthCareRes.items)
      setAllNetworks(networkHealthCareRes.items)
      // setExclusions(networkExclusionsRes.items)
      if (!search_values && !search_keys) {
        getExclusions()
      }

      setPagination({
        size: networkHealthCareRes.size,
        page: networkHealthCareRes.page,
        per_page: networkHealthCareRes.per_page,
        total_pages: networkHealthCareRes.total_pages,
        total: networkHealthCareRes.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
      console.log("error", errorMessage)
    }
  }

  useEffect(() => {
    if (isExclusionsOnly) {
      const exlusionsIDs = exclusions.map(item => item?.healthcare_provider?.id)
      const excluded = healthCareNetworks.filter(item =>
        exlusionsIDs.includes(
          item?.healthcare_contract?.healthcare_provider?.id
        )
      )
      setHealthCareNetworks(excluded)
    } else {
      setHealthCareNetworks(allNewtorks)
    }
  }, [isExclusionsOnly])
  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <div className="d-flex  gap-2">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {networkName} : Healthcare Providers
          </h5>
          {/* show it in network beneficiary form not in claim */}
          {inBeneficiaryForm ? (
            <div className="d-flex gap-2 align-items-center">
              <label htmlFor="">Show Exclusions only</label>
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="square-switch2"
                  switch="info"
                  defaultChecked={isExclusionsOnly}
                  onChange={() => setIsExclusionsOnly(!isExclusionsOnly)}
                />
                <label
                  htmlFor="square-switch2"
                  data-on-label="Yes"
                  data-off-label="No"
                />
              </div>
            </div>
          ) : null}
        </div>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <TableContainer
          removeActionsColumns={true}
          removeActionsButtons={true}
          columns={columns}
          data={healthCareNetworks}
          handleAdd={() => {}}
          pagination={pagination}
          queryAddOn={{ network_id: networkId }}
          getData={getNewtorkHealthCares}
          loading={loading}
        />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCloseClick}
        >
          {buttonsTitles.close}
        </button>
      </div>
    </Modal>
  )
}

NetworkContractsModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default withTranslation()(NetworkContractsModal)
