import { generalRequest } from "helpers/General/general_request"
import { convertNullToString } from "utils/smallUtils"

export const fetcher = async (url, params = {}, onFetchCB = undefined) => {
  const res = await generalRequest({
    url,
    ...params,
  })
  const data = res?.item ? res?.item : res?.items ? res?.items : null
  if (onFetchCB) {
    onFetchCB(data)
  }
  //   if the data is object convert any null value in it to string
  // if the data is array return it as it is
  return data instanceof Object && data.constructor === Object
    ? convertNullToString(data)
    : data
}
