export const policyStatus = {
  expired: 639,
  rejected: 638,
  active: 637,
  inProgress: 636,
}

export const policyStatusSlugs = {
  active: "policyStatus-active",
  expired: "policyStatus-expired",
  inProgress: "policyStatus-inprogress",
}
