/*
This is Hook is used to Decrypt the Record ID in the URL
and if the ID is not decrypted it will redirect to the home page
params => Id => encrypted ID
*/
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { decryptText } from "utils"

const useDecryptId = encryptedId => {
  const [decryptedId, setDecryptedId] = useState("")
  const history = useHistory()
  useEffect(() => {
    if (encryptedId) {
      const result = decryptText(encryptedId)
      if (result) {
        setDecryptedId(+result)
      } else {
        history.replace("/")
      }
    }
  }, [encryptedId])

  return { decryptedId }
}

export default useDecryptId
