import React, { Fragment, useEffect, useRef, useState } from "react"
import { Input, InputGroup, Label, UncontrolledTooltip } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./dateTimePicker.css"
import moment from "moment"
import FormInputError from "./FormInputError"
import { useLocation } from "react-router-dom"
import { usePageType } from "hooks"
import { APIDateFormat } from "constants/TPA"
const DateTimePickerInput = ({
  id,
  name,
  value,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  onChange,
  customOnChange,
  onBlur,
  invalid,
  error,
  wrapperClassNames,
  disabledDates,
  disableFutureDates,
  disablePastDates,
  disableFutureDatesAndToday,
  disablePastDatesAndToday,
  removeDisable,
  removeIcon,
  noLabel,
  minDate,
  maxDate,
  readOnly,
  removeOnClose,
  notCloseOnSelect,
  clearIcon,
  inFilter,
  showTime,
  disableInvalidPreload,
  minTime,
  maxTime,
}) => {
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  }${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  }${propInputClass ? propInputClass : ""}`

  const handleChange = (date) => {
    onChange(
      `${name}`,
      date ? moment(date).format(`${APIDateFormat} hh:mm:ss A`) : ""
    )
  }

  const handleBlur = () => {
    onBlur(`${name}`, true)
  }

  const location = useLocation()
  const { pathname } = location

  const { isViewPage, isViewProcessingPage } = usePageType()
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2]

  let handleTimeClassNames = (time) => {
    return moment(time).format("hh:mm") < minTime ? "pe-none text-gray-400" : ""
  }
  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel ? (
        <Fragment>
          <Label
            htmlFor={id}
            className={`text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${labelClass ? labelClass : ""}`}
            id={`tooltip-${id}`}
          >
            {label}
          </Label>
          {placeholder && (
            <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
              {placeholder}
            </UncontrolledTooltip>
          )}
        </Fragment>
      ) : null}

      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholderText={placeholder}
        showTimeSelect
        // to make the calender on top of every element
        popperProps={{ strategy: "fixed" }}
        className={`form-control ${pageType === "view" ? "border-0" : ""} ${
          readOnly ? "bg-gray-600 bg-opacity-10" : ""
        } ${invalid ? "border-danger" : ""}`}
        timeFormat="hh:mm a"
        timeIntervals={15}
        timeCaption="time"
        timeClassName={handleTimeClassNames}
        dateFormat={"dd-MM-yyyy hh:mm:ss"}
        isClearable={
          !isViewPage && !isViewProcessingPage && !readOnly ? true : false
        }
        disabled={isViewPage || isViewProcessingPage || readOnly ? true : false}
      />

      {/* Error Message */}
      {invalid && !isViewPage && !isViewProcessingPage && !readOnly ? (
        <FormInputError error={error} selectError={true} />
      ) : null}
    </div>
  )
}

export default DateTimePickerInput
