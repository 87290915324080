import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { withTranslation } from "react-i18next"

const Breadcrumb = props => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>

          {/* Right Section of BreadCumbers */}

          {/* <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div> */}
          <div className="d-flex align-content-center gap-2">
            {props.includeBackButton ? (
              <Link to=".." className="btn w-sm btn-secondary">
                {props.t("Back")}
              </Link>
            ) : null}
            {props.children}
          </div>
        </div>
      </Col>
      {/* <Col className="col-1">
        {props.includeBackButton ? (
          <Link to=".." className="btn w-sm btn-secondary">
            Back
          </Link>
        ) : null}
      </Col> */}
    </Row>
  )
}

Breadcrumb.propTypes = {
  // breadcrumbItem: PropTypes.string,
  breadcrumbItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
}

export default withTranslation()(Breadcrumb)
