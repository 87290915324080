import { buttonsTitles } from "common/data"
import React, { Fragment, useState } from "react"
import { Row } from "reactstrap"
import MultiSkeleton from "../Common/MultiSkeleton"
import { FormModal, SearchSelect } from "../Common/Forms"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  NotificationMessage,
  apiErrorrHandler,
  generateYearsOptions,
  isInputInvalid,
} from "utils"
import * as URLs from "helpers/url_helper"
import { calculateCauseSpending } from "helpers/Production/Policies/beneficaries_helper"
import { StickyHeaderTable } from "../Common/Tables"
import moment from "moment"

const FocSpentModal = ({ onCloseClick, show, beneficiaryId }) => {
  const [causeSpendings, setCauseSpendings] = useState([])

  const handleCalculateCauseSpending = async (values, setSubmitting) => {
    try {
      const res = await calculateCauseSpending(beneficiaryId, {
        underwriting_year: values.year,
        cause_ids: values.cause_ids,
      })
      setCauseSpendings(res.item?.map(item => item?.cause))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      year: `${moment().get("year")}`,
      cause_ids: [],
    },
    validationSchema: Yup.object({
      year: Yup.string(),
      cause_ids: Yup.array(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleCalculateCauseSpending(values, setSubmitting, resetForm)
    },
  })

  const { values, setFieldValue, setFieldTouched, errors, isSubmitting } =
    validation

  return (
    <FormModal
      modalSize="md"
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={validation.handleSubmit}
      modalTitle={buttonsTitles.focSpent}
      isSaving={validation.isSubmitting}
      saveButtonTitle={buttonsTitles.submit}
    >
      <Fragment>
        <form>
          <Row>
            <SearchSelect
              //   id="1075"
              name="year"
              label="Underwriting Year"
              value={values.year}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              options={generateYearsOptions(30)}
              invalid={isInputInvalid(validation, "year")}
              error={errors.year}
              wrapperClassNames="mb-3"
            />
            <SearchSelect
              id="415"
              name="cause_ids"
              label="Claim Motive"
              // inputField={fieldFinder(inputs, 415)}
              inputField={{ predefined_value: true }}
              value={values.cause_ids}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              mappingLabel="name"
              mappingValue="id"
              url={`${URLs.PREDEFINEDS}/235`}
              invalid={isInputInvalid(validation, "cause_ids")}
              error={errors.cause_ids}
              wrapperClassNames="mb-3"
              multiple={true}
            />
          </Row>
        </form>

        <Fragment>
          {isSubmitting ? (
            <MultiSkeleton length={4} />
          ) : (
            <StickyHeaderTable
              columns={["Claim Motive Name", "Amount $"]}
              isLoading={isSubmitting}
              noData={causeSpendings.length === 0}
            >
              {causeSpendings
                .sort((a, b) => b?.calculated_value - a?.calculated_value)
                .map(cause => (
                  <tr key={cause.id}>
                    <td>{cause?.name}</td>
                    <td>
                      {Math.ceil(cause?.calculated_value)?.toLocaleString(
                        "en-us"
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td>
                  <span className="fw-bold">Total: </span>
                </td>
                <td>
                  {Math.ceil(
                    causeSpendings.reduce(
                      (total, item) => total + item?.calculated_value,
                      0
                    )
                  )?.toLocaleString("en-us")}
                </td>
              </tr>
            </StickyHeaderTable>
          )}
        </Fragment>
      </Fragment>
    </FormModal>
  )
}

export default FocSpentModal
