import { request } from "../api_helper"
import { NETWORKHEALTHCARE } from "../url_helper"
/*
Networks
*/

// add new network

// url
export const addNetworkHealthCare = (data) =>
  request({ url: NETWORKHEALTHCARE, method: "POST", data })

// get all healthCares with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllNetworkHealthCares = ({
  network_id,
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: NETWORKHEALTHCARE,
    method: "GET",
    params: {
      network_id,
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// delete network
//url/{id}
export const deleteNetworkHealthCare = (networkId) =>
  request({
    url: `${NETWORKHEALTHCARE}/${networkId}`,
    method: "DELETE",
  })

// activate network
//url/activate/{id}
export const activateNetworkHealthCare = (networkId) =>
  request({
    url: `${NETWORKHEALTHCARE}/${networkId}/activate`,
    method: "POST",
  })

// deactivate network
//url/deactivate/{id}
export const deactivateNetworkHealthCare = (networkId) =>
  request({
    url: `${NETWORKHEALTHCARE}/${networkId}/deactivate`,
    method: "POST",
  })
