import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"

// sidebar permissions

import sidebarSaga from "./permissions/sidebar/saga"

import moduleSaga from "./permissions/modules/saga"
import notificationsSaga from "./general/notifications/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(sidebarSaga),
    fork(moduleSaga),
    fork(notificationsSaga),
  ])
}
