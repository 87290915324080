import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { CSVLink } from "react-csv";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { previewDateFormat } from "constants/TPA";
import { buttonsTitles } from "common/data";
import { flattenObject } from "utils/smallUtils";
import { exportToExcel } from "utils";

const ExportTable = ({
  data,
  sheetName,
  customCSVHeader,
  customCSVData,
  customPDFHeader,
  customPDFData,
  columns,
  removePDFExport = false,
  // ref
}) => {
  const { moduleName } = useSelector((state) => ({
    moduleName: state.Module.name,
  }));
  // state to handle dropdown opening and closing for Export
  const [isExportOpen, setIsExportOpen] = useState(false);

  const csvData = data.map((row) => {
    let modifiedRow = Object.fromEntries(
      // convert prices to array, map each key/value pair into another pair
      // and then fromEntries gives back the object
      Object.entries(row).map(([key, value]) => {
        return moment(value, "YYYY-MM-DD", true).isValid()
          ? [key, moment(value).format("DD/MM/YYYY")]
          : [key, value];
      })
    );

    return {
      ...modifiedRow,
      is_active: modifiedRow.is_active === 1 ? "Yes" : "No",
    };
  });

  // to make it not include checkboxes column
  const csvHeader = columns
    .filter((item) => item.Header !== "#" && item.Header !== "Actions")
    .map((column) => ({
      label: column.Header,
      key: column.accessor,
    }));

  // to make it not include checkboxes column
  const pdfHeader = columns
    .filter((item) => item.Header !== "#" && item.accessor !== "actions")
    .map((column) => ({
      header: column.Header,
      dataKey: column.accessor,
    }));

  // generate pdf from table
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    // const orientation = "portrait" // portrait or landscape
    // make orientation dynamic based on the number of columns
    const orientation = columns?.length > 5 ? "landscape" : "portrait"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    // make the title equal module name
    const title = sheetName ? sheetName : moduleName?.trim();
    // get page Size
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

    doc.text(title, pageWidth / 2 - doc.getTextWidth(title) / 2, 20);

    // headers from fields in level 2
    const headers = customPDFHeader
      ? customPDFHeader
      : customCSVHeader
      ? customCSVHeader.map((item) =>
          typeof item == "object"
            ? { header: item.label, dataKey: item.key }
            : item
        )
      : pdfHeader;
    // the data comming from API
    const data = customPDFData
      ? customPDFData
      : customCSVData
      ? customCSVData
      : csvData;

    let content = {
      startY: 30,
      columns: headers,
      // body: data,
      body: data?.map((item) =>
        typeof item == "object" ? flattenObject(item) : item
      ),
    };

    doc.autoTable(content);

    const pageCount = doc.getNumberOfPages();
    // this will be displayed in each page
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      // get page Sizes
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // footer
      const userName = JSON.parse(localStorage.getItem("authUser"))?.name;
      const pageNumber = `Page ${i} of ${pageCount}`;
      const timeStamp = `${moment().format(
        previewDateFormat
      )} ${moment().format("hh:mm:ss")}`;

      doc.setFontSize(12);
      doc.text(userName, 40, pageHeight - 15, { baseline: "bottom" });
      doc.text(
        timeStamp,
        pageWidth / 2 + doc.getTextWidth(userName),
        pageHeight - 15,
        {
          baseline: "bottom",
          align: "right",
        }
      );

      doc.text(pageNumber, pageWidth - 40, pageHeight - 15, {
        baseline: "bottom",
        align: "right",
      });
    }

    doc.save(
      `${sheetName ? sheetName : moduleName}_${new Date().toISOString()}.pdf`
    );
  };

  return (
    <Fragment>
      {removePDFExport ? (
        <Fragment>
          {customCSVData ? (
            <CSVLink
              data={customCSVData ? customCSVData : csvData}
              headers={customCSVHeader ? customCSVHeader : csvHeader}
              filename={`${
                sheetName ? sheetName : moduleName
              }_${new Date().toISOString()}.csv`}
              className="btn btn-secondary"
            >
              {buttonsTitles.exportExcel}
            </CSVLink>
          ) : (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                exportToExcel({
                  headers: customCSVHeader ? customCSVHeader : csvHeader,
                  data: customCSVData ? customCSVData : csvData,
                  filename: sheetName ? sheetName : moduleName,
                });
              }}
            >
              {buttonsTitles.exportExcel}
            </button>
          )}
        </Fragment>
      ) : (
        <Dropdown
          isOpen={isExportOpen}
          toggle={() => setIsExportOpen(!isExportOpen)}
        >
          {/* Export DropDown Toggle */}
          <DropdownToggle className="btn btn-secondary" caret>
            {buttonsTitles.export}
            <i className="mdi mdi-chevron-down px-1" />
          </DropdownToggle>

          {/* Export Dropdown Menu Items */}
          <DropdownMenu className="dropdown-menu-end">
            {customCSVHeader ? (
              <DropdownItem style={{ color: "inherit" }}>
                <CSVLink
                  data={customCSVData ? customCSVData : csvData}
                  headers={customCSVHeader ? customCSVHeader : csvHeader}
                  filename={`${
                    sheetName ? sheetName : moduleName
                  }_${new Date().toISOString()}.csv`}
                >
                  {buttonsTitles.exportExcel}
                </CSVLink>
              </DropdownItem>
            ) : (
              <DropdownItem
                style={{ color: "inherit" }}
                onClick={() => {
                  exportToExcel({
                    headers: customCSVHeader ? customCSVHeader : csvHeader,
                    data: customCSVData ? customCSVData : csvData,
                    filename: sheetName ? sheetName : moduleName,
                  });
                }}
              >
                {buttonsTitles.exportExcel}
              </DropdownItem>
            )}
            <DropdownItem
              style={{ color: "inherit" }}
              onClick={() => exportPDF()}
            >
              {buttonsTitles.exportPDF}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </Fragment>
  );
};

ExportTable.propTypes = {
  t: PropTypes.any,
};
export default withTranslation()(ExportTable);
