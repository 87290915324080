import React, { Fragment, useState } from "react";
import TagsFormController from "../Common/Forms/TagsFormController";
import { FormModal } from "../Common/Forms";
import { apiErrorrHandler, NotificationMessage } from "utils";
import { postRequest } from "helpers/General/general_request";
import { CHAT } from "helpers/url_helper";

const SendConversationsViaEmail = ({ conversationId, disabled }) => {
  const [isModal, setIsModal] = useState(false);

  const [emails, setEmails] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const sendEmail = async () => {
    setIsSaving(true);
    try {
      const res = await postRequest(`${CHAT}/${conversationId}/send-email`, {
        emails: emails,
        path: "Conversation/Conversation",
      });

      NotificationMessage("success", res.message);
      setIsModal(false);
      setEmails("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Fragment>
      <button
        type="button"
        disabled={disabled}
        onClick={() => setIsModal(true)}
        // send the last message of this conversation
        className=" btn btn-green d-flex align-items-center justify-content-center gap-2"
      >
        <i className="bx bx bx-envelope font-size-17" />
        <span>Send Via Email</span>
      </button>

      {isModal && (
        <FormModal
          show={isModal}
          onCloseClick={() => {
            setIsModal(false);
            setEmails("");
          }}
          onSaveClick={() => {
            sendEmail();
          }}
          saveButtonTitle="Send"
          modalTitle={"Send Via Email"}
          isSaving={isSaving}
        >
          <form onSubmit={(e) => e.preventDefault()}>
            <Fragment>
              <TagsFormController
                id="964"
                name="emails"
                type="text"
                label="Emails:"
                labelSuffix={
                  <span className="font-size-10 text-gray-600">
                    {" "}
                    Please Click Enter Button After Writting Your Email
                  </span>
                }
                placeholder="Enter your emails"
                value={emails}
                invalid={false}
                onChange={(selected) => setEmails(selected)}
                error={""}
                wrapperClassNames="mb-3"
              />
            </Fragment>
          </form>
        </FormModal>
      )}
    </Fragment>
  );
};

export default SendConversationsViaEmail;
