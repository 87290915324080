import React from "react"

const DataIcon = ({ title, icon, data }) => {
  return (
    <li className="d-flex gap-2 align-items-center">
      {icon ? <i className={`${icon} font-size-14 text-primary`}></i> : null}
      <span className="fw-bold">{title}</span>
      <span>{data}</span>
    </li>
  )
}

export default DataIcon
