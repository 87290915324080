import React, { Fragment, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
// UI Components
import {
  FormModal,
  FormControll,
  TimePickerInput,
  DatePickerInput,
} from "components/TP/Common/Forms"
import { Alert } from "reactstrap"

// redux hooks
import { useSelector } from "react-redux"

//utils functions
import { apiErrorrHandler, isInputInvalid, NotificationMessage } from "utils"
import { addClaimReminder } from "helpers/Claims/ClaimForm"

const AddReminderModal = ({ isModal, setIsModal, claimId, t }) => {
  // local states
  const [error, setError] = useState("")

  // current module name
  const { moduleName } = useSelector(state => ({
    moduleName: state.Module?.name,
  }))

  // add region to the DB

  const handleAddReminder = async (values, setSubmitting, resetForm) => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"))?.id

    try {
      await addClaimReminder(claimId, {
        reminder_user_id: loggedInUser,
        ...values,
      })
      NotificationMessage("success", "add")
      resetForm()
      setIsModal(false)
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }

  // validation with formik and Yup
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: "",
      date: "",
      time: "",
    },
    validationSchema: Yup.object({
      description: Yup.string(),
      date: Yup.string(),
      time: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleAddReminder(values, setSubmitting, resetForm)
    },
  })

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    isSubmitting,
    resetForm,
    handleSubmit,
    getFieldProps,
  } = validation
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
        resetForm()
        setError("")
      }}
      onSaveClick={handleSubmit}
      modalTitle={`${moduleName} Reminder`}
      isSaving={isSubmitting}
    >
      <form onSubmit={e => e.preventDefault()}>
        {error ? <Alert color="danger">{error}</Alert> : null}
        <Fragment>
          <TimePickerInput
            id="1561"
            name="time"
            inputField={{ label_name: "Time" }}
            value={values.time}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            format="HH:mm"
            invalid={isInputInvalid(validation, "time")}
            error={errors.time}
            wrapperClassNames="mb-3"
          />

          <DatePickerInput
            id="393"
            name="date"
            type="text"
            inputField={{ label_name: "Date" }}
            value={values.date}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            invalid={isInputInvalid(validation, "date")}
            error={errors.date}
            disablePastDates={true}
            wrapperClassNames="mb-3"
          />
          <FormControll
            id="531"
            name="description"
            type="textarea"
            inputField={{ label_name: "Description" }}
            {...getFieldProps("description")}
            invalid={isInputInvalid(validation, "description")}
            error={errors.description}
            wrapperClassNames="mb-3"
          />
        </Fragment>
      </form>
    </FormModal>
  )
}

export default withTranslation()(AddReminderModal)
