import moment from "moment"
import i18n from "i18n"
export function findDateDifference(date) {
  let message
  if (date) {
    const seconds = moment().diff(date, "second")
    const minutes = moment().diff(date, "minute")
    const hours = moment().diff(date, "hour")
    const days = moment().diff(date, "days")
    const weeks = moment().diff(date, "week")
    const months = moment().diff(date, "month")
    const years = moment().diff(date, "year")

    if (years > 0 && months >= 12) {
      message =
        years === 1 ? i18n.t("a year ago") : `${years} ${i18n.t("years ago")}`
    } else if (months > 0 && years < 1) {
      message =
        months === 1
          ? i18n.t("a month ago")
          : `${months} ${i18n.t("months ago")}`
    } else if (weeks > 0 && weeks < 4 && months === 0) {
      message =
        weeks === 1 ? i18n.t("a week ago") : `${weeks} ${i18n.t("weeks ago")}`
    } else if (weeks === 0 && days > 0) {
      message =
        days === 1 ? i18n.t("a day ago") : `${days} ${i18n.t("days ago")}`
    } else if (days === 0 && hours > 0 && hours < 24) {
      message =
        hours === 1 ? i18n.t("an hour ago") : `${hours} ${i18n.t("hours ago")}`
    } else if (minutes > 0 && minutes < 60 && hours < 1) {
      message =
        minutes === 1
          ? i18n.t("a minute ago")
          : `${minutes} ${i18n.t("minutes ago")}`
    } else if (seconds > 0 && seconds < 60 * 60 && minutes < 60) {
      message =
        seconds === 1
          ? i18n.t("a second ago")
          : `${seconds} ${i18n.t("seconds ago")}`
    } else {
      message = ""
    }
  }

  return message
}
