import { request } from '../../api_helper';
import * as url from '../../url_helper';

// getAll Medicals
export const getAllMedicalsOpinion = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.MEDICALOPINION,
    method: 'GET',
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : 'id',
      sort_type: sort_type ? sort_type : 'desc',
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  });

// add Medical

export const addMedicalOpinion = (data) =>
  request({ url: url.MEDICALOPINION, method: 'POST', data });

// show medical

export const showMedicalOpinion = (medicalId) =>
  request({ url: `${url.MEDICALOPINION}/${medicalId}`, method: 'GET' });

//  update medical

export const updateMedicalOpinion = (data) =>
  request({ url: `${url.MEDICALOPINION}/${data.id}`, method: 'PUT', data });

// deactivate Medical

export const activateMedicalOpinion = (medicalId) =>
  request({
    url: `${url.MEDICALOPINION}/${medicalId}/activate`,
    method: 'POST',
  });

// deactivate Medical
//url/{id}/deactivate
export const deactivateMedicalOpinion = (medicalId) =>
  request({
    url: `${url.MEDICALOPINION}/${medicalId}/deactivate`,
    method: 'POST',
  });

// deleteMedical

export const deleteMedicalOpinion = (medicalId) =>
  request({ url: `${url.MEDICALOPINION}/${medicalId}`, method: 'DELETE' });

export const addMedicalOpinTask = (id, data) =>
  request({
    url: `${url.MEDICALOPINION}/${id}/staff-tasks`,
    method: 'POST',
    data,
  });

export const getAllMedicalSourceTasks = (
  id,
  {
    page,
    per_page,
    sort_by,
    sort_type,
    search_keys,
    search_values,
    search_value,
    view,
    ...rest
  }
) =>
  request({
    url: `${url.MEDICALOPINION}/${id}/staff-tasks`,
    method: 'GET',
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : 'id',
      sort_type: sort_type ? sort_type : 'desc',
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  });
