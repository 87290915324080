export const settingsPaths = {
  notificationTemplate: "/nofitications-templates",
  claimRule: "/claim-rule",
  claimAuthority: "/claim-financial-authority",
  claimDistribution: "/claim-distribution-engine",
  billingPanels: "/billing_panels",
  informativePages: "/informative-pages",
  edi: "/edi-logs",
  transactionLog: "/transaction-logs",
}
