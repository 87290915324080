import { Skeleton } from "antd"
import React from "react"

const MultiSkeleton = ({ length, avatar, title }) => {
  return Array.from({ length: length }, (_, index) => (
    <Skeleton
      key={index}
      active
      avatar={avatar ? avatar : false}
      title={title ? title : false}
      style={{ marginBottom: length === 1 ? "5px" : "0px" }}
      paragraph={{
        rows: 1,
        width: "100%",
        style: { margin: "5px", marginLeft: title ? "0px" : "5px" },
      }}
    />
  ))
}

export default MultiSkeleton
