import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"

import { buttonsTitles, modalHeaders } from "common/data"
import { getCurrentLocation } from "utils"
import Map from "pages/Map/MapComponent"

// this component could be imported in claim
// of beneficiary
// in beneficiary we need to fetch claim permission
// in claim we will have it already so no need to fetch it again
const GoogleMapsModal = ({
  show,
  preloadedLocation,
  onCloseClick,
  onSelectPlace,
  t,
}) => {
  const [location, setLocation] = useState()
  useEffect(() => {
    const getLocation = async () => {
      try {
        const location = await getCurrentLocation()
        setLocation(location)
        console.log("getting location:", location)
      } catch (error) {
        console.error("Error getting location:", error)
      }
    }
    getLocation()
  }, [])

  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      // className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          {modalHeaders.maps}
        </h5>

        {/* close button on the top right section */}
        <button
          aria-label="Close Modal"
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <Map
          CurrentLocation={
            preloadedLocation ? preloadedLocation : location && location
          }
          onSelectPlace={onSelectPlace}
        />
      </div>

      <div className="modal-footer">
        <button
          aria-label="Close Modal"
          type="button"
          className="btn btn-secondary"
          onClick={onCloseClick}
        >
          {buttonsTitles.close}
        </button>
      </div>
    </Modal>
  )
}

GoogleMapsModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default withTranslation()(GoogleMapsModal)
