import { buttonsTitles } from "common/data"
import React, { Fragment, useState } from "react"
import { Badge, Row } from "reactstrap"
import { FormModal, SearchSelect } from "../Common/Forms"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  NotificationMessage,
  apiErrorrHandler,
  generateYearsOptions,
  isInputInvalid,
} from "utils"
import * as URLs from "helpers/url_helper"
import { calculateBeneficaryLimits } from "helpers/Production/Policies/beneficaries_helper"
import { StickyHeaderTable } from "../Common/Tables"
import MultiSkeleton from "../Common/MultiSkeleton"
import moment from "moment"

const FOBSpentModal = ({ onCloseClick, show, beneficiaryId, beneficiary }) => {
  const [limits, setLimits] = useState([])

  const getBeneficiaryLimits = async (values, setSubmitting) => {
    try {
      const res = await calculateBeneficaryLimits(beneficiaryId, {
        policy_id: values.policy_id,
        benefit_id: values.benefit_id,
      })
      const resLimits = res?.item?.limits || []
      const memberClass = beneficiary?.co_participations?.find(
        item => item?.benefit?.id === values.benefit_id
      )?.class?.id
      // filter limits based on FOB id and class
      setLimits(
        resLimits
          ?.filter(
            item =>
              item?.benefit[0]?.id === values.benefit_id &&
              item?.class?.id === memberClass
          )
          .map(item => ({
            ...item,
            remaing_limit:
              item?.value - (item?.calculated_value - item?.hold_amount),
          }))
      )
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
      setLimits([])
    } finally {
      setSubmitting(false)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      underwriting_year: `${moment().get("year")}`,
      benefit_id: "",
      policy_id: "",
    },
    validationSchema: Yup.object({
      underwriting_year: Yup.string(),
      benefit_id: Yup.string(),
      policy_id: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      getBeneficiaryLimits(values, setSubmitting, resetForm)
    },
  })
  const { values, setFieldValue, setFieldTouched, errors, isSubmitting } =
    validation
  return (
    <FormModal
      modalSize="md"
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={validation.handleSubmit}
      modalTitle={buttonsTitles.fobSpent}
      isSaving={validation.isSubmitting}
      saveButtonTitle={buttonsTitles.submit}
    >
      <Fragment>
        <form>
          <Row>
            <SearchSelect
              //   id="1075"
              name="underwriting_year"
              label="Underwriting Year"
              value={values.underwriting_year}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              options={generateYearsOptions(30)}
              invalid={isInputInvalid(validation, "underwriting_year")}
              error={errors.underwriting_year}
              wrapperClassNames="mb-3"
            />
            <SearchSelect
              id="415"
              name="policy_id"
              label="Policy"
              value={values.policy_id}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              mappingLabel="name"
              mappingValue="id"
              url={URLs.POLICIES}
              generateCustomLabels={item => (
                <div className="d-flex align-items-center flex-wrap gap-1">
                  {`${item?.reference} `}
                  <Badge color="danger">{item?.status?.name}</Badge>
                </div>
              )}
              disableFetch={!values.underwriting_year ? true : false}
              dynamicQuery={{
                underwriting_year: values.underwriting_year,
                beneficiary_id: beneficiaryId,
              }}
              invalid={isInputInvalid(validation, "policy_id")}
              error={errors.policy_id}
              wrapperClassNames="mb-3"
            />
            <SearchSelect
              id="415"
              name="benefit_id"
              label="FOB"
              value={values.benefit_id}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              mappingLabel="name"
              mappingValue="id"
              url={URLs.BENEFITS}
              urlAddOns={{
                has_slug: 1,
                class_allowed: 1,
              }}
              generateFilteredOptions={options =>
                options.filter(option => option?.slug !== "OP")
              }
              invalid={isInputInvalid(validation, "benefit_id")}
              error={errors.benefit_id}
              wrapperClassNames="mb-3"
            />
          </Row>
        </form>

        <Fragment>
          {isSubmitting ? (
            <MultiSkeleton length={4} />
          ) : (
            <StickyHeaderTable
              columns={["FOB", "Amount $", "Remaining Limit $"]}
              isLoading={isSubmitting}
              noData={limits.length === 0}
            >
              {limits.map(limit => (
                <tr key={limit.id}>
                  <td>{limit?.benefit[0]?.name}</td>
                  <td>{Math.ceil(limit?.value)?.toLocaleString("en-us")}</td>
                  <td>
                    {Math.ceil(
                      limit?.value -
                        (limit?.calculated_value + limit?.hold_amount)
                    )?.toLocaleString("en-us")}
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <span className="fw-bold">Total: </span>
                </td>
                <td>
                  {Math.ceil(
                    limits.reduce((total, item) => total + item?.value, 0)
                  )?.toLocaleString("en-us")}
                </td>
                <td>
                  {Math.ceil(
                    limits.reduce(
                      (total, item) =>
                        total +
                        item?.value -
                        (item?.calculated_value + item?.hold_amount),
                      0
                    )
                  )?.toLocaleString("en-us")}
                </td>
              </tr>
            </StickyHeaderTable>
          )}
        </Fragment>
      </Fragment>
    </FormModal>
  )
}

export default FOBSpentModal
