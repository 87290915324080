import React from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"
import { convertNameToRoute } from "utils"

const SidebarItem = ({ item }) => {
  return (
    <Link
      to={`/${convertNameToRoute(item.name)}`}
      data-toggle="tooltip"
      title={item.title}
    >
      <i className={item.icon ? item.icon : "bx bxs-camera-off"}></i>
      <span>{item.title}</span>
    </Link>
  )
}

SidebarItem.propTypes = {
  item: PropTypes.object,
}
export default SidebarItem
