/*
Authentication
*/

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";
//LOGIN
export const POST_EMAIL_LOGIN = "/auth/login";
//LOGOUT
export const POST_LOGOUT = "/auth/logout";
// LOGGED IN USER
export const POST_AUTH_USER = "/auth/me";
// Forget Password
export const POST_FORGOT_PASSWORD = "/auth/forgot-password";
// Reset Password
export const POST_RESET_PASSWORD = "/auth/reset-password";
// Forget Password
export const POST_REFRESH_TOKEN = "/auth/refresh";

// change Password
export const CHANGE_PASSWORD = "/auth/change-password";

//PROFILEs
export const PROFILE = "/auth/profile";

/*
setting modules
*/
// regions
export const REGIONS = "/settings/regions";

// predefineds
export const PREDEFINEDS = "/settings/predefined-values";

// jobtitles
export const JOBTITLES = "/settings/job-titles";

// jobRoles
export const JOBROLES = "/settings/job-roles";
// Users
export const USERS = "/settings/users";

// Priority Payers
export const PRIORITYPAYERS = "/settings/priority-payers";

// Taxes
export const TAXES = "/settings/taxes";

// Company
export const COMPANY = "/stakeholders/tpas";

// Logs (Log Files)

export const LOGFILES = "/settings/logs";

// Fields Control

export const FIELDS = "/settings/fields";
// Work FLows
export const WORKFLOWS = "/settings/workflows";

// Notfications Template
export const NOTIFICATIONSTEMPLATE = "/settings/notification-templates";

// Claim Rules
export const CLAIMRULES = "/settings/claim-rules";

// PCIS Rules
export const PCISRULES = "/settings/pcis";

// claimAuthority
export const CLAIMAUTHORITY = "/settings/claim-financial-authorities";
// claimAuthority
export const CLAIMDISTRIBUTION = "/settings/claim-distributions";
// Billing Rules
export const BILLINGRULES = "/settings/billing-panels";
// Validate Billing Rules
export const VALIDATEBILLINGRULES = "/settings/services/billing-panels";
// informative Pages
export const INFORMATIVEPAGES = "/general/informative-pages";

//Http TransactionLogs
export const TRANSACTIONLOGS = "/settings/http-transaction-logs";

export const CALCULATEDRUGREFILL = "/settings/calculate-drug-refill";

// Edi
export const EDIPAGES = "/edi/logs";
/*
Common Data Modules
*/

// Banks

export const BANKS = "/common/banks";

// Services

export const SERVICES = "/common/services";
// Benefits
export const BENEFITS = "/common/benefits";
// Medical codes
export const MEDICALCODES = "/common/medical-codes";
//covers
export const COVERS = "/common/covers";
//line of business
export const LOB = "/general/line-of-business";
// Medical Codes Types
export const MEDICALCODESTYPES = "/common/medical-code-types";

// event Manager
export const EVENTMANAGER = "/common/events";

// TOB
export const TOB = "/common/tob-templates";
export const TEMPLATEBENEFITS = "/common/tob-template-benefit";

// Validate TOB
export const VALIDATETOB = "/common/tob/automate";
// PROFORMA ITEM
export const PROFORMITEMS = "/common/proforma-items";
// PROFORMA ITEM PROVIDERS
export const PROFORMITEMSPROVIDERS = "/common/proforma-item-providers";
// Uncovered Benefit in table benefits
export const TEMPLATEUNCOVEREDBENEFITS = "excluded-benefits";

// Priority Payer Codes
export const PRIORITYPAYERSCODES = "standard-codes";

/*
 permissions
*/

// sidebar permissions (user-modules)

export const USER_MODULES = "/auth/modules";

export const MODULES_SUBMODULES = "/auth/modules-sub-modules";

// export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILEs
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//Currencies
export const CURRENCIES = "/settings/currencies";

// StackHolders
//Re-insurer
export const REINSURER = "/stakeholders/reinsurers";

//PHYSICIAN
export const PHYSICIAN = "/stakeholders/physicians";

//PAYER
export const PAYER = "/stakeholders/payers";
export const DMPD = "/stakeholders/DMPD-programs";

// HealthCare Providers
export const HEALTHCAREPROVIDER = "/stakeholders/healthcare-providers";
// HealthCare Providers Report
export const HEALTHCAREPROVIDERREPORT =
  "/stakeholders/report/healthcare-provider-report";

// HealthCare Provider Services
export const HEALTHCAREPROVIDERSERVICES =
  "/stakeholders/healthcare-provider-services";

/*
NetWorks
*/

// Netoworks
export const NETWORKS = "/network/networks";
export const NETWORKHEALTHCARE = "/network/network-healthcare-contracts";
// Health Care Contracts
export const HEALTHCARECONTRACTS = "/network/healthcare-contracts";
// Health Care Contracts Services
export const HEALTHCARECONTRACTSERVICES =
  "/network/healthcare-contract-services";
// Health Care Contracts pricse
export const HEALTHCARECONTRACTPRICES = "/network/healthcare-service-prices";

/*
Productions
*/

// payer agreement
export const PAYERAGREEMENT = "/production/payer-agreements";
// products
export const PRODUCTS = "/production/products";
// Co-particatipation
export const COPARTICIPATION = "product-co-participations";
// Product Payer
export const PRODUCTPRIORITY = "priority-payers";
// Limits
export const PRODUCTLIMITS = "limits";

// Production treatyProgram
export const TREATYAGREEMENT = "/production/treaty-agreements";
export const CUSTOMERS_URL = "stakeholders/customers";
//product PCI
export const PRODUCTPCI = "/production/product-pcis";
export const FAMILYBENEFITS = "family-benefits";
// Upgrade Benefit
export const UPGRAGDINGBENEFITS = "upgrading-benefits";
// Claim Settlement
export const CLAIMSETTLEMENTS = "claim-settlements";
// Schedule template
export const SCEHDULETEMPLATE = "schedule-template";
// Table of Benefits
export const TABLEBENEFITS = "table-benefits";
// Uncovered Benefit in table benefits
export const UNCOVEREDBENEFITS = "excluded-table-benefits";

// Premium
export const PREMIUMS = "premiums";
// Age Group
export const AGEGROUPS = "product-age-groups";
// Matrices
export const MATRICES = "matrices";
// participations
export const PARTICPICATION = "calculate-participation";
// product exceptions
export const EXCEPTIONS = "co-participation-exceptions";

// Policies
export const POLICIES = "/production/policies";
// Policy Endorsement
export const POLICIESENDORESMENT = "/production/endorsements";

// Policiesy Benefecaries

export const BENEFECARIES = "/production/beneficiaries";
export const BENEFECARIESEligibility =
  "/production/beneficiaries/check-eligibility";
// beneficiary calculate Premiums
export const CALCULATEPREMIUMS = "calculate-premium";
// beneficiary calculate Endrosement
export const CALCULATEENDORSEMENT = "calculate-endorsement";
// beneficiary save Endrosement
export const SAVEENDORSEMENT = "save-endorsement";

/*
Claim Enpoints
*/

// Claim Form
export const CLAIMFORM = "/claim/claims";
// Batch Claim
export const BATCHCLAIM = "/claim/batch-forms";

// chronic Form
export const CHRONICFORM = "/claim/chronic-forms";

// Claim history
export const CLAIMHISTORY = "/history/claim/claims";

// MedicalOpinion

export const MEDICALOPINION = "/claim/medical-opinion-cases";

// approval requests

export const APPROVAL_REQUESTS_URL = "/production/beneficiary-requests";
/*
Finance Enpoints
*/

// Invoices
export const INVOICES = "/finance/invoices";
// Payment Order
export const PAYMENTORDER = "/finance/payment-orders";
//commissions Payment
export const COMMISSIONPAYMENTS = "/finance/commission-payments";
// Receipts
export const RECEIPTS = "/finance/receipts";
// Policy Payment
export const POLICYPAYMENT = "/finance/policy-payments";
// provider Payment
export const PROVIDERPAYMENT = "/finance/provider-payments";
// early Payment
export const EARLYPAYMENT = "/finance/early-payment-discounts";

/*
General EndPoints
*/

export const BRANCHES = "/general/branches";
export const ACCESSTYPES = "/auth/access-types";
export const MODULES = "/general/modules";
export const FILES = "/files";
export const KPIS = "/general/kpis";
export const CLAIMASSIGN = "/general/claim-assigned-users";
export const INVOICEPARSE = "/general/document-ai/invoice/parse";
export const INVOICEPROCESSING = "/general/document-ai/invoice/process";
export const ACTIVEPOPULATION =
  "/general/kpis/beneficiaries-active-population-count";
export const SENDSMS = "/general/send-sms";

/*
Dashboards modules
 */

// dashboard
export const DASHBOARDS = "/general/dashboards";
export const STAFFTASKS = "/general/staff-tasks";

/*
Notifications
*/

export const NOTIFICATIONS = "/general/notifications";
export const NOTIFICATIONSLOGS = "/general/notification-logs";

/*
Schedule Tasks
*/
export const SCHEDULETASKS = "/general/scheduled-tasks";

/* Reports */
export const DYNAMICREPORTS = "/general/reports";

export const REMINDERS = "/general/reminders";

// saved Reports
export const SAVEDREPORTS = "/general/report-templates";

export const MEMBERFILE = "/production/beneficiaries/report/reinsurer";
export const POLICYFILE = "/production/policies/report/reinsurer";
export const CLAIMFILE = "/claim/report/reinsurer";

// Medical History
export const MEDICALHISTORYREPORT =
  "/production/beneficiaries/report/beneficiaries";

// blacklisted Report
export const BLACKLISTEDREPORTS =
  "/production/beneficiaries/report/blacklisted";
// Due To Expiry Report
export const DUETOEXPIRYREPORT = "/production/policies/report/due-to-expire";
// Due To Expiry Report
export const AGENTCOMISSIONSREPORT =
  "/finance/policy-payments/report/agent-commission";
// agent policies
export const AGENTPOLICIESREPORT = "/user/report/agent-policies";

// Not Renewed Report
export const NOTRENEWEDREPORT = "/production/policies/report/not-renewed";

//Volume rebat report
export const VOLUMREBATEREPORT =
  "network/healthcare-contracts/calculate-volume-rebate";

// fees by beneficiary
export const FEESBYBENEFICIARY =
  "production/payer-agreements/calculate-transaction-fees";
export const EXPERTPERFORMANCE = "motor/report/performance-report";
// LOSS RATIO RePORT
export const LOSSRATIOREPORT = "stakeholders/payers/calculate-loss-ratio";
// PriorityPayer report
export const PRIORITYPAYERREPORT = "claim/report/priority-payer-report";
// services price report
export const SERVICEPRICE = "stakeholders/report/service-price-report";
// claim accounting report
export const ACCOUNTING = "claim/report/accounting-report";

// policy premiums report
export const POLICYPREMIUM = "/production/policies/report/premiums";

// premium fees
export const PREMIUMFEES =
  "/production/payer-agreements/calculate-premium-fees";

// Insurer Report
export const CLAIMREPORT = "/claim/report/insurer-report";

// PREMIUM REPORT
export const PREMIUMREPORT = "/production/beneficiaries/report/premiums-report";

//dispatches report
export const DISPATCHESREPORT = "motor/source/fnols/dispatches-report";
// fnol Report
export const FNOLREPORT = "/motor/report/fnol-report";

// processing Report
export const PROCESSINGREPORT = "/claim/report/processing-report";

// data transfer Report
export const DATATRANSFERREPORT = "/claim/report/transfer-data";

//Chat
export const CHAT = "/general/conversations";
// Conversation Messages
export const CONVERSATIONMESSAGES = "/general/conversation-messages";

// Contacts
export const CONTACTS = "stakeholders/stakeholder-contacts";

// banks
export const PAYERBANKS = "stakeholders/stakeholder-banks";

//motors
//motoProvier
export const MOTOPROVIDER = "motor/providers";
//motoDriver
export const MOTODriver = "motor/drivers";
//motoVehicle
export const MOTOVehicle = "motor/vehicles";
//fnol
export const FNOL = "/motor/fnols";
//experts
export const MOTOREXPERTS = "/motor/experts";
// experts report
export const MOTOREXPERTSREPORT = "/motor/report/expert-report";
//spare parts
export const MOTORSPAREPARTS = "/motor/spare-parts";
//motoClaims
export const MOTORCLAIMS = "/motor/claims";
// moto policies
export const MOTORPOLICIES = "/motor/mt-policies";
// moto payers
export const MOTORPAYERS = "/motor/insured";
//Tow
export const TOWPROVIDER = "/motor/tow-providers";
// Dispatches
export const DISPATCHES = "/motor/dispatches";
// Suppliers
export const SUPPLIERS = "/motor/suppliers";
// Panels
export const PANELS = "/motor/panels";
// Surveyors
export const SURVEYORS = "/motor/surveyors";
//VEHICLEBRACKETS
export const VEHICLEBRACKETS = "/motor/vehicle-year-brackets";
