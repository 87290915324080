import React from "react";
import { findDateDifference } from "utils";

const NotificationDropdownItem = ({ notification }) => {
  return (
    <div
      className="d-flex gap-2"
      data-toggle="tooltip"
      title={notification.title}
    >
      <div className="avatar-xs">
        <span className="avatar-title bg-success rounded-circle font-size-16">
          <i className="bx bx-badge-check" />
        </span>
      </div>
      <div className="flex-grow-1">
        <h6 className="mt-0 mb-1">{`${notification.title?.slice(0, 33)} ${
          notification?.title?.length > 33 ? "..." : ""
        }`}</h6>
        <div className="font-size-12 text-muted">
          <p className="mb-0">
            <i className="mdi mdi-clock-outline" />{" "}
            {findDateDifference(notification.created_at)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationDropdownItem;
