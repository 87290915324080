import { get, request } from "../api_helper"

import { MODULES_SUBMODULES, USER_MODULES } from "../url_helper"

// get all modules for the logged in user
// url
// export const getAllModules = () => get(USER_MODULES)

export const getAllModules = (params) =>
  request({
    url: USER_MODULES,
    method: "GET",
    params,
    repeat: true,
  })

// get all actions that allowed to the logged in user on a specific module
//url/{id}
export const getAllActions = (moduleId) =>
  get(`${USER_MODULES}/${moduleId}`, moduleId)

// get all Submodules and Ipnuts that allowed to the logged in user on a specific module
//url/{id}

// export const getAllSubModules = moduleId =>
//   get(`${MODULES_SUBMODULES}/${moduleId}`, moduleId)

export const getAllSubModules = (moduleId, params) =>
  request({
    url: `${MODULES_SUBMODULES}/${moduleId}`,
    method: "GET",
    params,
  })
