import React, { useState } from "react"

import { useFormik } from "formik"
import { Alert, Col, Row } from "reactstrap"
import {
  DatePickerInput,
  FormControll,
  FormModal,
  ModalSpinner,
  SearchSelect,
} from "components/TP/Common/Forms"
import * as Yup from "yup"
import * as URLs from "helpers/url_helper"
import {
  apiErrorrHandler,
  fieldFinder,
  generateFieldValidation,
  isInputInvalid,
  NotificationMessage,
} from "utils"
import { MODULES_SUBMODULES } from "helpers/url_helper"
import { staffTasksModuleID } from "utils/modulesIds"

import { addMedicalOpinTask } from "helpers/Claims/MedicalOpinion"
import useSWR from "swr"
import { fetcher } from "utils/API/SWRFetcher"
import { useSelector } from "react-redux"
import { addClaimStaffTask } from "helpers/Claims/ClaimForm"
import DropZone from "components/TP/Common/DropZone"
import { UploadFiles } from "utils/API/UploadFiles"

function AddSourceTask({ setShow, show, sourceId, sourceType }) {
  // current module name
  const { moduleName } = useSelector((state) => ({
    moduleName: state.Module?.name,
  }))

  const [selectedFiles, setSelectedFiles] = useState([])

  const url = `${MODULES_SUBMODULES}/${staffTasksModuleID}`
  const {
    isLoading,
    error: fieldError,
    data,
  } = useSWR(
    () => (url ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  )

  const inputs = data?.fields

  const [error, setError] = useState("")
  const handleAddTask = async (values, setSubmitting, resetForm) => {
    let body
    try {
      if (sourceType === "Claim") {
        const fileIds = await UploadFiles(selectedFiles)
        body = {
          ...values,
          files: fileIds,
          users: [
            ...values.users?.map((user) => ({
              user_id: user,
              is_follower: 0,
            })),

            ...values.followers?.map((user) => ({
              user_id: user,
              is_follower: 1,
            })),
          ],
        }
      } else {
        body = {
          ...values,
          users: [
            ...values.users?.map((user) => ({
              user_id: user,
              is_follower: 0,
            })),

            ...values.followers?.map((user) => ({
              user_id: user,
              is_follower: 1,
            })),
          ],
        }
      }

      // use correspondent api function for each source Type
      // when create a task for claim the status id will be To Do by default
      sourceType === "Claim"
        ? await addClaimStaffTask(sourceId, { ...body, status_id: 1791 })
        : await addMedicalOpinTask(sourceId, body)

      NotificationMessage("success", "add")
      resetForm()
      setShow(false)

      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      status_id: "",
      priority_id: "",
      module_id: "",
      usage_id: "",
      start_date: "",
      due_date: "",
      users: [],
      followers: [],
      department_id: "",
    },
    validationSchema: Yup.object({
      title: generateFieldValidation(inputs, 1570, "string"),
      description: generateFieldValidation(inputs, 1571, "string"),
      status_id: generateFieldValidation(inputs, 1572, "dropdown"),
      priority_id: generateFieldValidation(inputs, 1573, "dropdown"),
      start_date: generateFieldValidation(inputs, 1574, "date"),
      due_date: generateFieldValidation(inputs, 1575, "date"),
      users: generateFieldValidation(inputs, 1576, "array"),
      followers: generateFieldValidation(inputs, 1577, "array"),
      department_id: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { department_id, ...rest } = values

      handleAddTask(rest, setSubmitting, resetForm)
    },
  })

  // console.log(moment('yy-mm-dd'));

  return (
    <FormModal
      modalSize="xl"
      show={show}
      onCloseClick={() => {
        setShow(false)
        validation.resetForm()
        setError("")
      }}
      onSaveClick={validation.handleSubmit}
      modalTitle={`${moduleName} - Add Task`}
      isSaving={validation.isSubmitting}
    >
      {isLoading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {error ? <Alert color="danger">{error}</Alert> : null}

          {inputs && inputs.length > 0 && (
            <>
              <Row>
                <Col md={3}>
                  <FormControll
                    id="1570"
                    name="title"
                    type="text"
                    inputField={fieldFinder(inputs, 1570)}
                    {...validation.getFieldProps("title")}
                    invalid={isInputInvalid(validation, "title")}
                    error={validation.errors.title}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />
                </Col>
                <Col md={3}>
                  <DatePickerInput
                    id="1574"
                    name="start_date"
                    // minDate={moment().format('YYYY-MM-DD')}
                    inputField={fieldFinder(inputs, 1574)}
                    value={validation.values.start_date}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    invalid={isInputInvalid(validation, "start_date")}
                    error={validation.errors.start_date}
                    wrapperClassNames="mb-4"
                    disablePastDates={sourceType === "Claim" ? true : false}
                    pageType="edit"
                  />
                </Col>
                <Col md={3}>
                  <DatePickerInput
                    id="1575"
                    name="due_date"
                    inputField={fieldFinder(inputs, 1575)}
                    value={validation.values.due_date}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    invalid={isInputInvalid(validation, "due_date")}
                    error={validation.errors.due_date}
                    wrapperClassNames="mb-4"
                    disablePastDates={true}
                    minDate={validation.values.start_date}
                    pageType="edit"
                  />
                </Col>
                <Col md={3}>
                  <SearchSelect
                    id="1573"
                    name="priority_id"
                    inputField={fieldFinder(inputs, 1573)}
                    value={validation.values.priority_id || null}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="name"
                    mappingValue="id"
                    url={`${URLs.PREDEFINEDS}/${
                      fieldFinder(inputs, 1573)?.predefined_value?.id
                    }`}
                    invalid={isInputInvalid(validation, "priority_id")}
                    error={validation.errors.priority_id}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />
                </Col>
                <Col md={3}>
                  <SearchSelect
                    id="550055100"
                    name="department_id"
                    inputField={{
                      label_name: "department",
                      predefined_value: 2,
                    }}
                    value={validation.values.department_id || null}
                    onChange={validation.setFieldValue}
                    customOnChange={(selected) => {
                      validation.setFieldValue(
                        "department_id",
                        selected ? selected.id : ""
                      )
                    }}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="name"
                    mappingValue="id"
                    url={`${URLs.PREDEFINEDS}/2`}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />
                </Col>

                <Col md={3}>
                  <SearchSelect
                    id="1576"
                    name="users"
                    inputField={fieldFinder(inputs, 1576)}
                    value={validation.values.users || null}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="name"
                    mappingValue="id"
                    url={URLs.USERS}
                    disableSorting={true}
                    invalid={isInputInvalid(validation, "users")}
                    error={validation.errors.users}
                    wrapperClassNames="mb-3"
                    multiple={true}
                    urlAddOns={
                      validation.values.department_id
                        ? {
                            search_keys: "department_id",
                            search_values: validation.values.department_id,
                            sort_by: "first_name",
                            sort_type: "asc",
                          }
                        : null
                    }
                    disableViewPage={true}
                  />
                </Col>
                <Col md={3}>
                  <SearchSelect
                    id="1577"
                    name="followers"
                    inputField={fieldFinder(inputs, 1577)}
                    value={validation.values.followers || null}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="first_name"
                    mappingValue="id"
                    url={URLs.USERS}
                    invalid={isInputInvalid(validation, "followers")}
                    error={validation.errors.followers}
                    wrapperClassNames="mb-3"
                    multiple={true}
                    disableViewPage={true}
                  />
                </Col>
                <Col md={12}>
                  <FormControll
                    id="1571"
                    name="description"
                    type="textarea"
                    inputField={fieldFinder(inputs, 1571)}
                    {...validation.getFieldProps("description")}
                    invalid={isInputInvalid(validation, "description")}
                    error={validation.errors.description}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />
                </Col>
              </Row>
            </>
          )}

          {sourceType === "Claim" && (
            <DropZone
              selectedFiles={selectedFiles}
              setselectedFiles={setSelectedFiles}
            />
          )}
        </form>
      )}
    </FormModal>
  )
}

export default AddSourceTask
