import React, { Fragment } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { Label } from "reactstrap"
import { FormInputError } from "."
import { usePageType } from "hooks"

const FormEditor = ({
  value,
  onChange,
  rest,
  id,
  inputField,
  error,
  invalid,
  readOnly,
  labelAddOns,
  wrapperClassNames,
}) => {
  const { isViewPage, isViewProcessingPage } = usePageType()
  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {inputField ? (
        <div className="d-flex gap-2">
          <Label
            htmlFor={id}
            className={`text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${inputField?.label_class ? inputField?.label_class : ""}`}
          >
            {inputField?.label_name}
          </Label>
          {labelAddOns && labelAddOns}
        </div>
      ) : null}
      <CKEditor
        editor={ClassicEditor}
        data={value}
        disabled={readOnly || isViewPage || isViewProcessingPage ? true : false}
        config={{
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
          isReadOnly:
            readOnly || isViewPage || isViewProcessingPage ? true : false,
        }}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor)
        }}
        // onChange={(event, editor) => {
        //   const data = editor.getData()
        //   console.log(data)
        //   setData(data)
        // }}
        onChange={onChange}
        {...rest}
      />

      {/* Error Message */}
      {invalid && !isViewPage && !isViewProcessingPage && !readOnly ? (
        <FormInputError error={error} selectError={true} />
      ) : null}
    </div>
  )
}

export default FormEditor
