export const commonDataPaths = {
  currencies: "/currency",
  banks: "/banks",
  medical_codes: "/medical_codes",
  benefits: "/benefits",
  services: "/services",
  events: "/event-manager",
  pciRules: "/pci-rules",
  tobTemplates: "/tob-template",
  covers: "/covers",
  lob: "/lob",
  proformaItems:'/proforma-items',
}
