import { PREDEFINEDS } from "../../url_helper"
import { request } from "../../api_helper"
/*
Predefineds
*/

// add new predefined
//url
export const addPredefined = (data) =>
  request({ url: PREDEFINEDS, method: "POST", data })

// get all predefineds
//url
//with pagination
// export const getAllPredefineds = () => get(PREDEFINEDS)
export const getAllPredefineds = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  repeat,
  ...rest
}) =>
  request({
    url: PREDEFINEDS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
    repeat,
  })

// show predefined
//url/{id}

export const showPredefined = (predefinedId) =>
  request({
    url: `${PREDEFINEDS}/${predefinedId}`,
    method: "GET",
  })

// update predefined
//url/{id}
export const updatePredefined = (data) =>
  request({ url: `${PREDEFINEDS}/${data.id}`, method: "PUT", data })

// delete predefined
//url/{id}
export const deletePredefined = (predefinedId) =>
  request({
    url: `${PREDEFINEDS}/${predefinedId}`,
    method: "DELETE",
    data: { predefinedId },
  })

// activate predefined
//url/activate/{id}
export const activatePredefined = (predefinedId) =>
  request({
    url: `${PREDEFINEDS}/${predefinedId}/activate`,
    method: "POST",
    data: {
      predefinedId,
    },
  })

// deactivate predefined
//url/deactivate/{id}
export const deactivatePredefined = (predefinedId) =>
  request({
    url: `${PREDEFINEDS}/${predefinedId}/deactivate`,
    method: "POST",
    data: {
      predefinedId,
    },
  })
