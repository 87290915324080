import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { read, utils } from "xlsx"

// UI Components
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

// redux
import { useDispatch, useSelector } from "react-redux"

import { getActions, getSubmodules } from "store/actions"

import { benefitsModuleID, productTableOfBenefitsId } from "utils/modulesIds"
import { benefitsSteps } from "common/data/BatchUploadSteps"
import { useHistory } from "react-router-dom"
import { useModuleActions } from "hooks"
import parse from "html-react-parser"
import { flatten } from "lodash"
import { uploadBenefit } from "helpers/CommonData/benefits"
import { UploadFiles } from "utils/API/UploadFiles"
import { CompanyName } from "constants/TPA"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { buttonsTitles } from "common/data"
import { batchUploadTOB } from "helpers/Production/Products/tabel_benefit"
import { useLocation } from "react-router-dom"

const UploadBenefit = (props) => {
  document.title = `Upload Table Of Benefits`
  const location = useLocation()

  const productId = parseInt(location.pathname.split("/")[2])

  const history = useHistory()
  const { isBatchUploadPermission } = useModuleActions()

  const { module } = useSelector((state) => ({
    module: state.Module.actions,
  }))
  const dispatch = useDispatch()

  // states
  const [file, setFile] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const fileRef = useRef(null)

  const [headers, setHeaders] = useState([])
  const [data, setData] = useState([])
  const [csvTable, setCsvTable] = useState(false)

  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleOnSimulate = async (e) => {
    e.preventDefault()

    if (file) {
      const f = await file.arrayBuffer()
      const wb = read(f)
      // const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
      /* rows are generated with a simple array of arrays */
      const rows = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: 1,
        defval: "",
        blankrows: false,
      })
      setData(rows.slice(1))
      setHeaders(rows[0])
      setCsvTable(true)
    }
  }

  useEffect(() => {
    dispatch(getActions(productTableOfBenefitsId))
  }, [getActions])

  const handleSave = async (e) => {
    e.preventDefault()
    if (!file) {
      NotificationMessage("Error", "Please Select File")
      return
    }
    setIsSaving(true)
    const formData = new FormData()
    formData.append("file", file)
    try {
      const response = await batchUploadTOB(productId, formData)
      NotificationMessage("Success", response?.message)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsSaving(false)
    }
  }

  // redirect the user if he/she has no permission to batch upload
  //   useEffect(() => {
  //     if (module && !isBatchUploadPermission) {
  //       history.push("/")
  //     }
  //   }, [module])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Import Table Of Benefits")}
            breadcrumbItem={props.t("Import Table Of Benefits")}
          />

          <Row>
            <Col className="col-12">
              {benefitsSteps.map((step, index) => (
                <p key={index}>
                  {index + 1}. {parse(step)}
                </p>
              ))}
            </Col>
          </Row>

          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            <Col xs="12">
              {/* Card To Ouptut White Card Around The Table */}
              <Card>
                <CardBody>
                  {/* buttons */}
                  <div className="mb-4 mt-3 justify-content-md-between justify-content-center row">
                    <div className="mb-4 md-mb-0 col-md-6 d-flex gap-2">
                      <div className="input-group">
                        <input
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          aria-label="Upload"
                          type="file"
                          ref={fileRef}
                          accept=".csv,.xlsx"
                          onChange={handleOnChange}
                          className="form-control form-control"
                        />
                        <button
                          className="btn btn-primary"
                          type="button"
                          id="inputGroupFileAddon04"
                          onClick={(e) => {
                            handleOnSimulate(e)
                          }}
                        >
                          {buttonsTitles.simulateImport}
                        </button>
                      </div>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                          setFile(null)
                          fileRef.current.value = ""
                          setCsvTable(false)
                        }}
                      >
                        {buttonsTitles.clear}
                      </button>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="d-flex justify-content-md-end flex-wrap justify-content-start gap-2">
                        <div className="text-sm-end">
                          <a
                            className="btn me-2 btn-success"
                            href="/files/ProductTOB_Sample.xlsx"
                            download
                          >
                            <i className="mdi mdi-download me-1"></i>
                            {buttonsTitles.downloadSample}
                          </a>
                          <button
                            className="btn me-2 btn-info"
                            onClick={(e) => handleSave(e)}
                            disabled={isSaving}
                          >
                            <i className="mdi mdi-table-check me-1"></i>
                            {buttonsTitles.saveData}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Table */}

                  {
                    csvTable ? (
                      <div className="table-responsive react-table">
                        <table
                          role="table"
                          className="table table-hover"
                          style={{ position: "relative" }}
                        >
                          <thead className="table-light table-nowrap">
                            <tr role="row">
                              {headers.map((item, index) => (
                                <th
                                  colSpan="1"
                                  key={item + index}
                                  role="columnheader"
                                >
                                  <div className="mb-2">
                                    <div>{item}</div>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody role="rowgroup">
                            {data.map((row, index) => (
                              <tr key={index} role="row">
                                {row.map((value, index) => (
                                  <td
                                    role="cell"
                                    key={value + index}
                                    className="text-capitalize"
                                  >
                                    <div>{value}</div>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null
                    // <div className="table-responsive react-table">
                    //   <table
                    //     role="table"
                    //     className="table table-hover"
                    //     style={{ position: "relative" }}
                    //   >
                    //     <thead className="table-light table-nowrap">
                    //       <tr role="row">
                    //         <th colSpan="1" role="columnheader">
                    //           <div className="mb-2">
                    //             <div></div>
                    //           </div>
                    //         </th>
                    //         <th colSpan="1" role="columnheader">
                    //           <div
                    //             className="mb-2"
                    //             title="Toggle SortBy"
                    //             style={{ cursor: "pointer" }}
                    //           >
                    //             Name <span style={{ color: "red" }}>*</span>
                    //           </div>
                    //         </th>
                    //         <th colSpan="1" role="columnheader">
                    //           <div
                    //             className="mb-2"
                    //             title="Toggle SortBy"
                    //             style={{ cursor: "pointer" }}
                    //           >
                    //             Benefit Group
                    //           </div>
                    //         </th>
                    //         <th colSpan="1" role="columnheader">
                    //           <div
                    //             className="mb-2"
                    //             title="Toggle SortBy"
                    //             style={{ cursor: "pointer" }}
                    //           >
                    //             Active
                    //             <span style={{ color: "red" }}>*</span>
                    //           </div>
                    //         </th>
                    //       </tr>
                    //     </thead>
                    //     <tbody role="rowgroup">
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Test Road
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Road Ambulance expenses{" "}
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Hormone Tests
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Miscellaneous
                    //         </td>
                    //         <td role="cell" className="">
                    //           yes
                    //         </td>
                    //         <td role="cell" className="text-capitalize"></td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Allergy tests (Example: skin tests)
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Miscellaneous
                    //         </td>
                    //         <td role="cell" className="">
                    //           yes
                    //         </td>
                    //         <td role="cell" className="text-capitalize"></td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Miscellaneous
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Out-patient
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Speech Therapy
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Treatment
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Radiotherapy
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Treatment
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Laser therapy
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Treatment
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Treatment
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Out-patient
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Double test
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Out-Patient Benefits for Maternity
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //       <tr role="row">
                    //         <td role="cell" className="text-capitalize">
                    //           <div></div>
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Maternity Lab tests
                    //         </td>
                    //         <td role="cell" className="text-capitalize">
                    //           Out-Patient Benefits for Maternity
                    //         </td>
                    //         <td role="cell">yes</td>
                    //       </tr>
                    //     </tbody>
                    //   </table>
                    // </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UploadBenefit)
