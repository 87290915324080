export const messages = {
  // general
  deleteModal: "Are you sure you want to delete this record?",

  requiredFields: "Please Fill All Required Data",
  successCalc: "Calculated Successfully",
  backToTop: "Back to top",
  duplicatePriceList: "There is already a price list for this service.",
  areYouSure: "Do you want to continue with the uploading of the price list?",
  uploadPremiums: "Do you want to continue with the uploading of the premiums?",

  // provider exluded
  providerExluded: "Product Exception based On",
  blackListedProduct:
    "The insurer has placed this provider on a blacklist for this product",
  computePolicy: "Are you sure you want to Compute This Policy?",

  // claims

  dif28:
    "The admission date and the report date show a difference of more than 28 days",
  // duplicatedClaim: "Duplicated Claim Founded For This Beneficiary. Check Claim",
  duplicatedClaim:
    "This member has claims with the same criteria within the last 7 days. Please check Claims ",
  uploadMedicalCodesFiles:
    "Please upload the necessary documents as indicated in the attachment section for this procedure.",
  physicianNotNSSF: "This physician is not authorized by the NSSF",
  // leave the tab
  unsavedTabChanges:
    "You have unsaved changes , are you sure you want to leave this page?",

  // renew policy
  renewPolicy: "Are you sure you want to Renew This Policy?",
  changePlans: "Are you sure you want to change those plans?",

  // general
  fileUploaded: "File Uploaded Successfully",
  // Medical Declaration
  above55: "This member is not eligible for LG due to the age constraint",

  wrongCredentials:
  "You can't access this Site with these credentials.",
}
