import React, { Fragment, useState } from "react"
import { FormControll, FormModal } from "components/TP/Common/Forms"
import { Row } from "reactstrap"

const WhatssAppModal = ({ isModal, setIsModal, claim }) => {
  const [number, setNumber] = useState("")
  const [text, setText] = useState("")

  const sendWatts = () => {
    const mobileNumber = number
    // Regex expression to remove all characters which are NOT alphanumeric
    let num = mobileNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "")

    // Appending the phone number to the URL
    let url = `https://web.whatsapp.com/send?phone=${num}`

    const message = `Claim#: ${claim?.reference}\nService Date: ${claim?.admission_date}\nPatient Name: ${claim?.beneficiary?.name}\nType: ${claim?.benefit?.name}\nProvider: ${claim?.healthcare_provider?.first_name}\nClaim Motive: ${claim?.cause?.name}\nstatus: ${claim?.status?.name}\nSpecial Notes: ${text}`
    // Appending the message to the URL by encoding it
    url += `&text=${encodeURI(message)}&app_absent=0`

    // Open our newly created URL in a new tab to send the message
    window.open(url)
    setIsModal(false)
    setNumber("")
    setText("")
  }
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
      }}
      onSaveClick={() => {
        sendWatts()
      }}
      saveButtonTitle="Send"
      modalTitle={"Whatsapp Message"}
      isSaving={false}
    >
      <form onSubmit={e => e.preventDefault()}>
        <Fragment>
          <Row>
            <FormControll
              id="number"
              label={"Number"}
              placeholder="961 xxx xxxx"
              value={number}
              type="text"
              onChange={e => {
                setNumber(e.target.value)
              }}
            />
            <FormControll
              id="text"
              label={"Text"}
              placeholder="enter your text"
              value={text}
              type="textarea"
              onChange={e => {
                setText(e.target.value)
              }}
              wrapperClassNames="mt-3"
            />
          </Row>
        </Fragment>
      </form>
    </FormModal>
  )
}

export default WhatssAppModal
