import React, { Fragment, useEffect, useState } from "react"
import useSWR from "swr"
import { fetcher } from "utils/API/SWRFetcher"
import { USER_MODULES } from "helpers/url_helper"
import { medicalOpinionId } from "utils/modulesIds"
import {
  apiErrorrHandler,
  encryptText,
  generateColumns,
  NotificationMessage,
} from "utils"
import { TableContainer } from "components/Common"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { ModalSpinner } from "../Common/Forms"
import { buttonsTitles } from "common/data"
import { getAllMedicalsOpinion } from "helpers/Claims/MedicalOpinion"
import { SingleRowAction } from "../Common/Tables"
import { claimPaths } from "common/data/routePaths"
import MultiSkeleton from "../Common/MultiSkeleton"
const ClaimMOCasesModal = ({ claim, isModal, setIsModal }) => {
  // const [isModal, setIsModal] = useState(false)

  const [showOptions, setShowOptions] = useState([])
  const [resetInput, setResetInput] = useState(false)

  const [columns, setColumns] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)

  const [medicalOpinionCases, setMedicalOpinionCases] = useState([])

  const toggle = () => setIsModal(false)

  const url = `${USER_MODULES}/${medicalOpinionId}`

  const { isLoading, error, data } = useSWR(
    () => (url && isModal ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  )

  const actionFields = data?.fields

  // index beneficiaries
  const getMedicalOpinionCases = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    ...rest
  }) => {
    try {
      setLoading(true)
      const res = await getAllMedicalsOpinion({
        claim_id: claim?.id,
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        ...rest,
      })
      setMedicalOpinionCases(res.items)
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (actionFields?.length > 0) {
      // Select Those columns from fields
      const desiredColumns = generateColumns(actionFields)
      setColumns([
        ...desiredColumns,

        {
          Header: "Actions",
          accessor: "actions",
          disableFilters: true,
          Cell: cellProps => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original
            return (
              <SingleRowAction
                to={`${claimPaths.medicalOpinion}/view/${encryptText(row?.id)}`}
                target="_blank"
                iconClass="mdi mdi-eye"
                actionName={"View"}
                record={row}
              />
            )
          },
        },
      ])
    } else return
  }, [actionFields])

  return (
    <Fragment>
      {/* SMO Cases */}
      {/* <button
        aria-label="SMO Cases"
        className="border-0 badge bg-primary fw-semibold py-2 px-2"
        onClick={() => setIsModal(true)}
      >
        SMO Cases
      </button> */}

      {isModal && (
        <Modal isOpen={isModal} toggle={toggle} fullscreen>
          {isLoading ? (
            <MultiSkeleton length={1} />
          ) : (
            <ModalHeader
              toggle={toggle}
            >{`${claim?.reference}’s ${data?.title}`}</ModalHeader>
          )}
          <ModalBody>
            {isLoading ? (
              <ModalSpinner />
            ) : (
              <TableContainer
                columns={columns}
                data={medicalOpinionCases}
                pagination={pagination}
                getData={getMedicalOpinionCases}
                loading={loading}
                resetInput={resetInput}
                setResetInput={setResetInput}
                // for show / hide columns
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                fullColumns={generateColumns(actionFields)}
                removeActionsButtons={true}
                removeActionsColumns={true}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              {buttonsTitles.close}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  )
}

export default ClaimMOCasesModal
