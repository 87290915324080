import { request } from "../../api_helper"
import { BENEFITS } from "../../url_helper"

/*
Benefits
*/

// add new Benefit

// url
export const addBenefit = (data) =>
  request({ url: BENEFITS, method: "POST", data })

// get all Benefits with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllBenefits = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: BENEFITS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// show Benefit
//url/{id}

export const showBenefit = (benefitId) =>
  request({ url: `${BENEFITS}/${benefitId}`, method: "GET" })

// update Benefit
//url/{id}

export const updateBenefit = (data) =>
  request({ url: `${BENEFITS}/${data.id}`, method: "PUT", data })

// delete Benefit
//url/{id}
export const deleteBenefit = (benefitId) =>
  request({ url: `${BENEFITS}/${benefitId}`, method: "DELETE" })

// activate Benefit
//url/{id}/activate
export const activateBenefit = (benefitId) =>
  request({ url: `${BENEFITS}/${benefitId}/activate`, method: "POST" })

// deactivate Benefit
//url/{id}/deactivate
export const deactivateBenefit = (benefitId) =>
  request({ url: `${BENEFITS}/${benefitId}/deactivate`, method: "POST" })

// Batch Upload Benefti
export const uploadBenefit = (data) =>
  request({ url: `${BENEFITS}/import`, method: "POST", data })
