import { buttonsTitles } from "common/data";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CloseButton, Modal, ModalBody, Spinner } from "reactstrap";
import { NotificationMessage, apiErrorrHandler, exportToExcel } from "utils";
import { NoRecords } from "../Tables";

const AysncExportCSVModal = ({
  getSheetData,
  queryAddOn,
  isOpen,
  sheetHeader,
  generateCustomData,
  setIsOpen,
  sheetName,
  noPagingation,
  CSV = true,
  t,
}) => {
  const { moduleName } = useSelector((state) => ({
    moduleName: state.Module.name,
  }));

  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCSVData = async () => {
    setIsLoading(true);
    try {
      const res = await getSheetData(
        noPagingation
          ? { ...queryAddOn }
          : {
              page: 1,
              per_page: 10000,
              ...queryAddOn,
            }
      );
      setCsvData(res.items ? res.items : res.item);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
      console.log("error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCSVData();
  }, []);

  return (
    <Modal
      size="md"
      backdrop="static"
      toggle={setIsOpen}
      isOpen={isOpen}
      centered={true}
    >
      <ModalBody>
        {!isLoading && csvData.length === 0 && (
          <CloseButton className="mr-auto" onClick={() => setIsOpen(false)} />
        )}
        <Fragment>
          {isLoading ? (
            <div className="d-flex align-items-center gap-4">
              <Spinner className="ms-2" color="info" />
              <h6 className="lh-base">
                Please wait while we Downloading your data.
                <br /> This process may take a few moments.
                <br /> Thank you for your patience.
              </h6>
            </div>
          ) : csvData.length === 0 ? (
            <NoRecords />
          ) : (
            <div className="d-flex align-items-center justify-content-center w-100 h-100">
              {CSV ? (
                <CSVLink
                  className="btn btn-secondary"
                  aria-disabled={isLoading}
                  tabIndex={isLoading ? "-1" : "0"}
                  data={
                    generateCustomData ? generateCustomData(csvData) : csvData
                  }
                  onClick={() => setIsOpen(false)}
                  headers={sheetHeader}
                  filename={`${
                    sheetName ? sheetName : moduleName
                  }_${new Date().toISOString()}.csv`}
                >
                  {buttonsTitles.saveAs}
                </CSVLink>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    exportToExcel({
                      headers: sheetHeader,
                      data: generateCustomData
                        ? generateCustomData(csvData)
                        : csvData,
                      filename: sheetName ? sheetName : moduleName,
                    });
                    setIsOpen(false);
                  }}
                >
                  {buttonsTitles.saveAs}
                </button>
              )}
            </div>
          )}
        </Fragment>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(AysncExportCSVModal);
