import React, { Fragment } from 'react';
import { TimePicker } from 'antd';
import { usePageType } from 'hooks';
import { FormInputError } from '.';

import moment from 'moment';

const TimePickerInput = ({
  onChange,
  onBlur,
  value,
  name,
  id,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  customOnChange,
  wrapperClassNames,
  invalid,
  error,
  disabled,
  format,
}) => {
  const { isViewPage } = usePageType();
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel;
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder;
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ''
  } ${propLabelClass ? propLabelClass : ''}`;

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ''
  } ${propInputClass ? propInputClass : ''}`;

  const handleChange = (e, es) => {
    onChange(`${name}`, es);
  };

  const handleBlur = () => {
    onBlur(`${name}`, true);
  };

  return (
    <Fragment>
      <label
        htmlFor={id}
        className={`form-label text-capitalize ${
          inputField?.is_required ? 'required-label' : ''
        } ${labelClass ? labelClass : ''}`}
      >
        {label}
      </label>
      <div
        className={`form-control p-0  ${
          wrapperClassNames ? wrapperClassNames : ''
        }`}
        // style={{ width: "fit-content" }}
      >
        <TimePicker
          id={id}
          name={name}
          value={value ? moment(value, format) : ''}
          onChange={customOnChange ? customOnChange : handleChange}
          onBlur={handleBlur}
          format={format}
          style={{ width: '100%', border: 'none' }}
          disabled={disabled || isViewPage}
        />

        {/* Error Message */}
        {invalid && !isViewPage ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>
    </Fragment>
  );
};

export default TimePickerInput;
