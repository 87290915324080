import { staticPlaceholders } from "common/data"
import { claimPaths } from "common/data/routePaths"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

const TopSearchbar = () => {
  const [searchValue, setSearchValue] = useState("")
  const history = useHistory()
  const handleSubmit = e => {
    console.log("search ", searchValue)
    e.preventDefault()
    if (searchValue) {
      history.push({
        pathname: `${claimPaths.claimForm}/search-results`,
        state: { claimNo: searchValue },
      })
    } else {
      return
    }
  }
  return (
    <form className="app-search d-none d-lg-block" onSubmit={handleSubmit}>
      <div className="position-relative">
        <input
          type="text"
          className="form-control bg-white"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={staticPlaceholders.topSearchBar}
        />
        <button type="submit" className="border-0 bg-transparent">
          <span className="bx bx-search-alt" />
        </button>
      </div>
    </form>
  )
}

export default TopSearchbar
