/*
This is Hook is used to Extract the duplicated items 
in an array and send error back to the components
*/

import React, { useEffect, useState } from "react"
import { findDuplicatedItems } from "utils"
import useScrollWhenError from "./useScrollWhenError"

const useDuplicationError = (arr, keys, scroll = true) => {
  const [duplicationError, setDuplicationError] = useState("")

  useScrollWhenError(!scroll ? "" : duplicationError)
  // useScrollWhenError(duplicationError)
  useEffect(() => {
    const duplicatedItems = findDuplicatedItems(arr, keys)
    if (duplicatedItems.length > 0) {
      setDuplicationError(
        `Items Number ${duplicatedItems.map(item => item.index)} have a conflict
            `
      )
    } else {
      setDuplicationError("")
    }
  }, [arr])

  return duplicationError
}

export default useDuplicationError
