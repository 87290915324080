import { request } from "../api_helper"
import { HEALTHCARECONTRACTS } from "../url_helper"
/*
Health care Contracts
*/

// add new healthCare

// url
export const addHealthCareContract = (data) =>
  request({ url: HEALTHCARECONTRACTS, method: "POST", data })

// get all healthCares with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllHealthCareContracts = ({
  healthcare_provider_id,
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
}) =>
  request({
    url: HEALTHCARECONTRACTS,
    method: "GET",
    params: {
      healthcare_provider_id: healthcare_provider_id && healthcare_provider_id,
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
    },
  })

// show healthCare
//url/{id}

export const showHealthCareContract = (healthCareId) =>
  request({ url: `${HEALTHCARECONTRACTS}/${healthCareId}`, method: "GET" })

// update healthCare
//url/{id}

export const updateHealthCareContract = (data) =>
  request({ url: `${HEALTHCARECONTRACTS}/${data.id}`, method: "PUT", data })

// delete healthCare
//url/{id}
export const deleteHealthCareContract = (healthCareId) =>
  request({
    url: `${HEALTHCARECONTRACTS}/${healthCareId}`,
    method: "DELETE",
  })

// activate healthCare
//url/activate/{id}
export const activateHealthCareContract = (healthCareId) =>
  request({
    url: `${HEALTHCARECONTRACTS}/${healthCareId}/activate`,
    method: "POST",
  })

// deactivate healthCare
//url/deactivate/{id}
export const deactivateHealthCareContract = (healthCareId) =>
  request({
    url: `${HEALTHCARECONTRACTS}/${healthCareId}/deactivate`,
    method: "POST",
  })

// delete discount
//url/discount/{id}
export const deleteDiscount = (discountId) =>
  request({
    url: `${HEALTHCARECONTRACTS}/discount/${discountId}`,
    method: "DELETE",
  })

// copy health care contract
//url/copy/{id}
export const copyHealthCareContract = (data) =>
  request({
    url: `${HEALTHCARECONTRACTS}/${data.id}/copy`,
    method: "POST",
    data,
  })

export const validateInValidateContract = (healthCareId, data) =>
  request({
    url: `${HEALTHCARECONTRACTS}/${healthCareId}/validations`,
    method: "POST",
    data,
  })
export const AddContractToNetworks = (healthCareId, data) =>
  request({
    url: `${HEALTHCARECONTRACTS}/${healthCareId}/networks`,
    method: "POST",
    data,
  })
