import React, { Fragment, useState } from "react"
import { FormControll, FormModal } from "components/TP/Common/Forms"
import { Col, Input, Label, Row } from "reactstrap"
import TagsFormController from "../Common/Forms/TagsFormController"
import { apiErrorrHandler, NotificationMessage } from "utils"
import { sendClaimEmail } from "helpers/Claims/ClaimForm"

const SendEmailModal = ({ isModal, setIsModal, claim }) => {
  const [number, setNumber] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [emails, setEmails] = useState("")

  const sendEmail = async () => {
    setIsSaving(true)
    try {
      await sendClaimEmail(claim?.id, {
        emails: emails,
        path: "claims/InPatient",
      })
      NotificationMessage("success", "Email Sent Successfully")
      setIsModal(false)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setIsSaving(false)
    }
  }
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
      }}
      onSaveClick={() => {
        sendEmail()
      }}
      saveButtonTitle="Send"
      modalTitle={"Send Email"}
      isSaving={isSaving}
    >
      <form onSubmit={e => e.preventDefault()}>
        <Fragment>
          <Row>
            <TagsFormController
              id="964"
              name="emails"
              type="text"
              label="Emails:"
              labelSuffix={
                <span className="font-size-10 text-gray-600">
                  {" "}
                  Please Click Enter Button After Writting Your Email
                </span>
              }
              placeholder="Enter your emails"
              value={emails}
              invalid={false}
              onChange={selected => setEmails(selected)}
              error={""}
              wrapperClassNames="mb-3"
            />
          </Row>
        </Fragment>
      </form>
    </FormModal>
  )
}

export default SendEmailModal
